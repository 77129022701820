import React, { useLayoutEffect, useState } from 'react';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import { Container, Row, Col, Nav, Button, Form } from 'react-bootstrap';
import {
  faChevronLeft,
  faClipboardList,
  faQuestionCircle,
  faCalendarDay,
  faBullhorn,
  faCircleMinus,
  faList,
  faChevronCircleDown,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoginInfo from './LoginInfo/LoginInfo';
import ContactInfo from './ContactInfo/ContactInfo';
import CloseAccount from './CloseAccount/CloseAccount';
import MyOrders from './MyOrders/MyOrders';
import { faAddressBook, faUser } from '@fortawesome/free-regular-svg-icons';
import { useAuth } from '../hooks/useAuth';
import { USER_ROLES } from '../constants';

/**
 * An enumeration representing the types of tabs.
 * @enum {string}
 * @readonly
 */
export const TAB_TYPES = Object.freeze({
  CONTACT_INFO: 'CONTACT_INFO',
  LOGIN_INFO: 'LOGIN_INFO',
  CLOSE_ACCOUNT: 'CLOSE_ACCOUNT',
  MY_ORDERS: 'MY_ORDERS',
  FAQ: 'FAQ',
});

/**
 * @description
 * This function is the main component for the account settings screen.
 * It renders the entire account settings screen including the navigation bar,
 * the different tabs, and the content of the selected tab.
 *
 * @return {JSX.Element} The JSX element representing the account settings screen.
 */
const AccountSettings = () => {
  const auth = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  // useLayoutEffect(() => {
  //     document.body.classList.add('theme-light')

  //     return () => {
  //         document.body.classList.remove('theme-light')
  //     }
  // }, [])

  /**
   * @description
   * This variable is used to get the 'tabType' parameter from the URL.
   * It is used to determine which tab should be active in the account settings screen.
   *
   * @type {string | null}
   */
  let tabType = searchParams.get('tabType');
  const [activeTab, setactiveTab] = useState(tabType || TAB_TYPES.MY_ORDERS);

  /**
   * @description
   * This function is the event handler for the Nav.OnSelect event in the AccountSettings component.
   * It is used to handle the selection of a tab in the account settings screen.
   *
   * @param {string} eventKey - The key of the selected tab.
   * @return {void} This function does not return anything.
   */
  const handleSelect = (eventKey) => {
    console.log(eventKey);
    setactiveTab(eventKey);
  };

  return (
    <div className="h-100">
      <Container fluid className="d-flex h-100">
        <Row className="flex-grow-1 flex-nowrap">
          <Col className="p-0 d-flex flex-grow-0">
            <Nav
              activeKey={activeTab}
              onSelect={handleSelect}
              defaultActiveKey="CONTACT_INFO"
              className="flex-column vertical-nav-tabs acc-setup-nav-tabs"
            >
              <Nav.Item className="d-flex align-items-center px-3 py-4 fw-bold">
                <span className="d-none d-md-block">My Dashboard</span>
                <FontAwesomeIcon
                  title="My Orders"
                  icon={faChevronDown}
                  className="ms-2 fs-sm-1"
                />
              </Nav.Item>
              <Nav.Link
                eventKey={TAB_TYPES.MY_ORDERS}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  title="My Orders"
                  icon={faList}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">Orders</span>
              </Nav.Link>
              <Nav.Link
                eventKey={TAB_TYPES.CONTACT_INFO}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  title="Contact Information"
                  icon={faAddressBook}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">
                  Contact Information
                </span>
              </Nav.Link>
              <Nav.Link
                eventKey={TAB_TYPES.LOGIN_INFO}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  title="Login Information"
                  icon={faUser}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">
                  Login Information
                </span>
              </Nav.Link>
              <Nav.Link
                eventKey={TAB_TYPES.CLOSE_ACCOUNT}
                className="d-flex align-items-center"
              >
                <FontAwesomeIcon
                  title="Close Your Account"
                  icon={faCircleMinus}
                  className="me-1 fs-sm-1"
                />
                <span className="ms-2 d-none d-md-block">
                  Close Your Account
                </span>
              </Nav.Link>

              <Nav.Link
                eventKey={TAB_TYPES.FAQ}
                className="mt-auto d-flex align-items-center"
              >
                <FontAwesomeIcon
                  title="FAQ"
                  icon={faQuestionCircle}
                  className="me-1 fs-sm-1"
                  size="lg"
                />
                <span className="ms-2 d-none d-md-block">FAQ</span>
              </Nav.Link>
            </Nav>
          </Col>
          <Col className="flex-grow-1">
            <div className="px-3 mx-lg-5 px-lg-5 py-4">
              {/* {activeTab !== TAB_TYPES.MY_ORDERS &&
                                <Button as={Link} to={'/events'}
                                    variant="link" className="text-decoration-none px-0 mt-2">
                                    <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                                    Go Back to Events Dashboard
                                </Button>
                            } */}

              {(() => {
                switch (activeTab) {
                  case TAB_TYPES.CONTACT_INFO:
                    return <ContactInfo />;

                  case TAB_TYPES.LOGIN_INFO:
                    return <LoginInfo />;

                  case TAB_TYPES.CLOSE_ACCOUNT:
                    return <CloseAccount />;

                  case TAB_TYPES.MY_ORDERS:
                    return <MyOrders />;

                  default:
                    break;
                }
              })()}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccountSettings;
