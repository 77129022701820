import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';
import { BASE_URL } from '../constants';

/**
 * React functional component for rendering the AddNewPaymentElement.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.subscription - Flag indicating if the payment is for a subscription.
 * @return {JSX.Element} The rendered component.
 */
export default function AddNewPaymentElement(props) {
  /**
   * The `useStripe` hook returns a `stripe` object that allows you to call
   * Stripe.js methods. It will automatically handle loading the Stripe.js
   * script.
   *
   * @return {Stripe | null} The `stripe` object or `null` if it hasn't
   * loaded yet.
   */
  const stripe = useStripe();

  /**
   * The `useElements` hook returns a set of elements that allows you to call
   * Stripe.js methods. It will automatically handle loading the Stripe.js
   * script.
   *
   * @return {Elements | null} The `elements` object or `null` if it hasn't
   * loaded yet.
   */
  const elements = useElements();

  /**
   * A React state hook that manages the email.
   *
   * @return {Array} An array with the email and a function to update it.
   */
  const [email, setEmail] = useState('');

  /**
   * A React state hook that manages the message.
   *
   * @return {Array} An array with the message and a function to update it.
   */
  const [message, setMessage] = useState(null);

  /**
   * A React state hook that manages the loading status.
   *
   * @return {Array} An array with the loading status and a function to update it.
   */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * A React state hook that manages the subscription status.
   *
   * @return {Array} An array with the subscription status and a function to update it.
   */
  const [subscription, setSubscription] = useState(false);

  /**
   * A React useEffect hook that runs when the component mounts. It initializes
   * the subscription state based on the props.
   *
   * @return {void}
   */
  useEffect(() => {
    setSubscription(props.subscription);
    //console.log("FLAG ",subscription);
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  /**
   * Handles the form submission event.
   *
   * @param {Event} e - The form submission event.
   * @return {Promise<void>} A promise that resolves when the form submission is handled.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = subscription
      ? await stripe.confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          //This will be only called when we dont have any card details and wants to get card detail from user for subscription
          elements,
          confirmParams: {
            // return_url: '/'
            return_url: `${BASE_URL}/events/${props.data.eventId}?success=true`,
          },
        })
      : await stripe.confirmPayment({
          elements,
          confirmParams: {
            // return_url: '/'
            // Make sure to change this to your payment completion page
            return_url: `${BASE_URL}/events/${props.data.eventId}?success=true`,
          },
        });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  /**
   * The options for the Payment Element.
   * @type {Object}
   * @property {string} style - The style of the payment element.
   * @property {string} classes - The classes for the payment element.
   * @property {string} hidePostalCode - Whether to hide the postal code field.
   * @property {string} value - The value for the payment element.
   * @property {string} placeholder - The placeholder for the payment element.
   * @property {string} iconStyle - The style of the icon for the payment element.
   * @property {string} layout - The layout for the payment element.
   */
  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <form id="payment-form">
      {/* <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
      /> */}
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div className="d-flex mt-5">
        <Button
          className="ms-auto text-white px-4"
          variant="primary"
          disabled={isLoading || !stripe || !elements}
          onClick={handleSubmit}
          id="submit"
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : subscription ? (
              'Subscribe'
            ) : (
              'Place order'
            )}
          </span>
        </Button>
      </div>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
