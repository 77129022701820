import { Form } from 'react-bootstrap';
export default function FormInput({
  controlId,
  ariaLabel,
  label,
  type,
  placeholder,
  maxLength,
  value,
  onInputChange,
  minLength,
  children,
}) {
  return (
    <Form.Group className="mb-3" controlId={controlId}>
      <Form.Label className="fw-bold mb-1" aria-label={ariaLabel}>
        {label}
      </Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        maxLength={maxLength ? maxLength : '150'}
        value={value}
        onChange={(e) => onInputChange(e.target.value)}
        minLength={minLength}
      />
      {children}
    </Form.Group>
  );
}
