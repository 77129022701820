import React, { useState, useEffect } from 'react';
import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  Row,
  Button,
  Form,
  Modal,
  Spinner,
  Tab,
  Nav,
} from 'react-bootstrap';
import { useAsync } from '../../hooks/useAsync';
import { cancelOrder, getOrderList, getFeeDetails } from '../../utils/APIUtils';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import {
  CANCEL_ORDER_TOOLTIP,
  SERVICE_FEE_PERCENT,
  TAX_PERCENTAGE,
  TICKET_TYPE,
  RESTOCKING_FEE,
  APPLICATION_FEE,
  GATEWAY_FEE,
} from '../../constants';
import Pagination from '../../shared/Pagination';
import RefundReasonModal from './RefundReasonModal';
import ContactOrganizerModal from './ContactOrganizerModal';
import ViewReceiptModal from './ViewReceiptModal';

/**
 * Enumeration representing the steps contact organizer.
 * @enum {string}
 * @readonly
 */
const CONTACT_ORGANIZER_STEPS = Object.freeze({
  SHOW_FAQ: 'SHOW_FAQ',
  SHOW_FORM: 'SHOW_FORM',
  SHOW_DETAILS: 'SHOW_DETAILS',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
});

/**
 * Renders a table of order list.
 * @param {Object} props - The properties for the component.
 * @param {Array} props.orderList - The list of orders to display.
 * @returns {JSX.Element} - The rendered component.
 */
const OrderListTable = (props) => {
  // State hook to set the show refund reason modal state
  const [showRefundReasonModal, setShowRefundReasonModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [showContactOrganizerModal, setShowContactOrganizerModal] =
    useState(false);
  const [showViewReceiptModal, setShowViewReceiptModal] = useState(false);

  /**
   * Opens the view receipt modal.
   *
   * @param {Object} selectedOrder - The order to view the receipt for.
   */
  const viewReceiptModal = (selectedOrder) => {
    setShowViewReceiptModal(true);
    setSelectedOrder(selectedOrder);
  };

  return (
    <>
      <Row>
        <Col xs="12" className="border">
          <Row className="border-bottom fw-bold">
            <Col xs="7" className="py-3 ps-5">
              Events
            </Col>
            <Col xs="3" className="py-3 text-center">
              Ordered on
            </Col>
            <Col xs="2"></Col>
          </Row>
          {props.orderList.map((item, i) => {
            item.orderStatus = 'CONFIRMED'; // hard-coded
            return (
              <Row key={i} className="mb-1 align-items-center py-3">
                <Col xs="5" className="d-flex ps-5">
                  <img
                    className="event-banner-thumbnail me-3"
                    src="/banner.jpg"
                    alt="event thumbnail"
                  />
                  <div>
                    <h5 className="text-primary fw-bold">{item.eventName}</h5>
                    <p className="fw-bold mb-2">
                      {moment(item.orderDate).format('MMM DD / YYYY')}
                    </p>
                    <p className="mb-0">National Event Venue & Banquet Hall</p>
                  </div>
                  {/* <Button onClick={() => props.setSelectedOrder(item)} variant="link" 
                                    className="text-truncate w-100 text-secondary text-decoration-none px-0">
                                        {item.uniqueGeneratedOrderId}
                                </Button> */}
                </Col>
                <Col xs="2" className="text-center" title={item.eventName}>
                  <Button
                    variant="primary"
                    className="border-primary rounded-pill text-white mb-2 px-4"
                  >
                    View Event
                  </Button>
                  <br />
                  <Button
                    onClick={() => setShowContactOrganizerModal(true)}
                    variant="link"
                    className="text-default fw-bold"
                  >
                    Contact Organizer
                  </Button>
                </Col>
                {/* <Col className="d-none d-md-block">{item.customPromoterName || "-"}</Col> */}
                <Col
                  xs="3"
                  className="text-truncate fw-bold text-center"
                  title={item.orderDateTimeInUTC}
                >
                  {item.orderDateTimeInUTC}
                </Col>
                <Col xs="2">
                  {item.orderStatus && item.orderStatus === 'CONFIRMED' && (
                    <>
                      <Button
                        onClick={() => viewReceiptModal(item)}
                        variant="primary"
                        className="border-primary rounded-pill text-white mb-2 px-4"
                      >
                        View Receipt
                      </Button>
                      {props.eventsType == 'upcoming' && (
                        <Button
                          onClick={() => setShowRefundReasonModal(true)}
                          variant="link"
                          className="text-default fw-bold"
                        >
                          Request a refund
                        </Button>
                      )}
                    </>
                  )}
                </Col>
                {/* <Col>{item.orderStatus}</Col> */}
              </Row>
            );
          })}
        </Col>
      </Row>

      <Modal
        centered
        show={showRefundReasonModal}
        onHide={() => setShowRefundReasonModal(false)}
      >
        <Modal.Header className="bg-header" alt="EventLinx" closeButton>
          <Modal.Title className="logo" alt="EventLinx"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RefundReasonModal />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="text-white px-4">
            Send Your Request
          </Button>
          <Button
            className="px-4 btn-light border-darkGray"
            onClick={() => setShowRefundReasonModal(false)}
          >
            Discard
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={showContactOrganizerModal}
        onHide={() => setShowContactOrganizerModal(false)}
      >
        <Modal.Header className="bg-header" alt="EventLinx" closeButton>
          <Modal.Title className="logo" alt="EventLinx"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactOrganizerModal />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="text-white px-4">
            Send Your Message
          </Button>
          <Button
            className="px-4 btn-light border-darkGray"
            onClick={() => setShowContactOrganizerModal(false)}
          >
            Discard
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showViewReceiptModal}
        onHide={() => setShowViewReceiptModal(false)}
        size={'lg'}
      >
        <Modal.Header className="bg-header" alt="EventLinx" closeButton>
          <Modal.Title className="logo" alt="EventLinx"></Modal.Title>
        </Modal.Header>
        <Modal.Body className={'p-5 m-5'}>
          <div className={'px-5 mx-5'}>
            <ViewReceiptModal orderDetails={selectedOrder} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// This function is used to display the user orders.
const MyOrders = () => {
  const { data, loading, error, run } = useAsync(null);

  const [orderList, setOrderList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    run(() => getOrderList()).then((res) => {
      console.log(res.data);
      setOrderList(res.data.patronOrderDetailsDtoList);
      setTotalPages(res.data.totalPages);
    });
  }, [currentPage]);

  // states for managing contact organizer form steps.
  const [showContactOrganizerModal, setShowContactOrganizerModal] =
    useState(false);

  // State hook managing the contact organizer form steps.
  const [contactOrganizerFormStep, setContactOrganizerFormStep] = useState(
    CONTACT_ORGANIZER_STEPS.SHOW_FAQ
  );

  // State hook to manage the cancel order modal.
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);

  // State hook to manage the reason for refund.
  const [refundReason, setRefundReason] = useState('');

  // state hook to set the refund order ID.
  const [orderRefundId, setOrderRefundId] = useState('');

  // State hook to set the detail for fee.
  const [feeDetails, setFeeDetails] = useState(null);

  const [selectedTab, setSelectedTab] = useState('upcoming');

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getUserList();
  };

  // State hook to reset the contact organizer from fields.
  const resetContactOrganizerForm = () => {
    setContactOrganizerFormStep(CONTACT_ORGANIZER_STEPS.SHOW_FAQ);
  };

  // This function handle the submission of the contact form
  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    setContactOrganizerFormStep(CONTACT_ORGANIZER_STEPS.SHOW_DETAILS);
  };

  // This function is used to handle the cancellation of the order
  const handleCancelOrder = () => {
    let orderCancelDTO = {
      eventId: selectedOrder.eventId,
      orderId: selectedOrder.orderId,
      refundReason: refundReason,
      fullRefund: false,
    };

    cancelOrder(orderCancelDTO)
      .then((res) => {
        console.log(res.data);
        setOrderRefundId(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(orderCancelDTO);
  };

  // This function is used to handle the open cancel order modal.
  const handleOpenCancelOrderModal = () => {
    setShowCancelOrderModal(true);

    if (!feeDetails) {
      getFeeDetails()
        .then((res) => {
          console.log(res.data);
          setFeeDetails(res.data);
        })
        .catch((err) => {
          console.log('ERR', err);
        });
    }
  };

  // This function is used to fetch the sub total price for the ticket.
  const getSubTotalPrice = (ticketDTO) => {
    const { chargeTax, taxPercentage, ticketPrice, ticketType } = ticketDTO;

    return (
      ticketPrice +
      (chargeTax
        ? (ticketPrice * taxPercentage) / 100
        : ticketPrice * TAX_PERCENTAGE) +
      ticketPrice * SERVICE_FEE_PERCENT
    );
  };

  // This function is used to get the total price for the ticket.
  const getTotalPrice = () => {
    let subtotal = getSubTotalPrice(selectedOrder.ticketDetails);
    let discount = selectedOrder.couponDiscount || 0;

    return subtotal - discount;
  };

  // This function is used to get the refund amount.
  const getRefundAmount = () => {
    let refund =
      getTotalPrice() - RESTOCKING_FEE - GATEWAY_FEE - APPLICATION_FEE;

    if (refund < 0) {
      refund = 0;
    }

    return refund;
  };

  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <>
      {!selectedOrder && (
        <div>
          {/* <Button as={Link} to="/events"
                        variant="link" className="text-decoration-none px-0 mt-2">
                        <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                        Go Back to Events Dashboard
                    </Button> */}
          <div className="mb-5">
            <h2 className="fw-bold mt-3 mb-0">Orders</h2>
            <span>Here is the list of your orders.</span>
          </div>

          <Row>
            <Col xs="12" className="mb-5">
              <Form>
                <Form.Group className="d-flex">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="w-auto me-3"
                  ></Form.Control>
                  <Form.Control
                    type="text"
                    placeholder="Calendar"
                    className="w-auto"
                  ></Form.Control>
                </Form.Group>
              </Form>
            </Col>

            <Col xs="12" className="px-4">
              <Tab.Container
                id="orders-type-tabs"
                activeKey={selectedTab}
                onSelect={(k) => setSelectedTab(k)}
              >
                <Row>
                  <Col xs="12" className="px-0">
                    <Nav variant="tabs" className="tabs-top-right-radius">
                      <Nav.Item>
                        <Nav.Link
                          className={`ps-5 text-white ${
                            selectedTab == 'upcoming'
                              ? 'bg-success border-primary'
                              : 'bg-gray'
                          }`}
                          eventKey="upcoming"
                          title="Upcoming Events"
                        >
                          <h5 className="my-2">Upcoming Events</h5>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className={`text-white ${
                            selectedTab == 'past'
                              ? 'bg-success border-primary'
                              : 'bg-gray'
                          }`}
                          eventKey="past"
                          title="Past Events"
                        >
                          <h5 className="my-2">Past Events</h5>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col xs={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="upcoming">
                        <OrderListTable
                          eventsType="upcoming"
                          orderList={orderList}
                          setSelectedOrder={setSelectedOrder}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="past">
                        <OrderListTable
                          eventsType="past"
                          orderList={orderList}
                          setSelectedOrder={setSelectedOrder}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
          />
        </div>
      )}

      {selectedOrder && (
        <div>
          <Row>
            <Col xs="12" lg="9">
              <Button
                onClick={() => setSelectedOrder(null)}
                variant="link"
                className="text-decoration-none px-0 mt-2"
              >
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                Back to your orders
              </Button>
              <div className="mb-4">
                <h2 className="fw-bold mt-3 mb-0 border-bottom border-gray pb-3">
                  Order for {selectedOrder.eventName}
                  {selectedOrder.customPromoterName
                    ? 'by Vincent Dark' + selectedOrder.customPromoterName
                    : ''}
                </h2>
              </div>

              <Row>
                <Col xs="12" md="6" lg="8">
                  <h5 className="mb-3">
                    <strong>Order id: </strong>{' '}
                    {selectedOrder.uniqueGeneratedOrderId}
                  </h5>
                  <h5 className="mb-3">
                    <strong>Order status: </strong> {selectedOrder.orderStatus}
                  </h5>
                  <h5 className="mb-3">
                    <strong>Ordered on: </strong>{' '}
                    {moment(selectedOrder.orderDate).format(
                      'ddd MMM DD, YYYY hh:mm A'
                    )}
                  </h5>
                  <h5 className="mb-3">
                    <strong>Order Type: </strong> LiveStream
                  </h5>{' '}
                  {/* hard coded */}
                  <h5 className="mb-3">
                    <strong>Card Number: </strong> XXXX XXXX XXXX{' '}
                    {selectedOrder.lastFourDigit}
                  </h5>
                  <h5 className="mb-3 text-capitalize">
                    <strong>Card Type: </strong> {selectedOrder.cardBrand}
                  </h5>
                </Col>

                <Col xs="12" md="6" lg="4" className="ms-auto">
                  <h5>Order Summary</h5>
                  <div className="d-flex">
                    <p className="mb-1 me-5">1 x General Admission</p>
                    <span className="ms-auto">
                      CA $
                      {selectedOrder.ticketDetails.ticketType ==
                      TICKET_TYPE.PAID
                        ? selectedOrder.ticketDetails.ticketPrice
                        : 0}
                    </span>
                  </div>

                  <div className="d-flex">
                    <p className="mb-1">Fee</p>
                    <span className="ms-auto">
                      CA $
                      {selectedOrder.ticketDetails.ticketPrice *
                        SERVICE_FEE_PERCENT}
                    </span>
                  </div>

                  {/* tax hard coded */}
                  <div className="d-flex">
                    <p className="mb-1">Tax</p>
                    <span className="ms-auto">
                      CA $
                      {selectedOrder.ticketDetails.ticketPrice *
                        (selectedOrder.ticketDetails.chargeTax
                          ? selectedOrder.ticketDetails.taxPercentage / 100
                          : TAX_PERCENTAGE)}
                    </span>
                  </div>

                  <hr className="my-2" />

                  <div className="d-flex">
                    <p className="mb-1">Subtotal</p>
                    <span className="ms-auto">
                      CA $ {getSubTotalPrice(selectedOrder.ticketDetails)}
                      {/* {getTotalPrice()} */}
                    </span>
                  </div>

                  <div className="d-flex">
                    <p className="mb-1">
                      {' '}
                      Discount
                      {selectedOrder.couponDiscount && (
                        <small className="text-darkGray">
                          {' '}
                          (Coupon Applied)
                        </small>
                      )}
                    </p>
                    <span className="ms-auto">
                      - CA ${' '}
                      {selectedOrder.couponDiscount
                        ? selectedOrder.couponDiscount.couponDiscount
                        : 0}
                      {/* {appliedCoupon.couponType == 'FIXED' ? appliedCoupon.couponDiscount : 
                                                        eventDTO.eventTicketDetailsModel.price * (appliedCoupon.couponDiscount / 100)} */}
                    </span>
                  </div>

                  <hr className="my-2" />

                  <div className="d-flex">
                    <p className="mb-1">Grand Total</p>
                    <span className="ms-auto">
                      CA $ {getSubTotalPrice(selectedOrder.ticketDetails)}
                      {/* {getTotalPrice()} */}
                    </span>
                  </div>
                </Col>
              </Row>

              <hr className="my-4" />

              <h4 className="mb-3 fw-bold">Billing Details</h4>

              <h5 className="mb-2">
                {selectedOrder.orderBillingInfoDto.firstName}{' '}
                {selectedOrder.orderBillingInfoDto.lastName}
              </h5>

              <h5 className="mb-2">
                {selectedOrder.orderBillingInfoDto.email}
              </h5>

              <h5 className="mb-2">
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .addressLine1
                }
              </h5>

              <h5 className="mb-2">
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .addressLine2
                }
              </h5>

              <h5 className="mb-2">
                {selectedOrder.orderBillingInfoDto.orderBillingAddressDto.city},{' '}
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .province
                }
                ,{' '}
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .country
                }{' '}
                ({' '}
                {
                  selectedOrder.orderBillingInfoDto.orderBillingAddressDto
                    .postalCode
                }{' '}
                )
              </h5>

              {/* <div className="d-flex mb-3">
                                <Form.Group controlId="firstName" className="me-4">
                                    <Form.Label className="required">First Name</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        value={selectedOrder.orderBillingInfoDto.firstName} 
                                        readOnly />
                                </Form.Group>
                                <Form.Group controlId="lastName">
                                    <Form.Label className="required">Last Name</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        value={selectedOrder.orderBillingInfoDto.lastName}
                                        readOnly 
                                    />
                                </Form.Group>
                            </div>


                            <div className="d-flex mb-3">
                                <Form.Group controlId="email">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        value={selectedOrder.orderBillingInfoDto.email}
                                        readOnly />
                                </Form.Group>
                            </div>
                            
                            <div className="d-flex mb-3">
                                <Form.Group controlId="deliveryMethod">
                                    <Form.Label>Delivery method</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        value="e-Ticket"
                                        readOnly
                                    />
                                </Form.Group>
                            </div> */}

              <div className="mt-4 pt-3">
                {/* <Button variant="primary" className="text-white">
                                    Print Ticket
                                </Button> */}

                <Tooltip title={<p className="mb-0">{CANCEL_ORDER_TOOLTIP}</p>}>
                  <Button
                    onClick={handleOpenCancelOrderModal}
                    disabled={selectedOrder.orderStatus != 'CONFIRMED'}
                    variant="outline-dark"
                    className="me-3"
                  >
                    Cancel Order Request
                  </Button>
                </Tooltip>

                <Button
                  onClick={() => setShowContactOrganizerModal(true)}
                  variant="orange"
                  className="text-white"
                >
                  Contact Organizer
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      )}

      <Modal
        show={showCancelOrderModal}
        onHide={() => {
          setShowCancelOrderModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="px-3">
            Are you sure you want to cancel this order?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 pb-5">
          {selectedOrder && (
            <>
              <Row className="mb-2">
                <Col>Order Amount</Col>
                <Col>${getTotalPrice()}</Col>
              </Row>

              <Row className="mb-2">
                <Col>Restocking Fee</Col>
                <Col>- ${RESTOCKING_FEE}</Col>
              </Row>

              <Row className="mb-2">
                <Col>Application Fee</Col>
                <Col>- ${APPLICATION_FEE}</Col>
              </Row>

              <Row className="mb-2">
                <Col>Payment Gateway Fee</Col>
                <Col>- ${GATEWAY_FEE}</Col>
              </Row>

              <hr />

              <Row className="mb-2">
                <Col>Refund Amount</Col>
                <Col>${getRefundAmount()}</Col>
              </Row>

              <Form.Group controlId="refundReason" className="mt-3">
                <Form.Label>Reason (optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  value={refundReason}
                  onChange={(e) => setRefundReason(e.target.value)}
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!orderRefundId && (
            <>
              <Button
                onClick={() => setShowCancelOrderModal(false)}
                variant="outline-dark"
              >
                No
              </Button>
              <Button
                onClick={handleCancelOrder}
                className="text-white"
                variant="danger"
              >
                Yes, Cancel
              </Button>
            </>
          )}

          {orderRefundId && (
            <Button
              onClick={() => setShowCancelOrderModal(false)}
              variant="outline-dark"
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showContactOrganizerModal}
        onHide={() => {
          setShowContactOrganizerModal(false);
          resetContactOrganizerForm();
        }}
      >
        <Modal.Header
          closeButton
          className={`${
            contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_MESSAGE
              ? 'border-0'
              : ''
          }`}
        >
          {contactOrganizerFormStep != CONTACT_ORGANIZER_STEPS.SHOW_MESSAGE && (
            <Modal.Title className="px-4">Contact Organizer</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className="px-5 pb-5">
          {contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_FAQ && (
            <>
              <p className="mb-2">FAQ</p>
              <Button
                variant="link"
                className="text-decoration-none text-secondary px-0"
              >
                Can I get a refund?
              </Button>
              <br />
              <Button
                variant="link"
                className="text-decoration-none text-secondary px-0"
              >
                How to update your ticket information?
              </Button>
              <br />
              <p className="mb-2 mt-4">Have a question for organizer?</p>
              <Button
                onClick={() =>
                  setContactOrganizerFormStep(CONTACT_ORGANIZER_STEPS.SHOW_FORM)
                }
                variant="orange"
                className="text-white mt-2"
              >
                Contact Organizer
              </Button>
            </>
          )}
          {contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_FORM && (
            <Form>
              <div className="d-flex mb-3">
                <Form.Group controlId="yourName" className="me-3">
                  <Form.Label className="required">Your name</Form.Label>
                  <Form.Control id="yourName" type="text" />
                </Form.Group>

                <Form.Group controlId="yourEmail">
                  <Form.Label className="required">
                    Your email address
                  </Form.Label>
                  <Form.Control id="yourEmail" type="text" />
                </Form.Group>
              </div>

              <Form.Group controlId="subject" className="mb-3">
                <Form.Label className="required">Subject</Form.Label>
                <Form.Control id="subject" type="text" />
              </Form.Group>

              <Form.Group controlId="message" className="mb-4">
                <Form.Label className="required">Message</Form.Label>
                <Form.Control as="textarea" id="message" />
              </Form.Group>
              <div className="d-flex mt-5">
                <Button
                  onClick={() =>
                    setContactOrganizerFormStep(
                      CONTACT_ORGANIZER_STEPS.SHOW_FAQ
                    )
                  }
                  variant="outline-dark"
                  className="ms-auto me-2"
                >
                  Back
                </Button>
                <Button
                  onClick={handleContactFormSubmit}
                  variant="orange"
                  type="submit"
                  className="text-white px-4"
                >
                  Contact Organizer
                </Button>
              </div>
            </Form>
          )}
          {contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_DETAILS && (
            <div>
              <div className="d-flex mb-3">
                <div className="flex-grow-1">
                  <p className="mb-2">Contact Information</p>
                  <span>Maria R</span>
                </div>

                <div className="flex-grow-1">
                  <p className="mb-2">Your email address</p>
                  <span>Maria@gmail.com</span>
                </div>
              </div>

              <div className="mb-3">
                <p className="mb-2">Subject</p>
                <span>Refund ticket</span>
              </div>

              <div className="mb-3">
                <p className="mb-2">Message</p>
                <span>I need to get some information about this event</span>
              </div>

              <div className="d-flex mt-5">
                <Button
                  onClick={() =>
                    setContactOrganizerFormStep(
                      CONTACT_ORGANIZER_STEPS.SHOW_FORM
                    )
                  }
                  variant="outline-dark"
                  className="ms-auto me-2"
                >
                  Back
                </Button>
                <Button
                  onClick={() =>
                    setContactOrganizerFormStep(
                      CONTACT_ORGANIZER_STEPS.SHOW_MESSAGE
                    )
                  }
                  variant="orange"
                  className="text-white px-4"
                >
                  Submit
                </Button>
              </div>
            </div>
          )}

          {contactOrganizerFormStep == CONTACT_ORGANIZER_STEPS.SHOW_MESSAGE && (
            <div className="p-5 text-center">
              <h3>Thank you.</h3>
              <p>Your message was sent. The organizer will get back you soon</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyOrders;
