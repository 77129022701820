import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Row, Nav, Navbar, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SOCIAL_MEDIA_URLS } from '../constants';
import PrivacyPolicy from '../policies/PrivacyPolicy';
import TermsAndConditions from '../policies/TermsAndConditions';
import { openExternalLinkInPopupWindow } from '../utils/CommonUtils';

/**
 * Renders the footer section of the application.
 *
 * @return {JSX.Element} The rendered footer component.
 */
const Footer = () => {
  // State hook to manage the "show privacy" modal.
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  // State hook to manage the "show term" modal.
  const [showTermsModal, setShowTermsModal] = useState(false);

  /**
   * Navigates the user to the promoter page.
   *
   * This function checks the current environment and opens the promoter page
   * in a new browser window or tab.
   *
   * @return {void} This function does not return anything.
   */
  const navigateToPromoter = () => {
    if (process.env.NODE_ENV == 'development') {
      window.open('http://localhost:5000', '_blank');
    } else {
      window.open(' https://eventlinx-promoter.pages.dev/', '_blank');
    }
  };

  /**
   * Opens a social media link in a popup window.
   *
   * @param {string} url - The URL of the social media link.
   * @param {string} name - The name of the social media platform.
   * @return {void} This function does not return anything.
   */
  const openSocialMediaInPopup = (url, name) => {
    openExternalLinkInPopupWindow(url, name, null);
  };

  return (
    <footer className="main">
      <Row>
        <Col xs="12" className="d-lg-flex d-none">
          <Navbar.Brand as={Link} className="m-auto logo" to="home">
            {' '}
          </Navbar.Brand>
        </Col>
        <Col xs="6" lg="4" className="justify-content-center d-flex">
          <Nav className="flex-column mt-4 small">
            <Nav.Link className="fw-bold text-white" href="/home">
              Home
            </Nav.Link>
            <Nav.Link className="fw-bold text-white" href="/about">
              About Us
            </Nav.Link>
            <Nav.Link className="fw-bold text-white" href="/events">
              Live Events
            </Nav.Link>
            <Nav.Link disabled className="fw-bold">
              Streams
            </Nav.Link>
            <Nav.Link disabled className="fw-bold">
              FAQs
            </Nav.Link>

            {/* <Nav.Link className="fw-bold" href="/support">Support</Nav.Link> */}
            {/* <Nav.Link onClick={() => setShowPrivacyModal(true)} className="fw-bold" >Privacy Policy</Nav.Link>
                            <Nav.Link onClick={() => setShowTermsModal(true)} className=" fw-bold" >Terms of use</Nav.Link> */}
          </Nav>
        </Col>

        {/* section not visible on mobile screen */}
        <Col className="justify-content-center d-lg-flex d-none">
          <Nav className="flex-column text-center text-white">
            <small className="fw-bold mb-0 mt-4">Social Media</small>
            <small className="fw-light">Follow us on Social media</small>
            <Nav className="flex-row mb-5 justify-content-center">
              <Nav.Link
                onClick={() =>
                  openSocialMediaInPopup(SOCIAL_MEDIA_URLS.TWITTER, 'twitter')
                }
                className="px-1 d-flex"
              >
                <span className="twitter-logo">
                  <FontAwesomeIcon icon={faTwitter} className="text-white" />
                </span>
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  openSocialMediaInPopup(SOCIAL_MEDIA_URLS.FACEBOOK, 'facebook')
                }
                className="px-1"
              >
                <img src="/facebook.svg" alt="facebook logo" />
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  openSocialMediaInPopup(SOCIAL_MEDIA_URLS.YOUTUBE, 'youtube')
                }
                className="px-1"
              >
                <img src="/youtube.svg" alt="youtube logo" />
              </Nav.Link>

              <Nav.Link
                onClick={() =>
                  openSocialMediaInPopup(
                    SOCIAL_MEDIA_URLS.FACEBOOK,
                    'instagram'
                  )
                }
                className="px-1"
              >
                <img src="/instagram.svg" alt="instagram logo" />
              </Nav.Link>
            </Nav>
            <div>
              <small className="mt-5">
                Copyright 2024 EventLinx Inc. All rights reserved.{' '}
              </small>{' '}
              <br />
              <small className="mt-5">
                Please read our
                <a className="link" onClick={() => setShowPrivacyModal(true)}>
                  {' '}
                  Privacy Policy{' '}
                </a>{' '}
                and
                <a className="link" onClick={() => setShowTermsModal(true)}>
                  {' '}
                  Terms of Use.{' '}
                </a>
              </small>
              <br />
              <div className="mt-3">
                <img src="/Sectigo_trust.png" />
              </div>
            </div>
          </Nav>
        </Col>

        <Col xs="6" lg="4" className="justify-content-center d-flex">
          <Nav className="flex-column mt-4 small">
            <Nav.Link
              as={Link}
              to={'/contact-us'}
              className="fw-bold text-white"
            >
              Support/Contact Us
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={'/schedule-demo'}
              className="fw-bold text-white"
            >
              Schedule a Demo
            </Nav.Link>
            <Nav.Link
              onClick={() => navigateToPromoter()}
              className="fw-bold text-white"
            >
              Promoter Login
            </Nav.Link>
            <Nav.Link as={Link} to={'/login'} className="fw-bold text-white">
              Patron Login
            </Nav.Link>
            {/* <Nav.Link className="fw-bold text-white">Become an Affiliate</Nav.Link> */}
          </Nav>
        </Col>
        
        {/* section not visible on large screen */}
        <Col className="justify-content-center d-lg-none d-flex">
          <Nav className="flex-column text-center text-white">
            <small className="fw-bold mb-0 mt-4">Social Media</small>
            <small className="fw-light">Follow us on Social media</small>
            <Nav className="flex-row justify-content-center">
              <Nav.Link
                onClick={() =>
                  openSocialMediaInPopup(SOCIAL_MEDIA_URLS.TWITTER, 'twitter')
                }
                className="px-1 d-flex"
              >
                <span className="twitter-logo">
                  <FontAwesomeIcon icon={faTwitter} className="text-white" />
                </span>
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  openSocialMediaInPopup(SOCIAL_MEDIA_URLS.FACEBOOK, 'facebook')
                }
                className="px-1"
              >
                <img src="/facebook.svg" alt="facebook logo" />
              </Nav.Link>
              <Nav.Link
                onClick={() =>
                  openSocialMediaInPopup(SOCIAL_MEDIA_URLS.YOUTUBE, 'youtube')
                }
                className="px-1"
              >
                <img src="/youtube.svg" alt="youtube logo" />
              </Nav.Link>

              <Nav.Link
                onClick={() =>
                  openSocialMediaInPopup(
                    SOCIAL_MEDIA_URLS.FACEBOOK,
                    'instagram'
                  )
                }
                className="px-1"
              >
                <img src="/instagram.svg" alt="instagram logo" />
              </Nav.Link>
            </Nav>

            <div className="d-lg-none my-4">
              <Navbar.Brand as={Link} className="m-auto logo d-inline-block" to="home">
                {' '}
              </Navbar.Brand>
            </div>
            <div>
              <small className="mt-5">
                Copyright 2024 EventLinx Inc. All rights reserved.{' '}
              </small>{' '}
              <br />
              <small className="mt-5">
                Please read our
                <a className="link" onClick={() => setShowPrivacyModal(true)}>
                  {' '}
                  Privacy Policy{' '}
                </a>{' '}
                and
                <a className="link" onClick={() => setShowTermsModal(true)}>
                  {' '}
                  Terms of Use.{' '}
                </a>
              </small>
              <br />
              <div className="my-4">
                <img src="/Sectigo_trust.png" />
              </div>
            </div>
          </Nav>
        </Col>

      </Row>
      {/* <div className="text-center mt-4">
        <div className="justify-content-center d-flex d-md-none">
          <Nav className="flex-row pt-4 justify-content-center">
            <Nav.Link
              onClick={() =>
                openSocialMediaInPopup(SOCIAL_MEDIA_URLS.TWITTER, 'twitter')
              }
              className="px-1 d-flex"
            >
              <span className="twitter-logo">
                <FontAwesomeIcon icon={faTwitter} className="text-white" />
              </span>
 
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                openSocialMediaInPopup(SOCIAL_MEDIA_URLS.FACEBOOK, 'facebook')
              }
              className="px-1"
            >
              <img src="/facebook.svg" alt="facebook logo" />
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                openSocialMediaInPopup(SOCIAL_MEDIA_URLS.YOUTUBE, 'youtube')
              }
              className="px-1"
            >
              <img src="/youtube.svg" alt="youtube logo" />
            </Nav.Link>
            <Nav.Link
              onClick={() =>
                openSocialMediaInPopup(SOCIAL_MEDIA_URLS.FACEBOOK, 'facebook')
              }
              href="#"
              className="px-1"
            >
              <img src="/instagram.svg" alt="instagram logo" />
            </Nav.Link> 
          </Nav>
        </div> 
      </div> */}
      <Modal
        size="xl"
        show={showPrivacyModal || showTermsModal}
        onHide={() => {
          setShowPrivacyModal(false);
          setShowTermsModal(false);
        }}
      >
        <Modal.Header className="border-0" closeButton>
          {' '}
        </Modal.Header>
        <Modal.Body>
          {showPrivacyModal && <PrivacyPolicy />}
          {showTermsModal && <TermsAndConditions />}
        </Modal.Body>
      </Modal>
    </footer>
  );
};

export default Footer;
