import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
// import ReCAPTCHA from 'react-google-recaptcha';
import { login2 } from '../utils/APIUtils';
import FormInput from '../Components/FormInput';
// import Error from '../shared/Error';
import useRecaptcha from '../hooks/useRecaptcha';
import { useAuth } from '../hooks/useAuth';
import ToastMessage from '../shared/ToastMessage';
import AuthenticationHead from '../Components/AuthenticationHead';
import AuthenticationFoot from '../Components/AuthenticationFoot';
import KeepMeSignAndForgot from '../Components/KeepMeSignAndForgot';
import { verifyemail } from '../utils/APIUtils';
import ConfirmVerificationCode from '../Components/ConfirmVerificationCode';

const storageUtils = require('../utils/LocalStorageUtils');

// React functional component export the default function named login
/**
 * Login function is a React functional component that exports a default function
 * named Login. This component is responsible for handling user login functionality.
 *
 * @return {JSX.Element} Returns a JSX element that renders the login form.
 */
export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  // States for controlled components
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberUser, setRememberUser] = useState(false);
  // Remaining states
  const [errors, setErrors] = useState({});
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });
  const [pendingAuthToken, setPendingAuthToken] = useState('');
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [captchaResponse, setCaptchaResponsed] = useRecaptcha();

  /**
   * A useEffect hook that runs when the component mounts.
   * It retrieves the email of a previously logged in user from local storage and sets the email state.
   *
   * @return {void}
   */
  useEffect(() => {
    const loggedInUser = storageUtils.getItem('email');
    if (loggedInUser) {
      const foundUser = loggedInUser;
      setEmail(foundUser.email);
    }
  }, []);

  /**
   * Resets the form state.
   *
   * @return {void} This function does not return anything.
   */
  const resetFormState = () => {
    setErrors({});
    setEmail('');
    setPassword('');
    setPendingAuthToken('');
    setEmailVerificationCode('');
    if (process.env.NODE_ENV !== 'development') {
      window.grecaptcha.reset();
    }
  };

  /**
   * A function that handles the successful login response.
   *
   * @param {Object} loginData - The login data object.
   * @param {Object} response - The response object.
   * @return {void}
   */
  const handleLoginSuccess = (email, response) => {
    console.log('handleLoginSuccess');
    let loggedInUser = response.data;
    console.log(loggedInUser);
    // store the user email in localStorage if the user wants to be remembered
    if (rememberUser) {
      storageUtils.setItem('email', email);
    }

    // storageUtils.setItem(LOGGED_IN_USER, loggedInUser);
    // storageUtils.setItem(ACCESS_TOKEN, loggedInUser.idToken);
    auth.setUser(loggedInUser);
    resetFormState();

    setTimeout(() => {
      navigate('/events');
    }, 100);
  };

  /**
   * A function that handles login errors.
   *
   * @param {Error} err - The error object.
   * @return {void}
   */
  const handleLoginError = (err) => {
    if (!err.error) {
      err.error = 'Something went wrong please try again later';
    }
    setErrors({ auth: err.response.data.message });
    setToast({
      show: true,
      success: false,
      msg: err.response.data.message,
    });

    if (process.env.NODE_ENV !== 'development') {
      window.grecaptcha.reset();
    }
  };

  /**
   * A function that submits the form.
   *
   * @return {void} This function does not return anything.
   */
  const submitForm = (e) => {
    e.preventDefault();
    console.log('submitForm');
    const loginData = {
      email: email,
      password: password,
      // captchaResponse: captchaResponse
    };
    login2(loginData, captchaResponse)
      .then((res) => {
        console.log('login2 res: ', res);
        if (res.data.pending_authentication_token) {
          setPendingAuthToken(res.data.pending_authentication_token);
        } else {
          handleLoginSuccess(email, res);
        }
        // handleLoginSuccess(email, res);

        // if (res.data.pending_authentication_token) {
        //   // setPendingAuthToken(res.data.pending_authentication_token);
        //   // setIsCodeRequested(true);
        //   setToast({ show: true, success: true, msg: res.data.message });
        // } else {
        //   handleLoginSuccess(email, res);
        // }
      })
      .catch((err) => {
        handleLoginError(err);
      });
  };

  const handleAuthenticationSuccess = (registrationData, response) => {
    console.log('response in handleAuthenticationSuccess', response);

    let loggedInUser = response.data.user;
    console.log('loggedInUser', loggedInUser);

    // store the user's email in localStorage if remember me is checked
    if (rememberUser) {
      storageUtils.setItem('email', registrationData.email);
    }

    // Set user on the auth context
    auth.setUser(loggedInUser);

    // Navigate to events
    setTimeout(() => {
      navigate('/events');
    }, 100);
  };

  const handleSubmitVerificationCode = (e) => {
    e.preventDefault();
    verifyemail(emailVerificationCode, pendingAuthToken)
      .then((res) => {
        const userData = {
          email: email,
          password: password,
          // captchaResponse: captchaResponse
        };
        resetFormState();
        handleAuthenticationSuccess(userData, res);
      })
      .catch((e) => {
        setEmailVerificationCode('');
      });
  };

  return (
    <div
      id="banner"
      className="h-100 d-flex align-items-center justify-content-center"
    >
      <Card className="login-box">
        <Card.Body className="p-4">
          <AuthenticationHead isLogin={true} />
          {/* login form */}
          {!pendingAuthToken && (
            <Form onSubmit={submitForm}>
              <FormInput
                controlId={'formBasicEmail'}
                ariaLabel={'username'}
                label={'Username'}
                type={'email'}
                placeholder={'Enter your email address'}
                value={email}
                onInputChange={setEmail}
              >
                {errors.email && (
                  <Form.Text className="text-danger">{errors.email}</Form.Text>
                )}
              </FormInput>

              <FormInput
                controlId={'formBasicPassword'}
                ariaLabel={'password'}
                label={'Password'}
                type={'password'}
                placeholder={'Password'}
                value={password}
                onInputChange={setPassword}
                minLength={8}
              >
                {errors.password && (
                  <Form.Text className="text-danger">
                    {errors.password}
                  </Form.Text>
                )}
              </FormInput>

              <KeepMeSignAndForgot
                onChangeChecked={setRememberUser}
                rememberUser={rememberUser}
              />

              {process.env.NODE_ENV !== 'development' && (
                <div id="captchaDiv"></div>
              )}
              {/* <ReCAPTCHA
                          sitekey="6Le7W5EgAAAAAFbXUO0Ur9oFlf_ic7YeQV-O9xyx" // demo site key added for testing => 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
                          onChange={onChange}
                      /> */}
              {process.env.NODE_ENV !== 'development' && errors.captcha && (
                <Form.Text className="text-danger">{errors.captcha}</Form.Text>
              )}

              <Card.Text className="fs-10 mt-3">
                By continuing past this page, you agree to the Terms of Use and
                understand that information will be used as described in our
                Privacy Policy.
              </Card.Text>

              <Button
                variant="primary"
                type="submit"
                className="w-100 fw-bold text-uppercase text-white"
              >
                Log In
              </Button>
            </Form>
          )}
          {pendingAuthToken && (
            <ConfirmVerificationCode
              handleSubmitVerificationCode={handleSubmitVerificationCode}
              emailVerificationCode={emailVerificationCode}
              setEmailVerificationCode={setEmailVerificationCode}
              errors={errors}
            />
          )}
          <AuthenticationFoot isLogin={true} />
        </Card.Body>
      </Card>
      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />

      {/* <Error /> */}
    </div>
  );
}
