import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import Accordion from 'react-bootstrap/Accordion';

/**
 * @function FAQ
 * @description A React functional component that renders the frequently asked questions (FAQ) section of the website.
 * @returns {JSX.Element} The JSX element that represents the FAQ section of the website.
 */
const FAQ = () => {
  return (
    <Container className="bg-white text-dark" fluid>
      <Row className="py-5 mb-4">
        <Col xs="10" className="mx-auto">
          <h1 className="fw-bold">
            FREQUENTLY ASKED <span className="text-primary">QUESTIONS</span>{' '}
          </h1>

          <Row className=" mt-5 pt-4">
            <Col xs="12">
              <Accordion defaultActiveKey="0" className="faq-accordion">
                <Accordion.Item
                  eventKey="0"
                  className="border border-dark border-2 mb-4"
                >
                  <Accordion.Header>
                    <h4 class="mb-0 me-3 fw-bold">01</h4>
                    <h4 className="mb-0">Is there a fee to use EventLinx?</h4>
                  </Accordion.Header>
                  <Accordion.Body className="ms-5">
                    EventLinx is an innovative online platform designed to make
                    event management and ticketing easy and accessible for
                    everyone. Whether you're organizing a small local gathering
                    or a large-scale concert, our platform allows you to create,
                    promote, and manage events seamlessly. Attendees can easily
                    browse upcoming events, purchase tickets, and manage their
                    bookings all in one place.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  eventKey="1"
                  className="border border-dark border-2 mb-4"
                >
                  <Accordion.Header>
                    <h4 class="mb-0 me-3 fw-bold">02</h4>
                    <h4 className="mb-0">How do I create an account?</h4>
                  </Accordion.Header>
                  <Accordion.Body className="ms-5">
                    EventLinx is an innovative online platform designed to make
                    event management and ticketing easy and accessible for
                    everyone. Whether you're organizing a small local gathering
                    or a large-scale concert, our platform allows you to create,
                    promote, and manage events seamlessly. Attendees can easily
                    browse upcoming events, purchase tickets, and manage their
                    bookings all in one place.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="2"
                  className="border border-dark border-2 mb-4"
                >
                  <Accordion.Header>
                    <h4 class="mb-0 me-3 fw-bold">03</h4>
                    <h4 className="mb-0">How do I create an event?</h4>
                  </Accordion.Header>
                  <Accordion.Body className="ms-5">
                    EventLinx is an innovative online platform designed to make
                    event management and ticketing easy and accessible for
                    everyone. Whether you're organizing a small local gathering
                    or a large-scale concert, our platform allows you to create,
                    promote, and manage events seamlessly. Attendees can easily
                    browse upcoming events, purchase tickets, and manage their
                    bookings all in one place.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="3"
                  className="border border-dark border-2 mb-4"
                >
                  <Accordion.Header>
                    <h4 class="mb-0 me-3 fw-bold">04</h4>
                    <h4 className="mb-0">
                      Can I edit my event after it’s been published?
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body className="ms-5">
                    EventLinx is an innovative online platform designed to make
                    event management and ticketing easy and accessible for
                    everyone. Whether you're organizing a small local gathering
                    or a large-scale concert, our platform allows you to create,
                    promote, and manage events seamlessly. Attendees can easily
                    browse upcoming events, purchase tickets, and manage their
                    bookings all in one place.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="4"
                  className="border border-dark border-2 mb-4"
                >
                  <Accordion.Header>
                    <h4 class="mb-0 me-3 fw-bold">05</h4>
                    <h4 className="mb-0">
                      Can I get a refund if I can’t attend an event?
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body className="ms-5">
                    EventLinx is an innovative online platform designed to make
                    event management and ticketing easy and accessible for
                    everyone. Whether you're organizing a small local gathering
                    or a large-scale concert, our platform allows you to create,
                    promote, and manage events seamlessly. Attendees can easily
                    browse upcoming events, purchase tickets, and manage their
                    bookings all in one place.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className="px-0">
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
