import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBell, faCircleArrowUp, faHeart, faLocationDot, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Nav,
  Navbar,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';
// import { ACCESS_TOKEN } from '../constants';

/**
 * A React functional component that renders the home page.
 *
 * @return {JSX.Element} The rendered home page.
 */
function Home() {
  /**
   * Navigates the user to the promoter page.
   *
   * @return {void} This function does not return anything.
   */
  const navigateToPromoter = () => {
    if (process.env.NODE_ENV == 'development') {
      window.open('http://localhost:5000', '_blank');
    } else {
      window.open(' https://eventlinx-promoter.pages.dev/', '_blank');
    }
  };

  return (
    <div className="h-100">
      {/* banner section starts */}
      <section>
        <div className="homebanner">
          <div id="bannertext" className="p-5 mb-5 ms-4 mt-auto mb-auto">
            <h1 className="text-uppercase fw-bold">
              Your Camera, Your Concert
            </h1>
            <h4>See how to produce your own event</h4>
            {/* <Button variant="primary" className="text-white rounded-0 mt-2 px-4 py-2" >
                        <h5 className="mb-0">Learn More</h5>
                    </Button> */}
          </div>
        </div>
      </section>
      {/* banner section ends */}

      <section>
        <Container fluid className="bg-header">
          <Row className="logo-in-center">
            <Col
              xs="12"
              md="6"
              className="d-flex align-items-center justify-content-center p-4 section"
            >
              <div className="p-lg-5 w-75 mt-md-0 mt-lg-5">
                <h1 className="text-uppercase fw-bold">EVENTS</h1>
                <h4>Discover a live event or stream</h4>
                <h6>Patrons | Audiences | Spectators</h6>
                <h6>Get Started Now!</h6>
                <div className="d-flex">
                  <Button
                    as={Link}
                    to={'/events'}
                    variant="primary"
                    className="mt-4 rounded-pill text-white px-lg-5 px-3 ms-auto py-3"
                  >
                    <h5 className="mb-0">Search Event</h5>
                  </Button>
                </div>
              </div>
            </Col>
            <Col
              xs="12"
              md="6"
              className="bg-primary d-flex align-items-center justify-content-center p-4 section"
            >
              <div className="p-lg-5 w-75 mt-md-0 mt-lg-5">
                <h1 className="text-uppercase fw-bold">Are You A Promoter?</h1>
                <h4>Start selling tickets now.</h4>
                <h6>Promoters | Venues | Event Planners</h6>
                <h6>Get Started Now!</h6>

                <div className="d-flex">
                  <Button
                    variant="dark"
                    onClick={() => navigateToPromoter()}
                    className="rounded-pill mt-4 text-white px-lg-5 px-3 ms-auto py-3"
                  >
                    <h5 className="mb-0">Create Event</h5>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="p-4 bg-white text-dark">
            <Col xs="6" lg="4">
              <div className="text-center p-lg-4 m-lg-4">
                <img className="img-fluid" src="/link-icon.svg" />
                <h3 className="fw-bold mt-4">Link</h3>
                <p className="mb-0">(Promote an event)</p>
                <p>
                  Create your event in minutes and start linking to your
                  audience
                </p>
              </div>
            </Col>
            <Col  xs="6" lg="4">
              <div className="text-center p-lg-4 m-lg-4">
                <img className="img-fluid" src="/share-icon.svg" />
                <h3 className="fw-bold mt-4">Share</h3>
                <p className="mb-0"> (Be an Influencer)</p>
                <p>
                  Discounts, Prizes & Rewards Promote on social media share in
                  the experience
                </p>
              </div>
            </Col>
            <Col  xs="12" lg="4">
              <div className="text-center p-lg-4 m-lg-4">
                <img className="img-fluid" src="/connect-icon.svg" />
                <h3 className="fw-bold mt-4">Connect</h3>
                <p className="mb-0">(Stay in the loop)</p>
                <p>
                  Create the experience and connect with fans while building
                  your audience
                </p>
              </div>
            </Col>
          </Row>
          <Row className="p-4">
            <Col>
              <div className="text-center p-lg-5 m-lg-5">
                <h2 className="mb-4">
                  “Experience a live event the way it should be”
                </h2>
                <h2>Live Music| Sports | Theater | Special Events </h2>
              </div>
            </Col>
          </Row>

          {/* <Row className='bg-white text-dark'>                    
                        <Col className='p-0 my-4'>
                            <img className="img-fluid w-100" src='/livestream-image.png' />
                        </Col>
                    </Row> */}

          <Row className='bg-white text-dark'>
            {/* <Col xs={12} className="p-5 text-center">
                            <h2 className="fw-bold">Featured Genres</h2>
                        </Col>
                        <Col xs={12} className="my-4">
                            <ul className="artists-carousel">
                                <li>
                                    <picture>
                                        <source srcSet="/photo1.png" media="(min-width: 768px)" />
                                        <img src="/photo1.png" alt="artist 1" />
                                    </picture>
                                    <h3 className="text-lightGray">Concert</h3>
                                </li>

                                <li>
                                    <picture>
                                        <source srcSet="/photo2.png" media="(min-width: 768px)" />
                                        <img src="/photo2.png" alt="artist 2" />
                                    </picture>
                                    <h3 className="text-lightGray">Rock</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo3.png" media="(min-width: 768px)" />
                                        <img src="/photo3.png" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Pop</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo4.png" media="(min-width: 768px)" />
                                        <img src="/photo4.png" alt="artist 1" />
                                    </picture>
                                    <h3 className="text-lightGray">Classic</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo5.png" media="(min-width: 768px)" />
                                        <img src="/photo5.png" alt="artist 2" />
                                    </picture>
                                    <h3 className="text-lightGray">Funk</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo9.jpeg" media="(min-width: 768px)" />
                                        <img src="/photo9.jpeg" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Jazz</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo6.png" media="(min-width: 768px)" />
                                        <img src="/photo6.png" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Country</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo7.jpeg" media="(min-width: 768px)" />
                                        <img src="/photo7.jpeg" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Jazz</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo8.jpeg" media="(min-width: 768px)" />
                                        <img src="/photo8.jpeg" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Jazz</h3>
                                </li>
                               
                                
                            </ul>
                        </Col>

                        <Col xs={12}  className="my-4">
                        <ul className="artists-carousel artists-carousel--reverse">
                        <li>
                                    <picture>
                                        <source srcSet="/photo1.png" media="(min-width: 768px)" />
                                        <img src="/photo1.png" alt="artist 1" />
                                    </picture>
                                    <h3 className="text-lightGray">Concert</h3>
                                </li>

                                <li>
                                    <picture>
                                        <source srcSet="/photo2.png" media="(min-width: 768px)" />
                                        <img src="/photo2.png" alt="artist 2" />
                                    </picture>
                                    <h3 className="text-lightGray">Rock</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo3.png" media="(min-width: 768px)" />
                                        <img src="/photo3.png" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Pop</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo4.png" media="(min-width: 768px)" />
                                        <img src="/photo4.png" alt="artist 1" />
                                    </picture>
                                    <h3 className="text-lightGray">Classic</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo5.png" media="(min-width: 768px)" />
                                        <img src="/photo5.png" alt="artist 2" />
                                    </picture>
                                    <h3 className="text-lightGray">Funk</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo9.jpeg" media="(min-width: 768px)" />
                                        <img src="/photo9.jpeg" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Jazz</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo6.png" media="(min-width: 768px)" />
                                        <img src="/photo6.png" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Country</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo7.jpeg" media="(min-width: 768px)" />
                                        <img src="/photo7.jpeg" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Jazz</h3>
                                </li>
                                <li>
                                    <picture>
                                        <source srcSet="/photo8.jpeg" media="(min-width: 768px)" />
                                        <img src="/photo8.jpeg" alt="artist 3" />
                                    </picture>
                                    <h3 className="text-lightGray">Jazz</h3>
                                </li>
                            </ul>
                        </Col> */}

            {/* <Row>
                            <Col xs={4}>
                                <img src="/photo1.png" alt="artist 1" height="300" width="100%"></img>
                            </Col>
                            <Col xs={4}>
                                <img src="/photo2.png" alt="artist 2" height="300" width="100%"></img>
                            </Col>
                            <Col xs={4}>
                                <img  src="/photo3.png" alt="artist 3" height="300" width="100%"></img>
                            </Col>
                        </Row>  */}
            <Col xs={12} className="text-center py-5">
              <Col xs={10} lg={3} md={6} className="m-auto py-5 ">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label
                      className="mb-3 fw-bold"
                      as="h4"
                      htmlFor="emailsubscribe"
                    >
                      Stay up to date with EventLinx
                    </Form.Label>
                    <Form.Control
                      className="rounded-0 px-4 py-3 shadow"
                      id="emailsubscribe"
                      placeholder="Enter your email address"
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    className="text-white rounded-0 mt-2 px-5 py-3"
                  >
                    <h5 className="m-0">Subscribe</h5>
                  </Button>
                </Form>
              </Col>
            </Col>
          </Row>

          
        </Container>
        <Footer></Footer>
      </section>
    </div>
  );
}

export default Home;
