import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';

/**
 * Renders a form to get the reason to close an account.
 *
 * @return {JSX.Element} The rendered form.
 */
const CloseAccount = () => {
  /**
   * Handles the form submission event.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmit = (e) => {
    // This function prevent the default way of form submission
    e.preventDefault();
  };

  const [password, setPassword] = useState('');

  return (
    <>
      <h2 className="fw-bold mt-3 mb-0">Close your account</h2>
      <span>Please take a moment to let us know why you are leaving.</span>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs="10">
            <Row>
              <Col xs="7">
                <Form.Group controlId="summary" className="mt-4 mb-5">
                  <Form.Control
                    as="textarea"
                    placeholder="Type here..."
                    rows={5}
                  />
                </Form.Group>

                <span>
                  Please enter your password to confirm that you wish to close
                  your account.
                </span>
                <Form.Group controlId="password" className="mb-3 mt-3">
                  <Form.Label className="required">Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password..."
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </Form.Group>
              </Col>
            </Row>

            <hr className="my-4" />

            <Row>
              <Col className="d-flex">
                {/* <Button variant="outlined" 
                                    className="ms-auto me-3 border border-dark">
                                        Discard
                                </Button> */}
                <Button
                  type="submit"
                  variant={password ? 'primary' : 'outlined'}
                  className={`mt-auto mb-3 border ${
                    password
                      ? 'border-primary text-white'
                      : 'border-darkGray text-dark'
                  }`}
                >
                  Close Account
                </Button>

                <Button
                  variant="outlined"
                  className={`mt-auto mb-3 ms-3 border border-dark`}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CloseAccount;
