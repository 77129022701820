import React, { Component } from 'react';
import { ACCESS_TOKEN, LOGGED_IN_USER, USER_ROLES } from '../constants';
import { Navigate } from 'react-router-dom';
// Importing the local storage utilities module.
const storageUtils = require('../utils/LocalStorageUtils');

/**
 * A React component for handling OAuth2 redirection.
 *
 * @extends Component
 */
class OAuth2RedirectHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: null,
    };
  }
  /**
   * Invoked immediately after the component is mounted.
   * This method is a lifecycle method in React.
   * It is used to fetch the data from the server.
   */
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log('code', code);
    const fetchData = async () => {
      const response = await fetch(
        'http://localhost:8787/callback?code=' + code
      );
      const fetchedData = await response.json();
      console.log('Fetched data', fetchedData);
      this.setState({ loggedInUser: fetchedData['user'] });
    };
    fetchData();
  }

  /**
   * Invoked immediately after updating occurs. This method is a lifecycle
   * method in React. It is invoked immediately after any call to
   * `setState` or `forceUpdate`.
   *
   * @return {void} This function does not return anything.
   */
  componentDidUpdate() {
    this.props.updateLoggedInUser(this.state.loggedInUser);
  }

  /**
   * Function to extract URL parameter.
   *
   * @param {string} name - The name of the parameter to extract.
   * @return {string} The value of the parameter if it exists, otherwise an empty string.
   */
  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  // Function to get logged in user from server.
  /**
   * Renders the component.
   *
   * @return {JSX.Element} The rendered component.
   */
  render() {
    if (this.state.loggedInUser) {
      console.log('loggedInUser', this.state.loggedInUser);
      const firstName = this.state.loggedInUser.firstName;
      const lastName = this.state.loggedInUser.lastName;
      const profileImageURL = this.state.loggedInUser.profileImageURL;
      const token = this.state.loggedInUser.idToken;
      const roles = this.state.loggedInUser.roles;
      const location = window.location.pathname;
      if (token) {
        const loggedInUser = {
          firstName: firstName,
          lastName: lastName,
          profileImageURL: profileImageURL,
          roles: [roles],
          idToken: token,
        };
        // Store the access token and logged in user detail in local storage
        storageUtils.setItem(ACCESS_TOKEN, token);
        storageUtils.setItem(LOGGED_IN_USER, loggedInUser);
        console.log('abcd', loggedInUser.roles.indexOf(USER_ROLES.ROLE_USER));
        // Redirect based on user roles.
        if (loggedInUser.roles.indexOf(USER_ROLES.ROLE_USER) > -1) {
          return <Navigate to="/events" />;
        } else if (loggedInUser.roles.indexOf(USER_ROLES.ROLE_PROMOTER) > -1) {
          return <Navigate to="/promoter-panel/events" />;
        } else if (
          loggedInUser.roles.indexOf(USER_ROLES.ROLE_SUPER_ADMIN) > -1
        ) {
          return <Navigate to="/admin/manage-users" />;
        } else {
          return <Navigate to="/events" />;
        }
      } else {
        // If token does not exist, redirect to login page.
        return <Navigate to="/login" />;
      }
    }
  }
}
export default OAuth2RedirectHandler;
