import { Card } from 'react-bootstrap';
import { GOOGLE_AUTH_URL } from '../constants';

export default function AuthenticationFoot({ isLogin }) {
  return (
    <>
      <Card.Subtitle className="fw-bold mt-3">
        <div className="hr-text-wrapper">
          <span className="hr-text">
            {isLogin ? 'OR LOG IN WITH' : 'OR SIGN UP WITH'}
          </span>
        </div>
      </Card.Subtitle>

      <div className="d-flex align-items-center justify-content-center mt-3">
        <a href={GOOGLE_AUTH_URL}>
          <img
            src="/google-square.png"
            width="50"
            height="30"
            alt="google logo"
          />
        </a>
      </div>
    </>
  );
}
