import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

/**
 * The ViewReceipt function represents the contact organizer component.
 * @function
 * @param {Object} props - The properties for the component.
 * @param {Object} props.orderDetails - The details of the order to view the receipt for.
 * @property {string} props.orderDetails.uniqueGeneratedOrderId - The unique generated order ID.
 * @returns {JSX.Element} - The rendered component.
 */
const ViewReceipt = ({ orderDetails }) => {
  return (
    <div>
      <div className="mb-3">
        <Form.Group className="mb-5">
          <Form.Label className="mb-5 fw-bold" as="h3" htmlFor="refundReason">
            Receipt from EventLinx:
          </Form.Label>
          <Form.Label as="h4">
            Receipt #: {orderDetails.uniqueGeneratedOrderId}
          </Form.Label>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Summary</Form.Label>
          <Row>
            <Col className={'px-0 flex-grow-1'}>
              <span>&#x2022;</span>2x Admission Ticket
            </Col>
            <Col className={'px-0 border-bottom border-bottom-dotted'}></Col>
            <Col className={'px-0'}>$30.0</Col>
          </Row>
          <Row>
            <Col className={'px-0 flex-grow-1'}>
              <span>&#x2022;</span>1 VIP Ticket
            </Col>
            <Col className={'px-0 border-bottom border-bottom-dotted'}></Col>
            <Col className={'px-0'}>$35.0</Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-5">
          <Row>
            <Col className={'px-0 flex-grow-1'}></Col>
            <Col className={'px-0'}>Tax</Col>
            <Col className={'px-0'}>$0.0</Col>
          </Row>
          <Row>
            <Col className={'px-0 flex-grow-1'}></Col>
            <Col className={'px-0'}>Total</Col>
            <Col className={'px-0'}>$65.0</Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3">
          <Row>
            <Col className={'text-center'}>
              <Button
                variant="primary"
                className="border-primary rounded-pill text-white mb-2 px-4"
              >
                View Receipt
              </Button>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-2">
          <Row>
            <Col className={'text-center'}>
              <p>
                If you have any questions, contact us at{' '}
                <a href="mailto:info@eventlinx.com">info@eventlinx.com</a>
              </p>
            </Col>
          </Row>
        </Form.Group>
      </div>
    </div>
  );
};

export default ViewReceipt;
