import React, { useEffect, useState } from 'react';
import {
  Link,
  matchPath,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Modal,
  Nav,
  Row,
  Spinner,
  Tab,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faShareAlt,
  faChevronDown,
  faMapMarkerAlt,
  faChevronUp,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { getEventDetails } from '../utils/APIUtils';
import { useAsync } from '../hooks/useAsync';
import moment from 'moment';
import ShareEvent from './ShareEvent';
import ToastMessage from '../shared/ToastMessage';
import {
  getSignedInUserEmail,
  initClient,
  publishTheCalenderEvent,
  signInToGoogle,
} from './useGoogleService';
import Counter from '../shared/Counter';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon, marker } from 'leaflet';
import 'leaflet/dist/leaflet.css';

/**
 * Represents the View Event Performance component for the preview event page.
 *
 * @return {React.FunctionComponent} The ViewEventPerformance component.
 */
const ViewEventPerformance = () => {
  /**
   * State hook to track the signed in state of the user.
   * @type {Array}
   * @property {boolean} signedin - Indicates whether the user is signed in or not.
   * @property {Function} setSignedIn - Function to update the signed in state.
   */
  const [signedin, setSignedIn] = useState(false);

  /**
   * State hook to track the google authorized email.
   * @type {Array}
   * @property {string|null} googleAuthedEmail - The authorized email of the user, or null if not authorized.
   * @property {Function} setgoogleAuthedEmail - Function to update the authorized email.
   */
  const [googleAuthedEmail, setgoogleAuthedEmail] = useState(null);

  /**
   * State hook to track the position of the marker on the map.
   * @type {Array}
   * @property {Array<number, number>} markerPosition - The latitude and longitude of the marker.
   * @property {Function} setMarkerPosition - Function to update the marker position.
   */
  const [markerPosition, setMarkerPosition] = useState([43.6529, -79.3849]);

  /**
   * State hook to toggle the display of the map.
   * @type {Array}
   * @property {boolean} showMap - Indicates whether the map is displayed or not.
   * @property {Function} setShowMap - Function to update the display of the map.
   */
  const [showMap, setShowMap] = useState(true);

  /**
   * Creates a custom icon for the map marker.
   *
   * @return {Icon} The custom icon for the map marker.
   */
  const customIcon = new Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/447/447031.png',
    iconSize: [38, 38], // size of the icon
  });

  /**
   * Component used to center the map marker on the given center coordinates.
   *
   * @param {Object} props - The properties object.
   * @param {Array<number, number>} props.center - The latitude and longitude of the marker.
   * @returns {null} Returns null as it does not render anything.
   */
  const Centerer = ({ center }) => {
    const map = useMap();

    useEffect(() => {
      map.setView(center);
    }, [center, map]);

    return null;
  };

  /**
   * Asynchronously retrieves the authorized Google email of the user when user signs with the email.
   *
   * @return {Promise<void>} A Promise that resolves when the email is retrieved or authorization is initiated.
   */
  const getGoogleAuthorizedEmail = async () => {
    let email = await getSignedInUserEmail();
    if (email) {
      setSignedIn(true);
      setgoogleAuthedEmail(email);
    } else {
      getAuthToGoogle();
    }
  };

  /**
   * Asynchronously signs in the user to Google and retrieves their authorized email.
   *
   * @return {Promise<void>} A Promise that resolves when the user is signed in and their email is retrieved.
   */
  const getAuthToGoogle = async () => {
    let successfull = await signInToGoogle();
    if (successfull) {
      getGoogleAuthorizedEmail();
    }
  };

  /**
   * A function that adds an event to a Google calendar.
   *
   * @return {void} This function does not return anything.
   */
  const addEventToGoogleCalendar = () => {
    initClient((success) => {
      if (success) {
        getGoogleAuthorizedEmail();

        // Event variable stote the information of the event like summery, location, start date time, end date time and attendees in json key value pair format
        let event = {
          summary: eventDTO.eventTitle,
          location: window.location.href,
          start: {
            dateTime: moment(
              eventDTO.eventStartDate + ' ' + eventDTO.eventStartTime
            ).toISOString(),
            timeZone: eventDTO.timezone,
          },
          end: {
            dateTime: moment(
              eventDTO.eventEndDate + ' ' + eventDTO.eventEndTime
            ).toISOString(),
            timeZone: eventDTO.timezone,
          },
          // recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
          attendees: [],
          reminders: {
            useDefault: false,
            overrides: [
              { method: 'email', minutes: 24 * 60 },
              { method: 'popup', minutes: 10 },
            ],
          },
        };

        // Function represents the publish and event on the calendar
        publishTheCalenderEvent(event).then((res) => {
          if (res.error) {
            let msg = res.error.message;
            setToast({ show: true, msg: msg, success: false });
          } else if (res.htmlLink) {
            setToast({
              show: true,
              msg: 'Event Successfully added to your calendar',
              success: true,
            });
          } else {
            setToast({
              show: true,
              msg: 'Unable to add event to your calender. Please try after some time',
              success: false,
            });
          }
        });
      }
    });
  };

  /**
   * Hook for handling asynchronous events.
   *
   * @typedef {Object} AsyncData
   * @property {any} data - The data returned by the asynchronous operation.
   * @property {boolean} loading - Indicates whether the data is being loaded.
   * @property {Error} error - The error object if there was an error while loading the data.
   * @property {Function} run - The function to run the asynchronous operation.
   */
  /**
   * Hook for handling asynchronous events.
   *
   * @return {AsyncData} The data, loading status, error, and function to run the asynchronous operation.
   */
  const { data, loading, error, run } = useAsync(null);

  /**
   * A hook that provides the state for the toast message and a function to set it.
   *
   * @return {Array} An array containing the toast message (object) and a function to set it (Function).
   * @typedef {Object} ToastState
   * @property {boolean} show - Indicates whether the toast message should be displayed or not.
   * @property {boolean} success - Indicates whether the toast message is a success message or not.
   * @property {string} msg - The message to be displayed in the toast.
   */
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  /**
   * A hook that provides the route parameters.
   *
   * @return {Object} The route parameters.
   */
  const params = useParams();

  /**
   * A hook that provides the URL for the current pathname.
   *
   * @return {Object} The URL object containing the pathname.
   * @property {string} pathname - The pathname of the URL.
   */
  const { pathname } = useLocation();

  /**
   * A hook that provides the search parameters and a function to set them.
   *
   * @return {Array} An array containing the search parameters (URLSearchParams)
   * and a function to set them (Function).
   * @typedef {Object} SearchParamsState
   * @property {URLSearchParams} searchParams - The search parameters.
   * @property {Function} setSearchParams - The function to set the search parameters.
   */
  const [searchParams, setSearchParams] = useSearchParams();

  // Dislplay the searchParams on the console
  console.log('searchParams ', searchParams);

  /**
   * Check if the current path matches with the preview event path.
   *
   * @return {Object|null} The match object if the path matches, null otherwise.
   * @property {boolean} isExact - Indicates whether the entire URL was matched (including the path, query, and fragment) or not.
   * @property {object} params - The parsed param values.
   * @property {string} path - The path pattern used to match.
   * @property {string} url - The matched portion of the URL.
   */
  const isPreview = matchPath(
    {
      path: '/preview-event/:eventId',
      exact: true,
    },
    pathname
  );

  /**
   * State hook to hold the event DTO.
   *
   * @type {Object|null}
   * @property {Object|null} eventDTO - The event DTO object or null.
   */
  const [eventDTO, setEventDTO] = useState(null);

  /**
   * State hook to hold the show share modal state.
   *
   * @type {boolean}
   * @property {boolean} showShareModal - The state of the share modal.
   */
  const [showShareModal, setShowShareModal] = useState(false);

  /**
   * useEffect() hook used to fetch the event detail and payment method.
   *
   * This hook is triggered when the component mounts. It fetches the event
   * detail and payment method using the useAsync hook. The fetched data is
   * stored in the eventDTO state variable.
   *
   * @return {void} This hook does not return anything.
   */
  useEffect(() => {
    // It fetch the event detail and update the state of the event
    run(() => getEventDetails(params.eventId))
      .then((res) => {
        setEventDTO(res.data);
        console.log('loading', loading);
      })
      .then(() => {})
      .catch((err) => {
        let msg = err.response.data.error.message;
        setToast({ show: true, msg: msg, success: false });
      });
    // .finally(handleStripeRedirect)
  }, []);

  /**
   * useEffect hook used to perform side effects when the `loading` state changes.
   *
   * This hook is triggered when the `loading` state changes. It doesn't have any
   * dependencies specified, so it executes only once when the component mounts.
   *
   * @return {void} This hook does not return anything.
   */
  useEffect(() => {}, [loading]);

  /**
   * Check if the loading state is true. And if it is, render a loading spinner
   *
   * @return {boolean} True if the loading state is true, false otherwise.
   */
  if (loading)
    /* This hook is used to manage the search parameters for the current URL.
        It returns an array with two elements: * the current search parameters
        and a function to update the search parameters. * * @returns {Array} An
        array with two elements: the current search parameters and a function to
        update the search parameters. * The current search parameters is an
        object representing the search parameters. */
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        const [searchParams, setSearchParams] = useSearchParams();
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <div className="h-100">
      <Container fluid className="bg-header">
        {isPreview && (
          <>
            <Row>
              <Col xs="12">
                <div className="p-4 text-center">
                  <h4>Preview</h4>
                </div>
              </Col>
            </Row>
            <Row className="bg-light">
              <Col xs="12">
                <div className="p-4 text-center">
                  <Button
                    to={'/promoter-panel/events'}
                    as={Link}
                    variant="link"
                    className="text-dark"
                  >
                    <h5>Close Preview</h5>
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}

        {/* banner section starts */}
        <Row>
          <Col className="px-0">
            <div
              className="homebanner position-relative"
              style={{
                backgroundImage: `url(/default_event_banner.png)`,
                height: '653px',
              }}
            >
              <div className="banner-overlay d-flex">
                <div
                  id="bannertext"
                  className="banner-text d-flex flex-grow-1 mt-auto p-5 mb-5 mx-4 text-white"
                >
                  <div>
                    <h1 className="fw-bold">{eventDTO.eventTitle}</h1>
                    <div className="d-flex">
                      <Button
                        onClick={addEventToGoogleCalendar}
                        variant="link"
                        className="text-white text-decoration-none px-0 d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          className="me-2"
                          size="lg"
                        />
                        <h5 className="mb-0">Add to calendar</h5>
                      </Button>

                      <span className="h-100 py-3 mx-5 h4 mb-0"> </span>

                      <Button
                        onClick={() => setShowShareModal(true)}
                        variant="link"
                        className="text-white text-decoration-none px-0 d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faShareAlt}
                          className="me-2"
                          size="lg"
                        />
                        <h5 className="mb-0">Share</h5>
                      </Button>
                    </div>
                    <h4 className="mt-2">
                      <span>By </span>
                      <span className="fw-bold text-decoration-underline">
                        {eventDTO.promoter}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* banner section ends */}
        <Row>
          <div className="p-3 p-lg-5">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Col>
                <Row xs={12} className="p-2 p-lg-4">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item className="custom-nav-item text-center">
                      <Nav.Link eventKey="first" className="rounded-pill">
                        Acoustic Performance
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="custom-nav-item text-center">
                      <Nav.Link eventKey="second" className="rounded-pill">
                        Hip-Hop
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Row>
                <Row xs={12}>
                  <Tab.Content>
                    <Tab.Pane
                      eventKey="first"
                      className="tab-gray performance-view"
                    >
                      <Row>
                        <Col>
                          <Row className="align-items-start p-3 p-lg-5">
                            <Col lg={8} xs={12}>
                              <div>
                                <h3 className="fw-bold mb-4">
                                  {eventDTO.eventTitle}
                                </h3>
                                <p className="mb-2">
                                  <span className="fw-bold">Artist:</span> Sarah
                                  Mclachlan
                                </p>
                                <p className="mb-2">
                                  <span className="fw-bold">Time: 1:00 PM
                                  - 3:00 PM</span>
                                </p>
                                <p className="mb-0">
                                  <span className="fw-bold">Description: </span>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                  Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia deserunt
                                  mollit anim id est laborum.
                                </p>
                              </div>
                            </Col>
                            <Col
                              lg="4"
                              className="text-center d-none d-lg-block"
                            >
                              <Button
                                size="lg"
                                variant="primary"
                                className="border-primary border-radius-10 text-white px-5"
                              >
                                <h4 className="fw-bold mb-0">View Tickets</h4>
                                <span className="fs-6">
                                  Start From $
                                  {eventDTO.eventTicketDetailsModel.price
                                    ? eventDTO.eventTicketDetailsModel.price
                                    : 0}
                                </span>
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="px-2 px-lg-4 d-flex align-items-center justify-content-center">
                            {eventDTO.eventThumbnailsList &&
                              eventDTO.eventThumbnailsList.map((dto) => {
                                return (
                                  <div className="px-2">
                                    <img
                                      src={`${dto.thumbnailUrl}`}
                                      style={{ maxHeight: '300px' }}
                                      className="img-fluid border border-darkGray"
                                      alt="artist 1"
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                      <Row className="px-3 px-lg-5  pb-5">
                        <Col xs="12" className="p-0">
                          <Row>
                            <Col className="px-3">
                              <img
                                className="w-100"
                                src="/performance_img_1.png"
                                alt="facebook logo"
                              />
                            </Col>
                            <Col className="px-3">
                              <img
                                className="w-100"
                                src="/performance_img_2.png"
                                alt="facebook logo"
                              />
                            </Col>
                            <Col className="px-3">
                              <img
                                className="w-100"
                                src="/performance_img_3.png"
                                alt="facebook logo"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col xs="12" className="d-lg-none my-4">
                          <Button
                            size="lg"
                            variant="primary"
                            className="w-100 border-primary border-radius-10 text-white px-5 rounded-pill"
                          >
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faShoppingCart} />
                              <div className="flex-grow-1">
                                <h4 className="fw-bold mb-0">View Tickets</h4>
                                <span className="fs-6">
                                  Start From $
                                  {eventDTO.eventTicketDetailsModel.price
                                    ? eventDTO.eventTicketDetailsModel.price
                                    : 0}
                                </span>
                              </div>
                            </div>
                          </Button>
                        </Col>

                        <Col xs="12">
                          <div>
                            <h4 className="my-4 fw-bold">
                              <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                                size="lg"
                                className="text-primary me-2"
                              />
                              Location
                            </h4>
                            <div className="ps-4">
                              <p className="fw-bold">
                                National Event Venue & Banquet Hall
                              </p>
                              <p>1000 Finch Avenue West Toronto, ON M3J 2V5</p>

                              <Button
                                onClick={() => setShowMap(!showMap)}
                                variant="link"
                                className="text-dark text-decoration-none"
                              >
                                <FontAwesomeIcon
                                  icon={showMap ? faChevronDown : faChevronUp}
                                  className="me-2"
                                />
                                {showMap ? 'Hide' : 'Show'} the map
                              </Button>

                              {showMap && (
                                <>
                                  <div className="d-lg-none">
                                    <div
                                      style={{ height: '300px', width: '100%' }}
                                      className="my-4"
                                    >
                                      <MapContainer
                                        center={markerPosition}
                                        zoom={markerPosition ? 14 : 2}
                                        style={{
                                          height: '300px',
                                          width: '100%',
                                        }}
                                        className="border border-dark"
                                      >
                                        <Centerer center={markerPosition} />
                                        <TileLayer
                                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        <Marker
                                          position={markerPosition}
                                          icon={customIcon}
                                          draggable={true}
                                        >
                                          <Popup>
                                            A marker indicating the location.
                                          </Popup>
                                        </Marker>
                                      </MapContainer>
                                    </div>
                                  </div>

                                  <div className="d-none d-lg-block">
                                    <div
                                      style={{
                                        height: '400px',
                                        width: '600px',
                                      }}
                                      className="my-4"
                                    >
                                      <MapContainer
                                        center={markerPosition}
                                        zoom={markerPosition ? 14 : 2}
                                        style={{
                                          height: '400px',
                                          width: '800px',
                                        }}
                                        className="border border-dark"
                                      >
                                        <Centerer center={markerPosition} />
                                        <TileLayer
                                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        <Marker
                                          position={markerPosition}
                                          icon={customIcon}
                                          draggable={true}
                                        >
                                          <Popup>
                                            A marker indicating the location.
                                          </Popup>
                                        </Marker>
                                      </MapContainer>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="second"
                      className="tab-gray performance-view"
                    >
                      <Row>
                        <Col>
                          <Row className="align-items-center p-3 p-lg-5">
                            <Col xs={8}>
                              <div>
                                <h3 className="fw-bold">
                                  {eventDTO.eventTitle}
                                </h3>
                                <p className="mb-2">
                                  <span className="fw-bold">Artist:</span> Sarah
                                  Mclachlan
                                </p>
                                <p className="mb-2">
                                  <span className="fw-bold">Time: 1:00 PM
                                  - 3:00 PM</span>
                                </p>
                                <p className="mb-0">
                                  <span className="fw-bold">Description: </span>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit, sed do eiusmod tempor
                                  incididunt ut labore et dolore magna aliqua.
                                  Ut enim ad minim veniam, quis nostrud
                                  exercitation ullamco laboris nisi ut aliquip
                                  ex ea commodo consequat. Duis aute irure dolor
                                  in reprehenderit in voluptate velit esse
                                  cillum dolore eu fugiat nulla pariatur.
                                  Excepteur sint occaecat cupidatat non
                                  proident, sunt in culpa qui officia deserunt
                                  mollit anim id est laborum.
                                </p>
                              </div>
                            </Col>
                            <Col
                              lg="4"
                              className="text-center d-none d-lg-block"
                              title={eventDTO.eventTitle}
                            >
                              <Button
                                size="lg"
                                variant="primary"
                                className="border-primary border-radius-10 text-white px-5"
                              >
                                <h4 className="fw-bold mb-0">View Tickets</h4>
                                <span className="fs-6">
                                  Start From $
                                  {eventDTO.eventTicketDetailsModel.price
                                    ? eventDTO.eventTicketDetailsModel.price
                                    : 0}
                                </span>
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="px-2 px-lg-4 d-flex align-items-center justify-content-center">
                            {eventDTO.eventThumbnailsList &&
                              eventDTO.eventThumbnailsList.map((dto) => {
                                return (
                                  <div className="px-2">
                                    <img
                                      src={`${dto.thumbnailUrl}`}
                                      style={{ maxHeight: '300px' }}
                                      className="img-fluid border border-darkGray"
                                      alt="artist 1"
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                      <Row className="px-3 px-lg-5 pb-5">
                        <Col xs="12" className="p-0">
                          <Row>
                            <Col xs="4" className="px-3">
                              <img
                                className="w-100"
                                src="/performance_img_1.png"
                                alt="facebook logo"
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col xs="12" className="d-lg-none my-4">
                          <Button
                            size="lg"
                            variant="primary"
                            className="w-100 border-primary border-radius-10 text-white px-5 rounded-pill"
                          >
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faShoppingCart} />
                              <div className="flex-grow-1">
                                <h4 className="fw-bold mb-0">View Tickets</h4>
                                <span className="fs-6">
                                  Start From $
                                  {eventDTO.eventTicketDetailsModel.price
                                    ? eventDTO.eventTicketDetailsModel.price
                                    : 0}
                                </span>
                              </div>
                            </div>
                          </Button>
                        </Col>

                        <Col xs="12">
                          <div>
                            <h4 className="my-4 fw-bold">
                              <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                                size="lg"
                                className="text-primary me-2"
                              />
                              Location
                            </h4>

                            <div className="ps-4">
                              <p className="fw-bold">
                                National Event Venue & Banquet Hall
                              </p>
                              <p>1000 Finch Avenue West Toronto, ON M3J 2V5</p>

                              <Button
                                onClick={() => setShowMap(!showMap)}
                                variant="link"
                                className="text-dark text-decoration-none"
                              >
                                <FontAwesomeIcon
                                  icon={showMap ? faChevronDown : faChevronUp}
                                  className="me-2"
                                />
                                {showMap ? 'Hide' : 'Show'} the map
                              </Button>

                              {showMap && (
                                <>
                                  <div className="d-lg-none">
                                    <div
                                      style={{ height: '300px', width: '100%' }}
                                      className="my-4"
                                    >
                                      <MapContainer
                                        center={markerPosition}
                                        zoom={markerPosition ? 14 : 2}
                                        style={{
                                          height: '300px',
                                          width: '100%',
                                        }}
                                        className="border border-dark"
                                      >
                                        <Centerer center={markerPosition} />
                                        <TileLayer
                                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        <Marker
                                          position={markerPosition}
                                          icon={customIcon}
                                          draggable={true}
                                        >
                                          <Popup>
                                            A marker indicating the location.
                                          </Popup>
                                        </Marker>
                                      </MapContainer>
                                    </div>
                                  </div>

                                  <div className="d-none d-lg-block">
                                    <div
                                      style={{
                                        height: '400px',
                                        width: '600px',
                                      }}
                                      className="my-4"
                                    >
                                      <MapContainer
                                        center={markerPosition}
                                        zoom={markerPosition ? 14 : 2}
                                        style={{
                                          height: '400px',
                                          width: '800px',
                                        }}
                                        className="border border-dark"
                                      >
                                        <Centerer center={markerPosition} />
                                        <TileLayer
                                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        />
                                        <Marker
                                          position={markerPosition}
                                          icon={customIcon}
                                          draggable={true}
                                        >
                                          <Popup>
                                            A marker indicating the location.
                                          </Popup>
                                        </Marker>
                                      </MapContainer>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Col>
            </Tab.Container>
          </div>
        </Row>
      </Container>

      <Modal
        centered
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareEvent name={eventDTO.eventTitle} url={window.location.href} />
        </Modal.Body>
      </Modal>

      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </div>
  );
};

export default ViewEventPerformance;
