import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * Function represents the order refund component.
 * @function
 * @name GetRefund
 * @returns {JSX.Element} - The JSX element representing the order refund component.
 */
const GetRefund = () => {
  return (
    <div>
      <div className="mb-3">
        <Form.Group className="mb-3">
          <Form.Label className="mb-3 fw-bold" as="h4" htmlFor="refundReason">
            What is the reason of the refund?
          </Form.Label>
          <Form.Control
            as="textarea"
            id="refundReason"
            placeholder="Type here..."
            maxLength={500}
            rows={5}
          />
          <Form.Text>Maximum 500 characters</Form.Text>
        </Form.Group>
      </div>
    </div>
  );
};

export default GetRefund;
