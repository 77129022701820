import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAsync } from '../hooks/useAsync';
import { payWithExistingMethod } from '../utils/APIUtils';
import { STRIPE_PUBLISH_KEY } from '../constants';
import PayExistingElement from './PayThroughExisitngElement';
//import "./App.css";

/**
 * This is your test publishable API key.
 * Call it outside of your React component to avoid recreating the Stripe object on every render.
 * Define stripe promise using the tsripe publishable key.
 *
 * @see {@link https://stripe.com/docs/keys}
 */

const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);

/**
 * Defines the appearance option for the Stripe Element.
 *
 * @type {Object}
 * @property {string} theme - The theme of the Stripe Element.
 */
const appearance = {
  theme: 'stripe',
};

/**
 * @function PayThroughExisting
 * @description This is a React functional component that exports a default function
 * when the user pays through the existing mode of payment.
 *
 * @param {Object} props - The props object containing the following properties:
 *    - {Object} data: The data object containing the information about the event.
 * @return {JSX.Element} The JSX element representing the PayThroughExisting component.
 */
export default function PayThroughExisting(props) {
  // Destructuring assignments to extract properties from the useAsync hook.
  const { data, loading, error, run } = useAsync(null);

  // State hook to set client secret.
  const [clientSecret, setClientSecret] = useState('');

  // State hook to set the subscription.
  const [subscription, setSubscription] = useState(false);

  //console.log("Middle ",props);

  // Extract subscription flag from props.
  const flag = props.subscription;

  // Extract the payment method ID from props.
  const paymentMethodId = props.data.paymentMethodId;

  /**
   * useEffect hook that runs when the component mounts or updates.
   *
   * @return {void} This function does not return anything.
   */
  useEffect(() => {
    //console.log("Flag : ",flag);
    console.log('Publishable Key : ' + STRIPE_PUBLISH_KEY);
    setSubscription(flag);
    // Create PaymentIntent as soon as the page loads

    // var body = JSON.stringify({
    //   "eventId":props.data.eventId,
    //   "price":"200",
    //   "receiptEmailId":"ayushsingh9200@gmail.com",
    //   "couponId":"63eabe926e3235568165b6cc",
    //   "paymentMethodId": paymentMethodId //payment method when we have already saved card details
    // //"duration":"3" //duration in months - upto what time subscription will go on
    // });

    let body = JSON.stringify(props.data);

    run(() => payWithExistingMethod(body))
      .then((res) => {
        // alert(res.data);
        setClientSecret(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  /**
   * The options for the Stripe payment element.
   *
   * @typedef {Object} PaymentElementOptions
   * @property {string} clientSecret - The client secret for the payment.
   * @property {Object} appearance - The appearance options for the payment element.
   * @property {string} appearance.theme - The theme for the payment element.
   */

  /**
   * Define the options for the Stripe payment element.
   *
   * @type {PaymentElementOptions}
   */
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <PayExistingElement
            subscription={props.subscription}
            data={{ ...props.data, clientSecret: clientSecret }}
            handlePaymentCallback={props.handlePaymentCallback}
          />
        </Elements>
      )}
    </div>
  );
}
