import { Container, Row, Col, Nav, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faLocationDot,
  faPhoneVolume,
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../Footer/Footer';
import { SOCIAL_MEDIA_URLS } from '../constants';
import { openExternalLinkInPopupWindow } from '../utils/CommonUtils';

/**
 * ContactUs component.
 *
 * @return {JSX.Element} The ContactUs component.
 */
const ContactUs = () => {
  /**
   * Opens a social media link in a popup window.
   *
   * @param {string} url - The URL of the social media link.
   * @param {string} name - The name of the social media platform.
   * @return {void} This function does not return anything.
   */
  const openSocialMediaInPopup = (url, name) => {
    openExternalLinkInPopupWindow(url, name, null);
  };

  return (
    <Container className="bg-white text-dark" fluid>
      <Row className="py-5">
        <Col xs="11" lg="8" className="mx-auto">
          <h2 className="fw-bold text-primary text-center">Contact Us</h2>
          <h5 className="text-center fw-bold">
            Any questions or remarks? Just write us a message!
          </h5>

          <Row className=" my-5 pt-4">
            <Col
              xs="12"
              lg="5"
              style={{
                backgroundImage: `url(/contact-form-background.png)`,
                backgroundSize: 'cover',
              }}
              className="text-white p-5 border-dark rounded-3"
            >
              <h3 className="fw-bold">Contact Information</h3>
              <h5 className="text-gray mt-3">
                Say something to start a live chat!
              </h5>

              <br />
              <br />
              <br />
              <br />
              <Row className="my-4">
                <Col xs="2">
                  <FontAwesomeIcon
                    size="2x"
                    icon={faPhoneVolume}
                    className="ms-2 fs-sm-1"
                  />
                </Col>
                <Col xs="10" className="h5 mb-0">
                  +1 647 123 1234
                </Col>
              </Row>

              <Row className="my-4">
                <Col xs="2">
                  <FontAwesomeIcon
                    size="2x"
                    icon={faEnvelope}
                    className="ms-2 fs-sm-1"
                  />
                </Col>
                <Col xs="10" className="h5 mb-0">
                  demo@gmail.com
                </Col>
              </Row>

              <Row className="my-4">
                <Col xs="2">
                  <FontAwesomeIcon
                    size="2x"
                    title="My Orders"
                    icon={faLocationDot}
                    className="ms-2 fs-sm-1"
                  />
                </Col>
                <Col xs="10" className="h5 mb-0">
                  132 Queen Street, Toronto 02156 Canada
                </Col>
              </Row>

              <br />
              <br />
              <br />
              <br />
              <Nav className="flex-row my-5">
                <Nav.Link
                  onClick={() =>
                    openSocialMediaInPopup(SOCIAL_MEDIA_URLS.TWITTER, 'twitter')
                  }
                  className="px-1"
                >
                  <img
                    width={40}
                    height={40}
                    src="/twitter_icon.svg"
                    alt="twitter_icon logo"
                  />
                </Nav.Link>
                <Nav.Link
                  onClick={() =>
                    openSocialMediaInPopup(
                      SOCIAL_MEDIA_URLS.FACEBOOK,
                      'facebook'
                    )
                  }
                  className="px-1"
                >
                  <img
                    width={40}
                    height={40}
                    src="/facebook.svg"
                    alt="facebook logo"
                  />
                </Nav.Link>
                <Nav.Link
                  onClick={() =>
                    openSocialMediaInPopup(SOCIAL_MEDIA_URLS.YOUTUBE, 'youtube')
                  }
                  className="px-1"
                >
                  <img
                    width={40}
                    height={40}
                    src="/youtube.svg"
                    alt="youtube logo"
                  />
                </Nav.Link>

                <Nav.Link
                  onClick={() =>
                    openSocialMediaInPopup(
                      SOCIAL_MEDIA_URLS.FACEBOOK,
                      'facebook'
                    )
                  }
                  className="px-1"
                >
                  <img
                    width={40}
                    height={40}
                    src="/instagram.svg"
                    alt="instagram logo"
                  />
                </Nav.Link>
              </Nav>
            </Col>
            <Col xs="12" lg="7" className="p-5 shadow">
              <Form>
                <Form.Group className="my-4 d-flex">
                  <div className="flex-grow-1 pe-2">
                    <Form.Label className="required">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your name..."
                      className="border-dark"
                    />
                  </div>
                  <div className="flex-grow-1 ps-2">
                    <Form.Label className="required">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your last name..."
                      className="border-dark"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="my-4">
                  <Form.Label className="required">Email Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Your email..."
                    className="border-dark"
                  />
                </Form.Group>
                <Form.Group className="my-4">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="(111)-123-1234"
                    className="border-dark"
                  />
                </Form.Group>
                <br /> <br />
                <Form.Group className="my-4">
                  <Form.Label as="h6" className="fw-bold my-3">
                    Select Subject?
                  </Form.Label>
                  <Form.Check
                    inline
                    label="General Inquiry"
                    name="group"
                    type={'radio'}
                  />

                  <Form.Check
                    inline
                    label="Your events support"
                    name="group"
                    type={'radio'}
                  />

                  <Form.Check
                    inline
                    label="Customer support"
                    name="group"
                    type={'radio'}
                  />
                </Form.Group>
                <Form.Group className="my-4">
                  <Form.Label className="small">Message</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Write your message"
                    className="border-0 border-bottom"
                  />
                </Form.Group>
                <div className="d-flex mt-5">
                  <Button
                    type="submit"
                    variant="dark"
                    className="border-dark rounded-2 py-3 px-5 ms-auto"
                  >
                    Send Message
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className="px-0">
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
