import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Nav, Button } from 'react-bootstrap'; 
import { getEventList } from '../utils/APIUtils';
import EventListCard from '../Events/EventListCard';
import Footer from '../Footer/Footer';

/**
 * Renders the promoter dashboard.
 *
 * @return {JSX.Element} The promoter dashboard component.
 */
const PromoterDashboard = () => {
  /**
   * A React Hook that returns a state variable and a function to update it.
   *
   * @return {Array} An array with two elements: the current state value and a function to update it.
   * The initial state value is `false`.
   */
  const [isexpanded, setIsexpanded] = useState(false);

  const [allEvents, setAllEvents] = useState([]);

  useEffect(() => {
    getEventList().then((res) => {
      setAllEvents(res.data);
    })
    .catch((e) => {
      console.log("error occurred while fetching event list");
      console.log(e);
    })
  })

  return (
    <div className="h-100">
      <Container fluid>
        {/* banner section starts */}
        <Row>
          <Col className="px-0">
            <div className="homebanner promoter-banner position-relative">
              <div className="profile-subanner bg-dark text-white col-6 col-lg-5 d-flex border-dark rounded-pill py-4">
                <div className="d-flex w-100">
                  <img
                    className="profile-img"
                    src="/promoter-default-img.png"
                  />
                  <div className="mx-auto">
                    <h2 className="fw-bold mt-3">Paul Rivard</h2>
                    <h5>TicketWindow Promoter</h5>
                    <p className="text-primary">3 Events</p>
                  </div>
                </div>
              </div>
              <Nav className="flex-row social-links d-none d-lg-flex">
                <Nav.Link target="_blank" className="px-1">
                  <img src="/facebook.svg" alt="facebook logo" />
                </Nav.Link>
                <Nav.Link target="_blank" className="px-1">
                  <img src="/youtube.svg" alt="youtube logo" />
                </Nav.Link>
                <Nav.Link href="#" className="px-1">
                  <img src="/instagram.svg" alt="instagram logo" />
                </Nav.Link>
              </Nav>
            </div>
          </Col>
        </Row>
        {/* banner section ends */}
        
        <Row className="mb-5">
          <Col xs="12" className="my-5 py-5"></Col>
          <Col xs="12" lg={{ span: 8, offset: 1 }}>
            <h2 className="mb-4">Events:</h2>
            {allEvents.map((event, index) => {
              return (
                <EventListCard event={event} key={index} index={index} />
              );
            })}

            

            {/* <Row className="mb-4 align-items-center py-3 bg-dark text-white">
              <Col xs="8" className="d-flex">
                <img
                  className="mx-3"
                  src="/banner.jpg"
                  width={120}
                  height={120}
                  alt="event thumbnail"
                />
                <div>
                  <h3 className="text-primary fw-bold">
                    Eminem - Live Stream Toronto
                  </h3>
                  <h5 className="fw-bold mb-2">Jan 20 / 1970</h5>
                  <h5 className="mb-0">National Event Venue & Banquet Hall</h5>
                </div>
              </Col>
              <Col xs="4" className="text-center">
                <Button
                  variant="primary"
                  className="border-primary rounded-pill text-white mb-2 px-4 py-2 d-flex align-items-center"
                >
                  <FontAwesomeIcon
                    title="No items in Cart yet"
                    icon={faCartShopping}
                    size="lg"
                    className="text-dark"
                  />
                  <div className="ms-3">
                    <h5>View Tickets</h5>
                    <span>Start from $20 CAD</span>
                  </div>
                </Button>
              </Col>
              <Col xs="12" className={isexpanded ? '' : 'd-none'}>
                <Row>
                  <Col xs="12">
                    <div className="p-5">
                      <h4>About the Artist</h4>
                      <p>
                        Dark Vince is Vincent from an alternate timeline where
                        ToyConvict won PRISON BREAK and took over the world. He
                        is thought to have grown cynical and evil in his world,
                        coming to the Light Realm for domination and acting as
                        the main antagonist of Season 3. Dark Vince is Vincent
                        from an alternate timeline where ToyConvict won PRISON
                        BREAK and took over the world. He is thought to have
                        grown cynical and evil in his world, coming to the Light
                        Realm for domination and acting as the main antagonist
                        of Season 3. Dark Vince is Vincent from an alternate
                        timeline where ToyConvict won PRISON BREAK and took over
                        the world. He is thought to have grown cynical and evil
                        in his world, coming to the Light Realm for domination
                        and acting as the main antagonist of Season 3.
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="px-4 d-flex align-items-center justify-content-center">
                      <div className="px-2">
                        <img
                          src="/banner.jpg"
                          style={{ maxHeight: '300px' }}
                          className="img-fluid border border-darkGray"
                          alt="artist 1"
                        />
                      </div>
                      <div className="px-2">
                        <img
                          src="/banner.jpg"
                          style={{ maxHeight: '300px' }}
                          className="img-fluid border border-darkGray"
                          alt="artist 1"
                        />
                      </div>
                      <div className="px-2">
                        <img
                          src="/banner.jpg"
                          style={{ maxHeight: '300px' }}
                          className="img-fluid border border-darkGray"
                          alt="artist 1"
                        />
                      </div>
                    </div>
                    <div className="justify-content-center d-flex d-md-none">
                      <Nav className="flex-row pt-4 justify-content-center">
                        <Nav.Link href="#" className="px-1">
                          <img src="/twitter_icon.svg" alt="twitter logo" />
                        </Nav.Link>
                        <Nav.Link href="#" className="px-1">
                          <img src="/facebook.svg" alt="facebook logo" />
                        </Nav.Link>
                        <Nav.Link href="#" className="px-1">
                          <img src="/youtube.svg" alt="youtube logo" />
                        </Nav.Link>
                        <Nav.Link href="#" className="px-1">
                          <img src="/instagram.svg" alt="instagram logo" />
                        </Nav.Link>
                      </Nav>
                    </div>
                  </Col>
                </Row>
                <Row className="py-5">
                  <Col lg={6} xs={12}>
                    <div className="px-5">
                      <h4>FOLLOW THE ARTIST. BE PART OF SOMETHING MORE</h4>
                      <p>
                        See what they’re up to off stage and on spare time. It’s
                        like the ultimate free back stage pass to your favourite
                        bands!{' '}
                      </p>
                      <p className="mt-4">
                        Support your artists and follow them on social media.
                      </p>

                      <Nav className="flex-row pt-4">
                        <Nav.Link
                          href={`https://www.facebook.com`}
                          className="px-3"
                        >
                          <img
                            width="70"
                            height="70"
                            src="/facebook.svg"
                            alt="facebook logo"
                          />
                        </Nav.Link>

                        <Nav.Link
                          href={`https://www.instagram.com/`}
                          className="px-3"
                        >
                          <img
                            width="70"
                            height="70"
                            src="/instagram.svg"
                            alt="instagram logo"
                          />
                        </Nav.Link>
                      </Nav>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="pe-5">
                      <iframe width="100%" height="350"></iframe>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" className="text-center">
                <FontAwesomeIcon
                  onClick={() => setIsexpanded(!isexpanded)}
                  title="expand event"
                  icon={!isexpanded ? faChevronDown : faChevronUp}
                  size="xl"
                  className="text-white cursor-pointer"
                />
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default PromoterDashboard;
