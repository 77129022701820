import React, { useState, useEffect, useRef } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
// import Error from '../shared/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useSearchParams } from 'react-router-dom';
import { validatePassword } from '../Login/login.validation';
import { verifyToken } from '../utils/APIUtils';
// import ReCAPTCHA from 'react-google-recaptcha';
// import { SITE_KEY } from "../constants";
import { resetNewPassword } from '../utils/APIUtils';
// import useRecaptcha from '../hooks/useRecaptcha';

/**
 * React functional component that exports and renders HTML elements for the reset password module.
 *
 * @return {JSX.Element} The JSX element that renders the reset password module.
 */
const ResetPassword = () => {
  /**
   * State hook to manage the search parameters.
   *
   * @type {[URLSearchParams, function]}
   * @memberof ResetPassword
   * @return {[URLSearchParams, function]} The current value and a function to update it.
   */
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * A variable to store the search parameter "token".
   *
   * @type {string | null}
   */
  let token = searchParams.get('token');

  // State hook to manage the captcha response.
  // const [captchaResponse, setCaptchaResponsed] = useRecaptcha();

  /**
   * State hook to set the password.
   *
   * @type {Array}
   * @memberof ResetPassword
   * @return {Array} An array with the password and a function to update it.
   */
  const [password, setPassword] = useState('');

  /**
   * State hook to set the confirm password.
   *
   * @return {Array} An array with the confirm password and a function to update it.
   */
  const [confirmPassword, setConfirmPassword] = useState('');

  /**
   * State hook to set the errors.
   *
   * @return {Array} An array with the errors and a function to update it.
   */
  const [errors, setErrors] = useState('');

  /**
   * State hook to manage the "reset success" status.
   *
   * @type {Array}
   * @return {Array} An array with the "reset success" status and a function to update it.
   */
  const [resetSuccess, setResetSuccess] = useState(false);

  /**
   * State hook to manage the validity status.
   *
   * @return {Array} An array with the validity status and a function to update it.
   */
  const [isValid, setIsValid] = useState(true);

  /**
   * Effect hook to validate token when the component mounts.
   *
   * @return {void}
   */
  useEffect(() => {
    console.log('reset-password token', token);
    // const validateToken = () => {
    //     const payload = {
    //         token: token
    //     }

    //     verifyToken(payload).then((res) => {
    //         console.log(res);
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //         setIsValid(false);
    //     })
    // }

    // validateToken();
  }, []);

  /**
   * Handles the submit event.
   *
   * @param {Event} e - The submit event.
   * @return {void} This function does not return anything.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validatePassword(password, confirmPassword);

    // if(!captchaResponse.trim()) {
    //     errors.captcha = "Captcha is required";
    // }

    if (Object.keys(errors).length === 0) {
      submitForm();
    }

    setErrors(errors);
  };

  // Function to submit the form data.
  /**
   * Submits the form data.
   *
   * @return {void} This function does not return anything.
   */
  const submitForm = () => {
    const data = {
      token: token,
      password: password,
      // confirmPassword: confirmPassword
    };

    resetNewPassword(data)
      .then((res) => {
        // window.grecaptcha.reset();
        // setCaptchaResponsed("");
        setResetSuccess(true);
      })
      .catch((err) => {
        console.log(err.response);
        // window.grecaptcha.reset();
        // setCaptchaResponsed("");
        setResetSuccess(false);
        setErrors({ api: err.response.data.error.message });
        setIsValid(false);
      });
  };

  // const onCaptchaChange = (value) => {
  //     const token = captchaRef.current.getValue();
  //     setCaptchaResponsed(token);
  //     console.log(token)
  // }

  return (
    <div className="banner h-100 d-flex align-items-center justify-content-center bg-header">
      <Card style={{ width: '360px' }} className="login-box">
        {isValid && (
          <Card.Body className="p-4">
            <Card.Title className="text-primary text-uppercase mb-0">
              {resetSuccess ? 'Password Reset' : 'Create new password'}
            </Card.Title>
            <Card.Text className="fs-12 mb-2">
              {resetSuccess
                ? 'Your password has been successfully reset!'
                : 'Please create a new password for your account'}
            </Card.Text>

            <Form onSubmit={handleSubmit}>
              {!resetSuccess && (
                <>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="fw-bold mb-1" aria-label="password">
                      Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      minLength="8"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && (
                      <Form.Text className="text-danger">
                        {errors.password}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formConfirmPassword">
                    <Form.Label
                      className="fw-bold mb-1"
                      aria-label="password confirmation"
                    >
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      minLength="8"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {errors.confirmPassword && (
                      <Form.Text className="text-danger">
                        {errors.confirmPassword}
                      </Form.Text>
                    )}
                  </Form.Group>
                  {/* use css to show captcha conditionally */}
                  <div className={`mb-3`}>
                    {/* <div id="captchaDiv"></div> */}
                    {/* <ReCAPTCHA 
                                        sitekey={SITE_KEY} // demo site key added for testing => 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
                                        onChange={onCaptchaChange}
                                        ref={captchaRef}   
                                    />  */}
                    {/* {errors.captcha && <Form.Text className="text-danger">{errors.captcha}</Form.Text>}
                                    {errors.api && <Form.Text className="text-danger">{errors.api}</Form.Text> } */}
                  </div>
                </>
              )}

              {!resetSuccess && (
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 text-white fw-bold"
                >
                  Create new password
                </Button>
              )}

              {resetSuccess && (
                <Button
                  as={Link}
                  to="/login"
                  variant="primary"
                  type="button"
                  className="w-100 text-white fw-bold"
                >
                  Log In
                </Button>
              )}
            </Form>

            {!resetSuccess && (
              <div className="mt-2">
                <Link
                  to="/login"
                  className="p-0 text-decoration-none mt-2 fs-12 fw-bold"
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                  Back to Login
                </Link>
              </div>
            )}
          </Card.Body>
        )}
        {!isValid && (
          <Card.Body className="p-4">
            <Card.Title className="text-primary text-uppercase mb-0">
              {' '}
              Link Expired
            </Card.Title>
            <Card.Text className="fs-12">
              The link to reset password is not valid or expired. Please go to
              Login and generate a new link.
            </Card.Text>

            <div className="mt-2">
              <Link
                to="/login"
                className="p-0 text-decoration-none mt-2 fs-12 fw-bold"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                Back to Login
              </Link>
            </div>
          </Card.Body>
        )}
      </Card>
      {/* <Error /> */}
    </div>
  );
};

export default ResetPassword;
