import { Card, Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register, verifyemail } from '../utils/APIUtils';
import useRecaptcha from '../hooks/useRecaptcha';
// import {
//   ACCESS_TOKEN,
//   GOOGLE_AUTH_URL,
//   USER_TYPE,
//   USER_ROLES,
// } from '../constants';
import ToastMessage from '../shared/ToastMessage';
import FormInput from '../Components/FormInput';
import KeepMeSignAndForgot from '../Components/KeepMeSignAndForgot';
import ConfirmVerificationCode from '../Components/ConfirmVerificationCode';
import AuthenticationHead from '../Components/AuthenticationHead';
import AuthenticationFoot from '../Components/AuthenticationFoot';
import { useAuth } from '../hooks/useAuth';

export default function SignUp() {
  const [iscodeRequested, setIsCodeRequested] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [rememberUser, setRememberUser] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [pendingAuthToken, setPendingAuthToken] = useState('');
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });
  const [captchaResponse, setCaptchaResponsed] = useRecaptcha();
  const storageUtils = require('../utils/LocalStorageUtils');

  const navigate = useNavigate();
  const auth = useAuth();

  /**
   * Resets all the fields in the form.
   *
   * @return {void} This function does not return anything.
   */
  const resetAllFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  };

  /**
   * Handles the success of user registration.
   *
   * @param {Object} res - The response object containing user data.
   * @return {void}
   */
  const handleRegisterSuccess = (res) => {
    setToast({
      show: true,
      success: true,
      msg: 'User Registered Successfully, Please check your email for verification code',
    });
    setIsCodeRequested(true);
    setEmailVerificationCode('');
    setPendingAuthToken(res.data.pending_authentication_token);
  };

  const handleAuthenticationSuccess = (registrationData, response) => {
    console.log('response in handleAuthenticationSuccess', response);

    let loggedInUser = response.data.user;
    console.log('loggedInUser', loggedInUser);

    // store the user's email in localStorage if remember me is checked
    if (rememberUser) {
      storageUtils.setItem('email', registrationData.email);
    }

    // Set user on the auth context
    auth.setUser(loggedInUser);

    // Navigate to events
    setTimeout(() => {
      navigate('/events');
    }, 100);
  };

  const handleRegisterError = (err) => {
    if (!err.error) {
      err.error = 'Something went wrong please try again later';
    }
    setErrors({ auth: err.response.data.message });
    setToast({
      show: true,
      success: false,
      msg: err.response.data.message,
    });

    if (process.env.NODE_ENV !== 'development') {
      window.grecaptcha.reset();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const registerData = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      userType: 'member', // TODO:On the USER_TYPE constant is defined in Uppercase. Verify if this is correct.
    };
    register(registerData, captchaResponse)
      .then((res) => {
        console.log('REGISTER SUCCESS', res);

        handleRegisterSuccess(res);
      })
      .catch((err) => {
        handleRegisterError(err);
      });
  };

  // TODO:Implement captcha when ready to use
  const handleSubmitVerificationCode = (e) => {
    e.preventDefault();
    verifyemail(emailVerificationCode, pendingAuthToken)
      .then((res) => {
        const userData = {
          email: email,
          password: password,
          // captchaResponse: captchaResponse
        };
        resetAllFields();
        handleAuthenticationSuccess(userData, res);
      })
      .catch((e) => {
        setEmailVerificationCode('');
      });
  };

  return (
    <div
      id="banner"
      className="h-100 d-flex align-items-center justify-content-center"
    >
      <Card className="register-box">
        <Card.Body className="p-4">
          <AuthenticationHead isLogin={false} />
          {!iscodeRequested && (
            <Form onSubmit={handleSubmit}>
              <FormInput
                controlId="formFirstName"
                ariaLabel="first name"
                label="First name"
                type="text"
                placeholder="Enter your first name"
                value={firstName}
                onInputChange={setFirstName}
              >
                {errors.firstName && (
                  <Form.Text className="text-danger">
                    {errors.firstName}
                  </Form.Text>
                )}
              </FormInput>
              <FormInput
                controlId="formLastName"
                ariaLabel="last name"
                label="Last name"
                type="text"
                placeholder="Enter your last name"
                value={lastName}
                onInputChange={setLastName}
                errors={errors}
              >
                {errors.lastName && (
                  <Form.Text className="text-danger">
                    {errors.lastName}
                  </Form.Text>
                )}
              </FormInput>
              <FormInput
                controlId={'formBasicEmail'}
                ariaLabel="username"
                label="Email"
                type="email"
                placeholder="Enter your email address"
                value={email}
                onInputChange={setEmail}
                errors={errors}
              >
                {errors.email && (
                  <Form.Text className="text-danger">{errors.email}</Form.Text>
                )}
              </FormInput>
              <FormInput
                controlId="formBasicPassword"
                ariaLabel="password"
                label="Password"
                type="password"
                placeholder="Password"
                value={password}
                onInputChange={setPassword}
                minLength={8}
                errors={errors}
              >
                {errors.password && (
                  <Form.Text className="text-danger">
                    {errors.password}
                  </Form.Text>
                )}
                {errors.auth && (
                  <Form.Text className="text-danger">{errors.auth}</Form.Text>
                )}
              </FormInput>
              <FormInput
                controlId="formConfirmPassword"
                ariaLabel="password confirmation"
                label="Confirm Password"
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onInputChange={setConfirmPassword}
                minLength={8}
              >
                {errors.confirmPassword && (
                  <Form.Text className="text-danger">
                    {errors.confirmPassword}
                  </Form.Text>
                )}
              </FormInput>

              <KeepMeSignAndForgot
                rememberUser={rememberUser}
                onChangeChecked={setRememberUser}
              />

              {process.env.NODE_ENV !== 'development' && (
                <div id="captchaDiv"></div>
              )}
              {/* <ReCAPTCHA
                            sitekey="6Le7W5EgAAAAAFbXUO0Ur9oFlf_ic7YeQV-O9xyx" // demo site key added for testing => 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI
                            onChange={onChange}
                        /> */}
              {process.env.NODE_ENV !== 'development' && errors.captcha && (
                <Form.Text className="text-danger">{errors.captcha}</Form.Text>
              )}

              <Card.Text className="fs-10 mt-3">
                By continuing past this page, you agree to the Terms of Use and
                understand that information will be used as described in our
                Privacy Policy.
              </Card.Text>

              <Button
                variant="primary"
                type="submit"
                className="w-100 fw-bold text-uppercase text-white"
              >
                Create your account
              </Button>
            </Form>
          )}
          {/* 
            Renders the form for email verification if 
            the email verification code is requested
          */}
          {iscodeRequested && (
            <ConfirmVerificationCode
              handleSubmitVerificationCode={handleSubmitVerificationCode}
              emailVerificationCode={emailVerificationCode}
              setEmailVerificationCode={setEmailVerificationCode}
              errors={errors}
            />
          )}
          <AuthenticationFoot isLogin={false} />
        </Card.Body>
      </Card>
      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />

      {/* <Error /> */}
    </div>
  );
}
