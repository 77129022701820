import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Row,
  Col,
  Spinner,
  Form,
  Dropdown,
  InputGroup,
} from 'react-bootstrap';
import { useAsync } from '../hooks/useAsync';
import Footer from '../Footer/Footer';
import {
  getEventList,
  getCategoryDetails,
  getLocationTypes,
  getEventListCustomized,
  eventListFilter,
} from '../utils/APIUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import DatePicker from 'react-datepicker';

import {
  faChevronLeft,
  faChevronRight,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../hooks/useAuth';
import EventListCard from '../Events/EventListCard';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

/**
 * Object containing the CSS styles for the button.
 * @type {Object}
 * @property {string} width - The width of the button.
 * @property {string} background - The background color of the button.
 * @property {string} border - The border style of the button.
 */
const buttonStyle = {
  width: '30px',
  background: 'none',
  border: '0px',
};

/**
 * @constant {Object} SORT_OPTIONS - An object containing constants for sort options.
 * @property {string} SORT_OPTIONS.SORT_BY_DATE - Constant for sorting by date.
 * @property {string} SORT_OPTIONS.SORT_ALPHABETICALLY - Constant for sorting alphabetically.
 */
const SORT_OPTIONS = Object.freeze({
  SORT_BY_DATE: 'Sort By Date',
  SORT_ALPHABETICALLY: 'Sort Alphabetically',
});

/**
 * @constant {Object} properties - An object containing properties used in the EventDashboard component.
 * @property {Object} properties.buttonStyle - An object containing CSS styles for the button.
 * @property {string} properties.buttonStyle.width - The width of the button.
 * @property {string} properties.buttonStyle.background - The background color of the button.
 * @property {string} properties.buttonStyle.border - The border style of the button.
 * @property {Object} properties.SORT_OPTIONS - An object containing constants for sort options.
 * @property {string} properties.SORT_OPTIONS.SORT_BY_DATE - Constant for sorting by date.
 * @property {string} properties.SORT_OPTIONS.SORT_ALPHABETICALLY - Constant for sorting alphabetically.
 */
const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }} className="ms-5">
      <FontAwesomeIcon
        icon={faChevronLeft}
        size="4x"
        className="text-darkGray"
      />
    </button>
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }} className="me-5">
      <FontAwesomeIcon
        icon={faChevronRight}
        size="4x"
        className="text-darkGray"
      />
    </button>
  ),
  indicators: true,
};

/**
 * The EventDashboard function component is the main component for the event dashboard page.
 * It renders the event dashboard page, which includes a list of upcoming events and a list of past events.
 * It also provides functionality to filter events by date, location, category, and format.
 * It uses the useAsync hook to fetch event data from the server.
 * It uses the useAuth hook to get the user's authentication status.
 * It uses the useState hook to manage the state of various variables such as loading, allEvents, upcomingEvents, locationTypes, categoryDetails, and more.
 * It uses the useEffect hook to fetch data from the server when the component is mounted.
 * It uses the React-Bootstrap components for styling and layout.
 * It uses the FontAwesomeIcon component to display icons.
 *
 * @return {JSX.Element} The rendered event dashboard page.
 */
const EventDashboard = () => {
  /**
   * The useAsync hook is used to fetch data from the server asynchronously.
   * It takes a function as an argument and returns an object with three properties: data, error, and run.
   * The data property contains the data returned by the function.
   * The error property contains any error that occurred during the fetch.
   * The run property is a function that can be used to run the function passed to useAsync.
   *
   * @typedef {Object} AsyncData
   * @property {any} data - The data returned by the function passed to useAsync.
   * @property {Error} error - The error that occurred during the fetch.
   * @property {Function} run - The function used to run the function passed to useAsync.
   * @return {AsyncData} An object with data, error, and run properties.
   */
  const { data, error, run } = useAsync(null);

  /**
   * The useAuth hook is used to get the user's authentication status.
   * It returns an object with properties user, setUser, and logout.
   * The user property contains the user object.
   * The setUser property is a function used to set the user object.
   * The logout property is a function used to log the user out.
   *
   * @typedef {Object} AuthData
   * @property {Object} user - The user object.
   * @property {Function} setUser - A function used to set the user object.
   * @property {Function} logout - A function used to log the user out.
   * @return {AuthData} An object with user, setUser, and logout properties.
   */
  const auth = useAuth();

  /**
   * The useState hook is used to manage the state of the loading variable.
   * The loading variable is a boolean that indicates whether the data is being fetched from the server.
   * The useState hook returns an array with two elements: the current value of the state variable and a function to update the state variable.
   *
   * @typedef {Object} LoadingData
   * @property {boolean} loading - A boolean that indicates whether the data is being fetched from the server.
   * @property {Function} setLoading - A function used to update the loading state variable.
   * @return {LoadingData} An object with loading and setLoading properties.
   */
  const [loading, setLoading] = useState(false);

  /**
   * A state variable to hold all the events.
   *
   * @typedef {Array} AllEvents
   * @property {Array} allEvents - An array of events.
   * @property {function} setAllEvents - A function to update the allEvents state.
   * @return {AllEvents} An object with allEvents and setAllEvents properties.
   */
  const [allEvents, setAllEvents] = useState([]);

  /**
   * A state variable to hold the upcoming events.
   *
   * @typedef {Array} UpcomingEvents
   * @property {Array} upcomingEvents - An array of upcoming events.
   * @property {Function} setUpcomingEvents - A function to update the upcomingEvents state.
   * @return {UpcomingEvents} An object with upcomingEvents and setUpcomingEvents properties.
   */
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  /**
   * A state variable to hold the default thumbnail URL.
   *
   * @typedef {string} DefaultThumbnail
   * @property {string} defaultThumbnail - The default thumbnail URL.
   */
  const defaultThumbnail =
    'https://via.placeholder.com/150/FF5733/FFFFFF/?text=Thumbnail';

  /**
   * A state variable to hold the location types.
   *
   * @typedef {Array} LocationTypes
   * @property {Array} locationTypes - An array of location types.
   * @property {Function} setLocationTypes - A function to update the locationTypes state.
   * @return {LocationTypes} An object with locationTypes and setLocationTypes properties.
   */
  const [locationTypes, setLocationTypes] = useState();

  /**
   * A state variable to hold the category details.
   *
   * @typedef {Object} CategoryDetails
   * @property {Object} categoryDetails - An object representing the category details.
   * @property {Function} setCategoryDetails - A function to update the categoryDetails state.
   * @return {CategoryDetails} An object with categoryDetails and setCategoryDetails properties.
   */
  const [categoryDetails, setCategoryDetails] = useState();

  /**
   * A state variable to hold the category name.
   *
   * @typedef {string} CategoryName
   * @property {string} categoryName - The category name.
   * @property {Function} setCategoryName - A function to update the categoryName state.
   * @return {CategoryName} An object with categoryName and setCategoryName properties.
   */
  const [categoryName, setCategoryName] = useState('');

  /**
   * A state variable to hold the format.
   *
   * @typedef {string} Format
   * @property {string} format - The format.
   * @property {Function} setFormat - A function to update the format state.
   * @return {Format} An object with format and setFormat properties.
   */
  const [format, setFormat] = useState(null);

  /**
   * A state variable to hold the types selected.
   *
   * @typedef {Array} TypesSelected
   * @property {Array} typesSelected - An array of types selected.
   * @property {Function} setTypesSelected - A function to update the typesSelected state.
   * @return {TypesSelected} An object with typesSelected and setTypesSelected properties.
   */
  const [typesSelected, setTypesSelected] = useState([]);

  // const [showModal, setShowModal] = useState()

  /**
   * A state variable to hold the visibility of date range fields.
   *
   * @typedef {boolean} ShowDateRangeFields
   * @property {boolean} showDateRangeFields - The visibility of date range fields.
   * @property {Function} setShowDateRangeFields - A function to update the showDateRangeFields state.
   * @return {ShowDateRangeFields} An object with showDateRangeFields and setShowDateRangeFields properties.
   */
  const [showDateRangeFields, setShowDateRangeFields] = useState(false);

  /**
   * A state variable to hold the type of date.
   *
   * @typedef {string|null} DateType
   * @property {string|null} dateType - The type of date.
   * @property {Function} setDateType - A function to update the dateType state.
   * @return {DateType} An object with dateType and setDateType properties.
   */
  const [dateType, setDateType] = useState(null);

  /**
   * A state variable to hold the start date.
   *
   * @typedef {Date|null} StartDate
   * @property {Date|null} startDate - The start date.
   * @property {Function} setStartDate - A function to update the startDate state.
   * @return {StartDate} An object with startDate and setStartDate properties.
   */
  const [startDate, setStartDate] = useState(null);

  /**
   * A state variable to hold the end date.
   *
   * @typedef {Date|null} EndDate
   * @property {Date|null} endDate - The end date.
   * @property {Function} setEndDate - A function to update the endDate state.
   * @return {EndDate} An object with endDate and setEndDate properties.
   */
  const [endDate, setEndDate] = useState(null);

  /**
   * A state variable to hold the total number of pages.
   *
   * @typedef {number|null} TotalPages
   * @property {number|null} totalPages - The total number of pages.
   * @property {Function} setTotalPages - A function to update the totalPages state.
   * @return {TotalPages} An object with totalPages and setTotalPages properties.
   */
  const [totalPages, setTotalPages] = useState(null);

  /**
   * A state variable to hold the current page number.
   *
   * @typedef {number|null} CurrentPage
   * @property {number|null} currentPage - The current page number.
   * @property {Function} setCurrentPage - A function to update the currentPage state.
   * @return {CurrentPage} An object with currentPage and setCurrentPage properties.
   */
  const [currentPage, setCurrentPage] = useState(null);

  /**
   * A state variable to hold the results.
   *
   * @typedef {Object|null} Results
   * @property {Object|null} result - The results.
   * @property {Function} setResults - A function to update the results state.
   * @return {Results} An object with result and setResults properties.
   */
  const [result, setResults] = useState(null);

  /**
   * A state variable to hold the sort option.
   *
   * @typedef {string} SortOption
   * @property {string} sortOption - The sort option.
   * @property {Function} setSortOption - A function to update the sortOption state.
   * @return {SortOption} An object with sortOption and setSortOption properties.
   */
  const [sortOption, setSortOption] = useState(SORT_OPTIONS.SORT_BY_DATE);

  /**
   * A state variable to hold the visible index.
   *
   * @typedef {number} VisibleIndex
   * @property {number} visibleIndex - The visible index.
   * @property {Function} setVisibleIndex - A function to update the visibleIndex state.
   * @return {VisibleIndex} An object with visibleIndex and setVisibleIndex properties.
   */
  const [visibleIndex, setVisibleIndex] = useState(-1);

  /**
   * Toggles the visibility of an item.
   *
   * @param {Event} e - The event object.
   * @param {number} index - The index of the item to toggle.
   */
  const toggleVisibility = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    // If the clicked item is already visible, hide it; otherwise, show it
    setVisibleIndex(visibleIndex === index ? -1 : index);
  };

  const [showFilterPanel, setShowFilterPannel] = useState(false);

  /**
   * Represents the FilterEventListRequestDto object.
   * @typedef {Object} FilterEventListRequestDto
   * @property {string} [searchText] - The search text.
   * @property {number} [categoryId] - The category ID.
   * @property {number} [locationTypeId] - The location type ID.
   * @property {number} [page] - The page number.
   * @property {number} [size] - The number of items per page.
   * @property {string} [sort] - The sort option.
   */
  let FilterEventListRequestDto = {};

  /**
   * A useEffect hook that runs when the component mounts.
   *
   * @return {void} This function does not return anything.
   */
  useEffect(() => {
    setLoading(true);
    let isloggedin = auth && auth.user;
    Promise.all([
      getEventList(0, isloggedin),
      getCategoryDetails(isloggedin),
      getLocationTypes(),
    ])
      .then((values) => {
        const eventListRes = values[0];
        const categoryDetailsRes = values[1];
        const locationTypeRes = values[2];
        console.log('test', categoryDetailsRes.data);
        const publishedEvents = eventListRes.data;
        setAllEvents(publishedEvents);
        setLocationTypes(locationTypeRes.data);
        setCategoryDetails(categoryDetailsRes.data);
        setResults(publishedEvents.length);
        setLoading(false);
        // For pagination
        // const tp = Math.ceil(publishedEvents.length / 10) // 10 is total number of items
        // setTotalPages(tp)

        //For Customized List

        getEventListCustomized(auth.user).then((res) => {
          console.log('Customized List', res.data);
          setUpcomingEvents(eventListRes.data.upcomingEvents);
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  /**
   * Renders a loading spinner if the component is still loading.
   *
   * @return {JSX.Element} The loading spinner component.
   */
  if (loading)
    return (
      <div className="d-flex align-items-center justify-content-center p-5">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  /**
   * Renders a message if there are no events available.
   *
   * @return {JSX.Element} The message component indicating that there are no events.
   */
  if (allEvents.length == 0)
    return (
      <div className="d-flex align-items-center justify-content-center p-5">
        <h5 className="me-2">
          Sorry, We don't have any events going on at this time.
        </h5>
      </div>
    );

  /**
   * Event handler for category change.
   *
   * @param {Object} event - The event object.
   * @return {void} This function does not return anything.
   */
  const handleCategoryChange = (event) => {
    console.log('Category clicked', event.target.value);
    if (typesSelected.length > 0) {
      setTypesSelected([]);
    } // handling if User changes Category after selected any types from previous category
    setCategoryName(event.target.value);
  };

  /**
   * Event handler for type change.
   *
   * @param {Object} event - The event object.
   * @return {void} This function does not return anything.
   */
  const handleTypeChange = (event) => {
    const { value, checked } = event.target;

    if (value === 'all' && checked) {
      setTypesSelected(['all']);
    } else if (value !== 'all' && checked) {
      setTypesSelected((prevArray) => [...prevArray, value]);
    } else {
      setTypesSelected((prevArray) =>
        prevArray.filter((item) => item !== value)
      );
    }

    console.log('Type clicked', value);
    console.log('Types selected', typesSelected);
  };

  /**
   * Serializes an object into a query string.
   *
   * @param {Object} obj - The object to serialize.
   * @param {string} [prefix] - The prefix to prepend to the keys in the serialized string.
   * @return {string} The serialized string.
   */
  const serialize = function (obj, prefix) {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        );
      }
    }
    return str.join('&');
  };

  /**
   * Applies the filters and performs the necessary actions.
   *
   * @return {void} This function does not return anything.
   */
  const applyFilters = () => {
    console.log('Apply filters clicked');
    const allLocationTypes = format === 'all' ? true : false;
    const allCategories = categoryName === 'all' ? true : false;
    const allSubCategories =
      typesSelected[0] === 'all' || typesSelected.length === 0 ? true : false;
    const allDatesCovered =
      startDate === null && endDate === null && dateType === 'All'
        ? true
        : false;
    console.log(dateType === 'All');
    const eventsOnWeekends = dateType === 'weekends' ? true : false;
    const eventsOnWeekdays = dateType === 'weekdays' ? true : false;
    const eventsWithinDateRange = dateType === 'dateRange' ? true : false;
    const eventsOnThisWeekend = dateType === 'thisWeekend' ? true : false;
    const locationTypeFilterList =
      format === 'all'
        ? []
        : [locationTypes.find((type) => type.type === format).id];
    const categoriesFilterList =
      categoryName === 'all' || categoryName === null
        ? []
        : [
            categoryDetails.find((category) => category.name === categoryName)
              .id,
          ];

    const mc = categoryDetails.findIndex(
      (category) => category.name === categoryName
    );
    // console.log("abcd", categoryDetails[mc].eventSubCategoriesModelList)
    let subCategoriesFilterList;
    if (mc) {
      subCategoriesFilterList = typesSelected.map((type) => {
        return categoryDetails[mc].eventSubCategoriesModelList.find(
          (subCategory) => subCategory.name === type
        ).id;
      });
    } else {
      console.log('No category');
    }

    let formattedStartDate;

    if (startDate) {
      const year = startDate.getFullYear();
      let month = startDate.getMonth() + 1; // Adding 1 because January is 0
      month = month < 10 ? '0' + month : month; // Add leading zero if month is less than 10
      const day = String(startDate.getDate()).padStart(2, '0');

      formattedStartDate = `${year}-${month}-${day}`;
    }

    let formattedEndDate;

    if (endDate) {
      const year = endDate.getFullYear();
      let month = endDate.getMonth() + 1; // Adding 1 because January is 0
      month = month < 10 ? '0' + month : month; // Add leading zero if month is less than 10
      const day = String(endDate.getDate()).padStart(2, '0');
      formattedEndDate = `${year}-${month}-${day}`;
    }

    FilterEventListRequestDto = {
      allLocationTypes: allLocationTypes,
      allCategories: allCategories,
      allSubCategories: allSubCategories,
      allDatesCovered: allDatesCovered,
      eventsOnWeekends: eventsOnWeekends,
      eventsOnWeekdays: eventsOnWeekdays,
      eventsWithinDateRange: eventsWithinDateRange,
      eventsOnThisWeekend: eventsOnThisWeekend,
      locationTypeFilterList: locationTypeFilterList,
      categoriesFilterList: categoriesFilterList,
      subCategoriesFilterList: subCategoriesFilterList,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };

    console.log(FilterEventListRequestDto);

    console.log('Filtered events: ');
    eventListFilter(FilterEventListRequestDto)
      .then((res) => {
        console.log(res.data);
        setAllEvents(res.data);
        // Total pages logic
        console.log(res.data.length);
        const tp = Math.ceil(res.data.length / 10); // 10 is items per page
        setTotalPages(tp);
        setResults(res.data.length);
        console.log('Total pages', tp);
      })
      .catch((error) => {
        console.error('Error applying filters:', error);
      });
  };

  /**
   * Resets all filters to their default values and fetches a new list of events
   * based on the reset filters.
   *
   * @return {void} This function does not return anything.
   */
  const resetFilters = () => {
    console.log('Reset filters clicked');
    FilterEventListRequestDto = {};
    eventListFilter(FilterEventListRequestDto).then((res) => {
      setAllEvents(res.data);
      // Total pages logic
      console.log(res.data.length);
      const tp = Math.ceil(res.data.length / 10);
      setTotalPages(tp);
      setResults(res.data.length);
    });
  };

  // const dateRangeModal = () => {
  //   setShowModal(true)
  // }

  /**
   * Handles the change event of the format select input.
   *
   * @param {Object} event - The event object representing the change event.
   * @return {void} This function does not return anything.
   */
  const handleFormatChange = (event) => {
    console.log('Format clicked', event.target.value);
    setFormat(event.target.value);
  };

  // const handleModalApply = () => {
  //   // API Call thing here
  //   console.log("Start Date", startDate)
  //   console.log("End Date", endDate)
  //   setShowModal(false)
  //   console.log("Modal Apply clicked");
  // }

  // const handleModalClose = () => {
  //   setShowModal(false)
  //   console.log("Modal Close clicked");
  // }

  /**
   * Handles the event when the date range select button is clicked.
   *
   * @return {void} This function does not return anything.
   */
  const handleDateRangeSelect = () => {
    console.log('Date Range Selected');
    setDateType('dateRange');
    setShowDateRangeFields(true);
    // setShowModal(true)
  };

  /**
   * Handles the change event of the date select input.
   *
   * @param {Object} event - The event object representing the change event.
   * @return {void} This function does not return anything.
   */
  const handleDateChange = (event) => {
    console.log('Date Change', event.target.value);
    setDateType(event.target.value);
    setShowDateRangeFields(false);
  };

  /**
   * Handles the event when the page is changed.
   *
   * @param {number} page - The new page number.
   * @return {void} This function does not return anything.
   */
  const handlePageChange = (page) => {
    console.log('Page Change', page);
    // setPage(event.target.value);
    setCurrentPage(page);
    eventListFilter(FilterEventListRequestDto, page);
  };

  /**
   * Handles the click event when the sort option is clicked.
   *
   * @param {string} option - The sort option selected.
   * @return {void} This function does not return anything.
   */
  const handleSortClick = (option) => {
    console.log(handleSortClick);
    setSortOption(option);
    // handleSort(option);
  };

  return (
    <div className="h-100">
      <Container>
        <Row>
          <Col xs="12" className="d-md-none d-block mt-4">
            <InputGroup className="mb-3">
              <Form.Control placeholder="Search" />
              <InputGroup.Text className="bg-primary text-white">
                <FontAwesomeIcon icon={faSearch} className="mx-3" />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col xs="12">
            <div className="py-4">
              <Link to="/home">Home</Link>
              <FontAwesomeIcon
                icon={faChevronRight}
                className="mx-3"
                size="sm"
              />
              <span>Events</span>
            </div>
          </Col>
          <Col
            xs={3}
            className={`flex-column align-items-start pt-md-5 pt-3 mobile-filter-sidepanel ${showFilterPanel ? 'show' : ''}`}
          >
            <div className="d-flex d-md-none mb-3">
                <Button onClick={() => setShowFilterPannel(false)} variant="link" className="text-decoration-none ms-auto p-0">
                  <FontAwesomeIcon icon={faTimesCircle} size="2x"/>
                </Button>
              </div>
            <div className="w-100 px-4">
              {/* Add your sidebar filters here */}
              <h6 className="fw-bold mb-3">FORMAT</h6>
              {/* Example filter radio buttons */}
              <ul className="p-0 mb-4 list-unstyled">
                <Form>
                  <Col key="radio">
                    <Form.Check
                      type="radio"
                      name="filter"
                      id="all"
                      label="All"
                      value="all"
                      onChange={handleFormatChange}
                      className="mb-2"
                    />
                    {Object.entries(locationTypes).map(([key, value]) => (
                      <Form.Check
                        key={key}
                        type="radio"
                        name="filter"
                        id={key}
                        label={value.name}
                        value={value.type}
                        onChange={handleFormatChange}
                        className="mb-2"
                      />
                    ))}
                  </Col>
                </Form>
              </ul>
              <hr />
              <h6 className="fw-bold mb-3">CATEGORY</h6>
              <ul className="p-0 mb-4 list-unstyled">
                <Form>
                  <Col>
                    <Form.Check
                      type="radio"
                      name="filter"
                      id="all"
                      label="All"
                      value="all"
                      onChange={handleCategoryChange}
                      className="mb-2"
                    />
                    {Object.entries(categoryDetails).map(([key, value]) => (
                      <Form.Check
                        key={key}
                        type="radio"
                        name="filter"
                        id={key}
                        label={value.name}
                        value={value.name}
                        onChange={handleCategoryChange}
                        className="mb-2"
                      />
                    ))}
                  </Col>
                </Form>
              </ul>
              <hr />
              <h6 className="fw-bold mb-3">TYPES</h6>
              <ul className="p-0 mb-4 list-unstyled">
                <Form>
                  <Col>
                    <Form.Check
                      disabled={
                        categoryName === 'all' || categoryName === ''
                          ? true
                          : false
                      }
                      type="checkbox"
                      name="filter"
                      id="all"
                      label="All"
                      value="all"
                      onChange={handleTypeChange}
                      className="mb-2"
                    />
                    {Object.entries(categoryDetails).map((key, value) => {
                      if (categoryDetails[value]['name'] === categoryName) {
                        return Object.entries(
                          categoryDetails[value]['eventSubCategoriesModelList']
                        ).map(([key, value]) => (
                          <Form.Check
                            disabled={
                              typesSelected.includes('all') ? true : false
                            }
                            key={key}
                            type="checkbox"
                            name="filter"
                            id={key}
                            label={value.name}
                            value={value.name}
                            onChange={handleTypeChange}
                            className="mb-2"
                          />
                        ));
                      }
                    })}
                  </Col>
                </Form>
              </ul>
              <hr />
              <h6 className="fw-bold mb-3">Date</h6>
              <ul className="p-0 mb-4 list-unstyled">
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="thiWeekend"
                    label="This Weekend"
                    value="thisWeekend"
                    onChange={handleDateChange}
                    className="mb-2"
                  />
                </li>
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="Date Range"
                    label="Date Range"
                    value="Date Range"
                    onClick={handleDateRangeSelect}
                    className="mb-2"
                  />
                  {showDateRangeFields && (
                    <div className="ps-4 mb-3">
                      <div className="mb-2">
                        <p className="mb-0">Start Date:</p>
                        <DatePicker
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => setStartDate(date)}
                        />
                      </div>
                      <div>
                        <p className="mb-0">End Date:</p>
                        <DatePicker
                          selected={endDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </div>
                  )}
                </li>
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="Weekdays"
                    label="Weekdays"
                    value="Weekdays"
                    onChange={handleDateChange}
                    className="mb-2"
                  />
                </li>
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="Weekends"
                    label="Weekends"
                    value="Weekends"
                    onChange={handleDateChange}
                    className="mb-2"
                  />
                </li>
                <li>
                  <Form.Check
                    type="radio"
                    name="filter"
                    id="All"
                    label="All"
                    value="All"
                    onChange={handleDateChange}
                    className="mb-2"
                  />
                </li>
              </ul>
              <hr />
              <div className="my-4">
                <Button
                  className="rounded btn-primary px-4 border-primary me-2 text-white"
                  onClick={applyFilters}
                >
                  Apply
                </Button>
                <Button
                  className="rounded btn-light px-4 border"
                  onClick={resetFilters}
                >
                  Reset
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={9}>
            <Row className="align-items-center mb-4">
              <Col xs={6} className="d-flex">
                <Button
                  onClick={() => setShowFilterPannel(true)}
                  variant="primary"
                  className="d-md-none text-white me-3 border-primary rounded"
                >
                  Filter
                </Button>
                <h6 className="fw-bold mt-2">Showing all {result} results</h6>
              </Col>
              <Col xs={6} className="text-end">
                <Dropdown>
                  <Dropdown.Toggle className="border rounded">
                    {sortOption}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleSortClick(SORT_OPTIONS.SORT_BY_DATE)}
                    >
                      {SORT_OPTIONS.SORT_BY_DATE}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleSortClick(SORT_OPTIONS.SORT_ALPHABETICALLY)
                      }
                    >
                      {SORT_OPTIONS.SORT_ALPHABETICALLY}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            {allEvents.map((event, index) => {
              return (
                <EventListCard event={event} key={index} index={index} />
                // <ul key={index} className="list-unstyled p-0 mb-4">
                //   <li>
                //     <Link to={`/events/${event.eventId}`}>
                //       <Card className="bg-header">
                //         <Card.Body className="px-5 py-4">
                //           <div className="row">
                //             <div className="col-md-2 col-md-2 d-flex align-items-center">
                //               {event.thumbnailUrl &&
                //                 <Card.Img className="img-fluid" src={event.thumbnailUrl} alt={event.eventTitle} />
                //               }
                //               {!event.thumbnailUrl &&
                //                 <Card.Img className="img-fluid" src={defaultThumbnail} alt={event.eventTitle} />
                //               }
                //             </div>
                //             <div className="col-md-10 d-flex align-items-center">
                //               <div className="d-flex flex-column h-100 text-default">
                //                   <Card.Title as="h3" className="fw-bold text-primary">{event.eventTitle}</Card.Title>
                //                   <Card.Text as="h4">{moment(event.eventStartDate).format("MMM DD")}</Card.Text>
                //                   <Card.Text as="h5">{event.summary}</Card.Text>
                //               </div>

                //               <div className="ms-auto">
                //                 {index % 2 == 0 &&
                //                   <div>
                //                     <Button style={{width: "225px"}} variant="primary" className="rounded-pill d-flex align-items-center px-4 py-3">
                //                       <FontAwesomeIcon icon={faShoppingCart} />
                //                       <div className="text-white text-center flex-grow-1">
                //                           <h5 className="mb-0">  View Tickets </h5>
                //                           <div className="fs-12">Start from $20</div>
                //                       </div>
                //                     </Button>
                //                     <p className="text-default mt-2 text-center">120 People shared this event</p>
                //                   </div>
                //                 }

                //                 {index % 2 != 0 &&
                //                   <div>
                //                     <Button style={{width: "225px"}} to={`/events/${event.eventId}/performances`} as={Link} variant="primary"
                //                       className="rounded-pill d-flex align-items-center px-4 py-3">
                //                       <FontAwesomeIcon icon={faShoppingCart} />
                //                       <div className="text-white text-center flex-grow-1">
                //                         <h5 className="mb-0">View Performances</h5>
                //                         <div className="fs-12">Start from $20</div>
                //                       </div>
                //                     </Button>
                //                     <p  className="text-default mt-2 text-center">120 People shared this event</p>
                //                   </div>
                //                 }
                //               </div>
                //             </div>
                //           </div>

                //           <div>
                //             {visibleIndex === index &&
                //               <>
                //                 {/* event description */}
                //                 <div className="text-default mt-4">
                //                     Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                //                     Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                //                       when an unknown printer took a galley of type and scrambled it to make a type
                //                       specimen book. It has survived not only five centuries, but also the leap into ele
                //                       ctronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                //                       with the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                //                         with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsu
                //                 </div>
                //               </>
                //             }

                //             <div className="d-flex justify-content-center">
                //               <Button variant="link" onClick={(e) => toggleVisibility(e, index)}>
                //                 <FontAwesomeIcon size="2x" className="text-default" title="event Information"
                //                   icon={visibleIndex === index ? faChevronUp :  faChevronDown} />
                //               </Button>
                //             </div>

                //           </div>

                //         </Card.Body>
                //       </Card>
                //     </Link>
                //   </li>
                // </ul>
              );
            })}

            {upcomingEvents && !!upcomingEvents.length && (
              <Row>
                <Col className="my-4 px-5">
                  <h3 className="fw-bold mb-4">Upcoming</h3>
                  <Row>
                    {upcomingEvents.map((event) => {
                      return (
                        <Col xs={12} md={6} lg={4} className="mb-4">
                          <a
                            href={`/events/${event.eventId}`}
                            className="hoverable-card position-relative"
                          >
                            <div
                              className="d-flex justify-content-center event-card"
                              style={{
                                backgroundImage: `url(${event.thumbnailUrl})`,
                                height: '250px',
                              }}
                            >
                              <div className="hoverable-text-hidden d-flex flex-column flex-grow-1">
                                <div>
                                  <div className="d-inline-block m-3 bg-black px-3 py-1 text-white">
                                    <small>
                                      {moment(event.eventDate).format('MMM DD')}
                                    </small>
                                  </div>
                                </div>
                                {/* <h1 className="text-uppercase fw-bold">{event.eventTitle}</h1>
                                                        <h2>{moment(event.eventDate).format('ddd MMM DD, YYYY hh:mm A')}</h2> */}
                                <h3 className="text-uppercase fw-bold mb-3 mt-auto text-center text-white">
                                  {event.eventTitle}
                                </h3>
                              </div>

                              <div className="hoverable-text-visible">
                                <div className="banner-overlay">
                                  <div className="p-3">
                                    <h3 className="text-uppercase fw-bold mb-3 mt-auto text-center text-white">
                                      {event.eventTitle}
                                    </h3>
                                    <h5 className="text-center text-white">
                                      {moment(event.eventDate).format(
                                        'ddd MMM DD, YYYY hh:mm A'
                                      )}
                                    </h5>
                                    <p className="event-card-text-ellipses">
                                      YEAT This is an ALL AGES show. HISTORY is
                                      proud to have a food menu brought to you
                                      by Food Dudes & Blondies each and every
                                      night. If you have tickets for the Premium
                                      Seating areas and arEAT This is an ALL
                                      AGES show. HISTORY is proud to have a food
                                      menu brought to you by Food Dudes &
                                      Blondies each and every night. If you have
                                      tickets for the Premium Seating areas and
                                      arEAT This is an ALL AGES show. HISTORY is
                                      proud to have a food menu brought to you
                                      by Food Dudes & Blondies each and every
                                      night. If you have tickets for the Premium
                                      Seating areas and ar
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {totalPages > 1 && (
          <Container>
            <Row className="justify-content-center">
              <Col xs="auto">
                <div>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (page) => (
                      <button key={page} onClick={() => handlePageChange(page)}>
                        {page}
                      </button>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        )}

        {/* <Row>
                        <Col className="px-0">
                            <div className='p-3'>
                                <h2>Your Events</h2>
                            </div>
                        {allEvents.map((event) => {
                            return (
                                <div className="homebanner mb-5" style={{backgroundImage: `url(${event.thumbnailUrl})`}}>
                                    <div id="bannertext" className="mt-auto p-5 mb-5 mx-4">
                                        <div>
                                            <h1 className="text-uppercase fw-bold">{event.eventTitle}</h1>
                                            <h2>{moment(event.eventDate).format('ddd MMM DD, YYYY hh:mm A')}</h2>
                                        </div>
                                        <Button to={`/preview-event/${event.eventId}`} as={Link} className='text-white px-4' variant='primary'>
                                            Get Tickets
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}
                        </Col>
                    </Row> */}

        {/* {upcomingEvents.length &&
                    <Row>
                        <Col xs={12} className="p-5 text-center">
                            <h2 className="fw-bold">Upcoming Events</h2>
                        </Col>
                        <Col xs={12} className="my-4">
                            <ul className="artists-carousel">
                                {upcomingEvents.map((event) => {
                                    return(
                                        <li>
                                            <picture>
                                                <source srcSet={event.thumbnailUrl} media="(min-width: 768px)" />
                                                <img className='h-100' src={event.thumbnailUrl} alt="artist 1" />
                                            </picture>
                                            <h5 className='title'>{event.eventTitle}</h5>
                                        </li>
                                    )
                                })}

                            </ul>
                        </Col>
                    </Row>
                    } */}

        {/* <Row>
                        <Col className="px-0">
                            <div className='p-3'>
                                <h2>Featured Events</h2>
                            </div>
                        {allEvents.map((event) => {
                            return (
                                <div className="homebanner mb-5" style={{backgroundImage: `url(${event.thumbnailUrl})`}}>
                                    <div id="bannertext" className="mt-auto p-5 mb-5 mx-4">
                                        <div>
                                            <h1 className="text-uppercase fw-bold">{event.eventTitle}</h1>
                                            <h2>{moment(event.eventDate).format('ddd MMM DD, YYYY hh:mm A')}</h2>
                                        </div>
                                        <Button to={`/preview-event/${event.eventId}`} as={Link} className='text-white px-4' variant='primary'>
                                            Get Tickets
                                        </Button>
                                    </div>
                                </div>
                            )
                        })}

                        </Col>
                    </Row> */}

        <Row></Row>
      </Container>
      {/* <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div>
            <p>Start Date:</p>
            <DatePicker selected={startDate} dateFormat="dd/MM/yyyy" onChange={date => setStartDate(date)} />
          </div>
          <div>
            <p>End Date:</p>
            <DatePicker selected={endDate} dateFormat="dd/MM/yyyy" onChange={date => setEndDate(date)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleModalApply}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal> */}
      <div className="mt-5">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default EventDashboard;
