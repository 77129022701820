import React, { useEffect, useState } from 'react';
import {
  Link,
  matchPath,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Row,
  Spinner,
  Stack,
  Navbar,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCircleXmark,
  faClose,
  faLocation,
  faLock,
  faMapMarker,
  faMapMarkerAlt,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  fetchPaymentMethods,
  getEventDetails,
  getUserDetails,
  saveBillingInfo,
  validateCoupon,
} from '../utils/APIUtils';
import { useAsync } from '../hooks/useAsync';
import LabelIcon from '@mui/icons-material/Label';
import AddNewPayment from '../StripePayment/AddNewPayment';
import moment from 'moment';
import PayThroughExisting from '../StripePayment/PayThroughExisting';
import PaymentStatus from './PaymentStatus';
import {
  REACT_APP_CALENDAR_ID,
  REACT_APP_GOOGLE_ACCESS_TOKEN,
  REACT_APP_GOOGLE_API_KEY,
  SERVICE_FEE_PERCENT,
  TAX_PERCENTAGE,
} from '../constants';
import TermsAndConditions from '../policies/TermsAndConditions';
import ShareEvent from './ShareEvent';
import ToastMessage from '../shared/ToastMessage';
import {
  getSignedInUserEmail,
  initClient,
  publishTheCalenderEvent,
  signInToGoogle,
  signOutFromGoogle,
} from './useGoogleService';
import Counter from '../shared/Counter';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon, marker } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Footer from '../Footer/Footer';

/**
 * An enumeration representing the steps for the order.
 * @readonly
 * @enum {string}
 */
const ORDER_STEP = Object.freeze({
  COUPON_DETAILS: 'ADD_COUPON',
  CART: 'CART',
  BILLING_DETAILS: 'BILLING_DETAILS',
  CHECKOUT: 'CHECKOUT',
  CONFIRMED: 'CONFIRMED',
});

/**
 * An enumeration representing the names of the order steps.
 * @readonly
 * @enum {string}
 */
const ORDER_STEP_NAMES = Object.freeze({
  [ORDER_STEP.COUPON_DETAILS]: 'Find Ticket',
  [ORDER_STEP.CART]: 'Cart',
  [ORDER_STEP.BILLING_DETAILS]: 'Billing',
  [ORDER_STEP.CHECKOUT]: 'Pay/Verify',
  [ORDER_STEP.CONFIRMED]: 'Done',
});

/**
 * Function that returns the logo path for a given card type.
 *
 * @param {string} card_type - The type of the card.
 * @return {string} The logo path for the card type.
 */
const GET_CARD_LOGOS = (card_type) => {
  let icon = '';
  switch (card_type.toUpperCase()) {
    case 'VISA':
      icon = 'visa_logo.svg';
      break;
    case 'MASTERCARD':
      icon = 'mastercard_logo.svg';
      break;
    case 'AMEX':
      icon = 'amex_logo.svg';
      break;
    default:
      icon = 'debit_card_logo.png';
      break;
  }

  return icon;
};

/**
 * An object representing the initial status of the order. The object has 3 read-only properties:
 * - show: a boolean indicating whether the order status should be shown or not.
 * - msg: a string containing the message to be displayed for the order status.
 * - success: a boolean indicating whether the order status is a success status or not.
 *
 * @readonly
 * @constant
 * @type {Object}
 * @property {boolean} show - Indicates whether the order status should be shown or not.
 * @property {string} msg - The message to be displayed for the order status.
 * @property {boolean} success - Indicates whether the order status is a success status or not.
 */
const initialOrderStatus = Object.freeze({
  show: false,
  msg: '',
  success: false,
});

/**
 * @function
 * @name ProgressStepper
 * @description - React function component that renders a progress stepper based on the provided steps and active step.
 * @param {Object} props - The properties object.
 * @param {Object} props.steps - An object representing the steps of the progress stepper.
 * @param {string} props.activeStep - The currently active step.
 * @returns {JSX.Element} - The rendered progress stepper.
 */
const ProgressStepper = ({ steps, activeStep }) => {
  if (!steps) {
    // return nothing if there is no steps
    return <></>;
  }

  /**
   * @constant {string[]} keys - An array of keys extracted from the object representing the steps of the progress stepper.
   */
  const keys = Object.keys(steps);

  /**
   * @constant {number} activeIndex - The index of the active step in the progress stepper.
   *
   * @description
   * The `activeIndex` constant stores the index value of the currently active step in the progress
   * stepper. It is calculated by finding the index of the `activeStep` within the `keys` array.
   *
   * @see {@link ProgressStepper} for the complete function body.
   */
  const activeIndex = keys.indexOf(activeStep);

  return (
    <div className="timeline-steps">
      {keys.map((k, i) => {
        return (
          <div
            key={i}
            className={`timeline-step m-lg-3 ${
              activeIndex > i
                ? 'completed'
                : activeIndex == i
                ? 'current-step'
                : ''
            }`}
          >
            <div className="timeline-content">
              <div className="inner-circle">
                <div className="step-number">
                  <span>{++i}</span>
                </div>
              </div>
              <p className="h6 mt-4 mb-1">{steps[k]}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Ticket = (props) => {
  let { sectionName, capacity, inPersonTicketSectionTypeDetailsDtoList } =
    props.ticketSectionDTO;

  return (
    <div className="px-lg-5">
      <Row className="px-lg-5 mb-4">
        <Col lg={12} xs={10} className="ticket px-lg-5 mx-auto">
          <Row>
            <Col xs={6} lg={8} className="px-5 py-lg-5 py-3 ">
              <h5 className="mb-4">
                {sectionName}
                <span className="fs-12 ms-2">
                  ({capacity} Tickets Available)
                </span>
              </h5>
              <Stack direction="vertical" gap={0}>
                {inPersonTicketSectionTypeDetailsDtoList.map(
                  (ticketTypeDTO, i) => {
                    const {
                      active,
                      description,
                      id,
                      price,
                      serviceFees,
                      ticketType,
                    } = ticketTypeDTO;
                    if (active) {
                      return (
                        <Badge
                          key={id}
                          pill
                          className="d-flex px-3 py-2 mb-3"
                          bg={ticketType == 'General' ? 'primary' : 'light'}
                          text={ticketType == 'General' ? '' : 'dark'}
                        >
                          <h6 className="mb-0">{ticketType}</h6>
                          <h6 className="ms-auto mb-0">
                            ${price + (serviceFees || 0)}
                          </h6>
                        </Badge>
                      );
                    }
                    return <></>;
                  }
                )}
              </Stack>
            </Col>
            <Col
              xs={6}
              lg={4}
              className="px-5 py-lg-5 py-3  border-left-dashed-5"
            >
              <h5 className="mb-4">Quantity</h5>
              {inPersonTicketSectionTypeDetailsDtoList.map(
                (ticketTypeDTO, i) => {
                  const {
                    active,
                    description,
                    id,
                    price,
                    serviceFees,
                    ticketType,
                  } = ticketTypeDTO;

                  let selectedTicketsInOtherTypes =
                    inPersonTicketSectionTypeDetailsDtoList.reduce(
                      (acc, curr) => {
                        if (curr.id != ticketTypeDTO.id) {
                          return acc + (curr.selectedQuantity || 0);
                        } else {
                          return acc;
                        }
                      },
                      0
                    );

                  const availableTickets =
                    capacity - selectedTicketsInOtherTypes;

                  if (active) {
                    return (
                      <div className="mb-3">
                        <Form.Select
                          value={ticketTypeDTO.selectedQuantity}
                          onChange={(e) =>
                            props.handleTicketSelection(
                              id,
                              sectionName,
                              e.target.value
                            )
                          }
                        >
                          <option value="0">0</option>
                          {Array.from(Array(availableTickets)).map(
                            (item, i) => {
                              return (
                                <option key={i} value={i + 1}>
                                  {i + 1}
                                </option>
                              );
                            }
                          )}
                        </Form.Select>
                      </div>
                    );
                  }
                  return <></>;
                }
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

// Function represents the Preview event component for the preview event page
const PreviewEvent = () => {
  // Assign the React App Calendar ID to a constant 'CalendarID'
  const calendarID = REACT_APP_CALENDAR_ID;

  // Assign the React App Google API Key to a constant 'apikey'
  const apiKey = REACT_APP_GOOGLE_API_KEY;

  // Assign React App Google Access Token to a constant 'accessToken'
  const accessToken = REACT_APP_GOOGLE_ACCESS_TOKEN;

  // State hook to set signed in state of user
  const [signedin, setSignedIn] = useState(false);

  // State hook to set the google authorized email
  const [googleAuthedEmail, setgoogleAuthedEmail] = useState(null);

  // State hook to set the description
  const [description, setDescription] = useState('');

  // State hook to set the start time
  const [startTime, setStartTime] = useState('');

  // state hook to set the end time
  const [endTime, setEndTime] = useState('');

  const [markerPosition, setMarkerPosition] = useState([43.6529, -79.3849]);

  const [showMap, setShowMap] = useState(true);

  const customIcon = new Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/447/447031.png',
    iconSize: [38, 38], // size of the icon
  });

  // This used to happen we add address and the marker moves but map remains there only. weird to not handle out of the box
  // So here we have my own logic to handle centering map.
  const Centerer = ({ center }) => {
    const map = useMap();

    useEffect(() => {
      map.setView(center);
    }, [center, map]);

    return null;
  };

  // Function used to get the google authorized email when user sign with the email
  const getGoogleAuthorizedEmail = async () => {
    let email = await getSignedInUserEmail();
    if (email) {
      setSignedIn(true);
      setgoogleAuthedEmail(email);
    } else {
      getAuthToGoogle();
    }
  };

  // function to sign in to google and get signed in email
  const getAuthToGoogle = async () => {
    let successfull = await signInToGoogle();
    if (successfull) {
      getGoogleAuthorizedEmail();
    }
  };

  // Function to sign out from the google
  const _signOutFromGoogle = () => {
    let status = signOutFromGoogle();

    // check the status if the user signed out successfully and set signed in and google authorized email to null
    if (status) {
      setSignedIn(false);
      setgoogleAuthedEmail(null);
    }
  };

  // Function representing how to add an event to a google calendar
  const addEventToGoogleCalendar = () => {
    initClient((success) => {
      if (success) {
        getGoogleAuthorizedEmail();

        // Event variable stote the information of the event like summery, location, start date time, end date time and attendees in json key value pair format
        let event = {
          summary: eventDTO.eventTitle,
          location: window.location.href,
          start: {
            dateTime: moment(
              eventDTO.eventStartDate + ' ' + eventDTO.eventStartTime
            ).toISOString(),
            timeZone: eventDTO.timezone,
          },
          end: {
            dateTime: moment(
              eventDTO.eventEndDate + ' ' + eventDTO.eventEndTime
            ).toISOString(),
            timeZone: eventDTO.timezone,
          },
          // recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
          attendees: [],
          reminders: {
            useDefault: false,
            overrides: [
              { method: 'email', minutes: 24 * 60 },
              { method: 'popup', minutes: 10 },
            ],
          },
        };

        // Function represents the publish and event on the calendar
        publishTheCalenderEvent(event).then((res) => {
          if (res.error) {
            let msg = res.error.message;
            setToast({ show: true, msg: msg, success: false });
          } else if (res.htmlLink) {
            setToast({
              show: true,
              msg: 'Event Successfully added to your calendar',
              success: true,
            });
          } else {
            setToast({
              show: true,
              msg: 'Unable to add event to your calender. Please try after some time',
              success: false,
            });
          }
        });
      }
    });
  };

  // Hook for handling the asynchronous event
  const { data, loading, error, run } = useAsync(null);

  // State hook to set the Toast
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });

  // Extract the route parameter using the userParams() hook
  const params = useParams();

  // Extract the URL for the pathname using the useLocation() hook
  const { pathname } = useLocation();

  // Set the search parameters using the useSearchParams() hook
  const [searchParams, setSearchParams] = useSearchParams();

  const showTickets = searchParams.get('showTickets') === 'true' ? true : false; // convert to boolean since it will return a string i.e. 'true' or 'false'

  // // Dislplay the searchParams on the console
  // console.log("searchParams ", searchParams)

  const isPreview = matchPath(
    {
      path: '/preview-event/:eventId',
      exact: true,
    },
    pathname
  ); // check if path matches with preview event path

  // State hook to set the event DTO using the useState() hook
  const [eventDTO, setEventDTO] = useState(null);

  // State hook to set the show modal state
  const [showModal, setShowModal] = useState(false);

  // State hook to set the show share modal state
  const [showShareModal, setShowShareModal] = useState(false);

  // State hook to set the order loading state
  const [orderLoading, setOrderLoading] = useState(false);

  // State hook to set the billing detail using useState()
  const [billingDetails, setBillingDetails] = useState(null);

  // State hook used to update the state of terms accepted by the user
  const [termsAccepted, setTermsAccepted] = useState(false);

  // State hook to set the order step state for the user
  const [orderStep, setOrderStep] = useState('');

  // const [checkoutDone, setCheckoutDone] = useState(false);

  // State hook to set the show terms for the user
  const [showTerms, setShowTerms] = useState(false);

  // State hook to set the payment status for the user
  const [paymentStatus, setPaymentStatus] = useState(initialOrderStatus);

  // State hook to set the payment method list for the user
  const [paymentMethodList, setPaymentMethodList] = useState([]);

  // State hook is used to set the state of Subscription with the updater function setSubscription
  const [subscription, setSubscription] = useState(false);

  // State hook to set the new payment state
  const [newPayment, setNewPayment] = useState(false);

  // State hook to set the payment method Id state for the user
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);

  // State hook to update the state of the pay with existing element updater
  const [payWithExistingElement, setPayWithExistingElement] = useState(false);

  // State for user to enter coupon code
  const [couponCode, setCouponCode] = useState('');

  // State when coupon is validated and applied
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  // State hook to set the state of the show coupon toast
  const [showCouponToast, setShowCouponToast] = useState(false);

  // State for user when entered coupon is not valid
  const [couponError, setCouponError] = useState('');

  // State hook to set the state for order ID
  const [orderId, setOrderId] = useState(null);

  // useEffect() hook used to fetch the event detail and payment method
  useEffect(() => {
    // It fetch the event detail and update the state of the event
    run(() => getEventDetails(params.eventId))
      .then((res) => {
        setEventDTO(res.data);
        console.log('loading', loading);
      })
      .then(() => {
        // Condition check the preview mode
        if (!isPreview) {
          // If preview mode in not available then fetch the payment method and set the payment method list
          fetchPaymentMethods().then((res) => {
            setPaymentMethodList(res.data);
          });
        }

        if (showTickets) {
          loadOrderDetails();
        }
      })
      .catch((err) => {
        let msg = err.response.data.error.message;
        setToast({ show: true, msg: msg, success: false });
      });
    // .finally(handleStripeRedirect)
  }, []);

  // React hook is used to perform the side effects in the function component
  useEffect(() => {
    if (data) {
      // This function is called if the data is available
      //which means user is already on the event page
      handleStripeRedirect();
    }
  }, [loading]);

  // function representing the load order detail component
  const loadOrderDetails = async () => {
    //uncomment this before commit
    // if(new Date(eventDTO.eventTicketDetailsModel.salesEndDate  + ' ' + eventDTO.eventTicketDetailsModel.salesEndTime).getTime() < new Date().getTime()){
    //     setToast({show: true, msg: "Ticket sales already closed for this event!" , success: false});
    //     return
    // }

    // Updater for the order step state
    setOrderStep(ORDER_STEP.COUPON_DETAILS);

    // Updater for the order loading state
    setOrderLoading(true);

    // Updater for the show modal state
    setShowModal(true);

    if (!billingDetails) {
      //call api if we don't have billing details for the user
      await getUserDetails()
        .then((res) => {
          let detailsDTO = {
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            setAsDefaultAddress: false,
            orderBillingAddressDto: {},
          };
          if (
            res.data.addressDetailsList &&
            res.data.addressDetailsList.length
          ) {
            let billingDto = res.data.addressDetailsList.filter(
              (item) => item.addressType == 'BILLING'
            )[0];
            delete billingDto.addressType;
            detailsDTO.orderBillingAddressDto = billingDto;
          }
          setBillingDetails(detailsDTO);
        })
        .catch((err) => {
          console.log(err);
          let msg = err.response.data.error.message;
          setToast({ show: true, msg: msg, success: false });
        });
    }
    setOrderLoading(false);
  };

  // Function to fetch and validate the coupon detail
  const getCouponDetailsAndValidate = (eventId, couponCode) => {
    // Display coupon code on console
    console.log(couponCode);
    couponCode = couponCode.trim();

    // Function to validate the coupon and update the applied coupon and show coupon Toast state
    validateCoupon(eventId, couponCode)
      .then((res) => {
        console.log('coupon details', res);
        setAppliedCoupon(res.data);
        setShowCouponToast(true);
        setTimeout(() => {
          setShowCouponToast(false);
        }, 2000);
      })
      .catch((e) => {
        setCouponError(e.response.data.error.message);
        console.log('coupon error', e.response.data.error.message);
      });
  };

  // Function to update the payment method if user pay with existing mode of payment
  const payWithExisting = (paymentId) => {
    // Function to select the payment method
    setSelectedPaymentMethodId(paymentId);

    // Function Tigger the Display of the payment element
    setPayWithExistingElement(true);
  };

  // Function representing the calculation of the total price when user purchase a ticket for an event
  const getTotalPrice = (ticketDetailsDTO) => {
    let price = ticketDetailsDTO.price,
      fee = SERVICE_FEE_PERCENT * price,
      disc = appliedCoupon ? appliedCoupon.couponDiscount : 0,
      tax = ticketDetailsDTO.chargeTax
        ? 0
        : ticketDetailsDTO.price * TAX_PERCENTAGE;
    return price + fee + tax - disc;
  };

  // Function to reset the state of the order
  const resetOrderState = () => {
    setShowModal(false);
    setTermsAccepted(false);
    setAppliedCoupon(null);
    setCouponCode('');
    setOrderId(null);
    setOrderStep(ORDER_STEP.COUPON_DETAILS);

    if (eventDTO.locationTypeModel.type == 'In Person') {
      let { inPersonTicketSectionDetailsList } =
        eventDTO.eventTicketDetailsModel;
      inPersonTicketSectionDetailsList.map((ticketSectionDTO) => {
        return ticketSectionDTO.inPersonTicketSectionTypeDetailsDtoList.map(
          (typeDTO) => {
            typeDTO.selectedQuantity = '';
            return typeDTO;
          }
        );
      });

      setEventDTO({
        ...eventDTO,
        eventTicketDetailsModel: {
          ...eventDTO.eventTicketDetailsModel,
          inPersonTicketSectionDetailsList,
        },
      });
    }
  };

  // Function to update the billing detail
  const updateBillingDetails = (key, value) => {
    setBillingDetails({
      ...billingDetails,
      orderBillingAddressDto: {
        ...billingDetails.orderBillingAddressDto,
        [key]: value,
      },
    });
  };

  //Function represent the checkout
  const checkout = () => {
    // Save the billing information
    saveBillingInfo(eventDTO.id, billingDetails)
      .then((res) => {
        // If the billing is successful then log the result and set the order ID and update the order step
        console.log(res);
        setOrderId(res.data);
        setOrderStep(ORDER_STEP.CHECKOUT);
      })
      .catch((err) => {
        // If there is an error then log the result and update the order step and show a toast message
        console.log(err);
        setOrderStep(ORDER_STEP.CHECKOUT);
        let msg = err.response.data.error.message;
        setToast({ show: true, msg: msg, success: false });
      });
  };

  // This function is used to handle the payment callback
  const handlePaymentCallback = (result) => {
    // Log the result for the debugging purpose
    console.log(result);
    let isSuccess = false,
      msg = '';

    // chck if there is an error in the payment success and message
    if (result.error) {
      msg = result.error.message;
    } else if (result.paymentIntent.status === 'succeeded') {
      isSuccess = true;
      setEventDTO({ ...eventDTO, ticketBought: true });
    }

    // Set up an object method to set the payment status
    setPaymentStatus({
      show: true,
      success: isSuccess,
      msg: msg,
    });

    // Call a resetOrderState() function to reset the state of the order
    resetOrderState();
  };

  // Function represent the handles stripe redirect component
  const handleStripeRedirect = () => {
    if (
      searchParams.has('payment_intent') &&
      searchParams.get('redirect_status') == 'succeeded'
    ) {
      let result = {
        paymentIntent: {
          status: searchParams.get('redirect_status'),
          client_secret: searchParams.get('payment_intent_client_secret'),
          id: searchParams.get('payment_intent'),
        },
      };
      handlePaymentCallback(result);
    }
  };

  // Function is used to fetch the detail of order for in person ticket type
  const getInPersonTicketTypeOrderDetailsDtoList = () => {
    let inPersonTicketTypeOrderDetailsDtoList = [];

    if (eventDTO.locationTypeModel.type == 'In Person') {
      eventDTO.eventTicketDetailsModel.inPersonTicketSectionDetailsList.map(
        (sectionDTO) => {
          sectionDTO.inPersonTicketSectionTypeDetailsDtoList.map((typeDTO) => {
            if (
              typeDTO.selectedQuantity &&
              parseInt(typeDTO.selectedQuantity) > 0
            ) {
              inPersonTicketTypeOrderDetailsDtoList.push({
                ticketTypeId: typeDTO.id,
                quantity: parseInt(typeDTO.selectedQuantity),
              });
            }
          });
        }
      );
    }

    return inPersonTicketTypeOrderDetailsDtoList;
  };

  // Function used to handle the selection of ticket
  const handleTicketSelection = (id, sectionName, quantity) => {
    let { inPersonTicketSectionDetailsList } = eventDTO.eventTicketDetailsModel;

    inPersonTicketSectionDetailsList = inPersonTicketSectionDetailsList.map(
      (section) => {
        if (section.sectionName == sectionName) {
          section.inPersonTicketSectionTypeDetailsDtoList.map((type) => {
            if (type.id == id) {
              type.selectedQuantity = quantity;
            }

            return type;
          });
        }

        return section;
      }
    );

    setEventDTO({
      ...eventDTO,
      eventTicketDetailsModel: {
        ...eventDTO.eventTicketDetailsModel,
        inPersonTicketSectionDetailsList: [...inPersonTicketSectionDetailsList],
      },
    });

    console.log(
      eventDTO.eventTicketDetailsModel.inPersonTicketSectionDetailsList
    );
  };

  // Function is used to disable the ticket button
  const disableGetTicketsButton = () => {
    if (!termsAccepted) {
      return true;
    }

    const eventType = eventDTO.locationTypeModel.type;
    let ticketSelected = false;
    if (eventType == 'In Person') {
      eventDTO.eventTicketDetailsModel.inPersonTicketSectionDetailsList.map(
        (sectionDTO) => {
          sectionDTO.inPersonTicketSectionTypeDetailsDtoList.map(
            (ticketTypeDTO) => {
              if (
                ticketTypeDTO.selectedQuantity &&
                parseInt(ticketTypeDTO.selectedQuantity) > 0
              ) {
                ticketSelected = true;
              }
            }
          );
        }
      );

      if (!ticketSelected) {
        return true;
      }
      return false;
    }
  };

  // Function is used to fetch the remaining time
  const getRemainingTime = (date, time) => {
    let futureDate = moment(date + ' ' + time);

    // Calculate the difference between the future date and the current date
    let duration = moment.duration(futureDate.diff(moment()));

    // Format the duration as days, hours, minutes, and seconds
    let remainingTime =
      duration.days() +
      ' days ' +
      duration.hours() +
      ' hours ' +
      duration.minutes() +
      ' minutes ';

    return remainingTime;
  };

  if (loading)
    return (
      <div className="p-5 d-flex align-items-center justify-content-center">
        <h6 className="me-2">Loading...</h6>
        <Spinner animation="border" variant="secondary" />
      </div>
    );

  return (
    <div className="h-100">
      <Container fluid className="bg-header">
        {isPreview && (
          <>
            <Row>
              <Col xs="12">
                <div className="p-4 text-center">
                  <h4>Preview</h4>
                </div>
              </Col>
            </Row>
            <Row className="bg-light">
              <Col xs="12">
                <div className="p-4 text-center">
                  <Button
                    to={'/promoter-panel/events'}
                    as={Link}
                    variant="link"
                    className="text-dark"
                  >
                    <h5>Close Preview</h5>
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}

        {/* banner section starts */}
        <Row>
          <Col className="px-0">
            <div
              className="homebanner position-relative"
              style={{
                backgroundImage: `url(/default_event_banner.png)`,
                height: '653px',
              }}
            >
              <div className="banner-overlay d-flex">
                <div
                  id="bannertext"
                  className="banner-text d-flex flex-grow-1 mt-auto p-5 mb-5 mx-4 text-white"
                >
                  <div>
                    <h1 className="fw-bold">{eventDTO.eventTitle}</h1>
                    <h2>
                      {moment(
                        eventDTO.eventStartDate + ' ' + eventDTO.eventStartTime
                      ).format('ddd MMM DD, YYYY hh:mm A')}{' '}
                      {eventDTO.timezone}
                    </h2>
                    <div className="d-flex">
                      <Button
                        onClick={addEventToGoogleCalendar}
                        variant="link"
                        className="text-white text-decoration-none px-0 d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          className="me-2"
                          size="lg"
                        />
                        <h5 className="mb-0">Add to calendar</h5>
                      </Button>

                      <span className="h-100 py-3 mx-5 h4 mb-0"> </span>

                      <Button
                        onClick={() => setShowShareModal(true)}
                        variant="link"
                        className="text-white text-decoration-none px-0 d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faShareAlt}
                          className="me-2"
                          size="lg"
                        />
                        <h5 className="mb-0">Share</h5>
                      </Button>
                    </div>
                    <h4 className="mt-2">
                      <span>By </span>
                      <span className="fw-bold text-decoration-underline">
                        <Link className="text-white" to={'/promoter-dashboard'}>{eventDTO.promoter}</Link> 
                      </span>
                    </h4>
                  </div>
                  <div className="ms-auto">
                    <Button
                      onClick={() => loadOrderDetails()}
                      size="lg"
                      variant="primary"
                      className="text-white mt-5 px-5 py-3 border-primary rounded-pill"
                    >
                      <h4>View Tickets</h4>
                      {eventDTO.eventTicketDetailsModel.price && (
                        <h6>
                          Start from ${eventDTO.eventTicketDetailsModel.price}{' '}
                          CAD
                        </h6>
                      )}
                    </Button>
                    {/* {(() => {
                                            const isStreamEvent = eventDTO.locationTypeModel.type != 'In Person';

                                            if(isStreamEvent){
                                                if(eventDTO.ticketBought){
                                                    return (
                                                        <Button size="lg" variant="primary" className="text-white mt-3 px-4">
                                                            Starts in {getRemainingTime(eventDTO.eventStartDate, eventDTO.eventStartTime)}
                                                        </Button>
                                                    )
                                                }
                                                else {
                                                    return(
                                                        <Button onClick={() => loadOrderDetails()} size="lg" variant="primary" className="text-white mt-3 px-4">
                                                            View Tickets
                                                            {eventDTO.eventTicketDetailsModel.price &&
                                                                <span> ${eventDTO.eventTicketDetailsModel.price} CAD</span>
                                                            }
                                                        </Button>
                                                    )
                                                }
                                            }
                                            else{
                                                return(
                                                    <Button onClick={() => loadOrderDetails()} size="lg" variant="primary" className="text-white mt-3 px-4">
                                                        View Tickets
                                                        {eventDTO.eventTicketDetailsModel.price &&
                                                            <span> ${eventDTO.eventTicketDetailsModel.price} CAD</span>
                                                        }
                                                    </Button>
                                                )
                                            }
                                        })()} */}
                    {/* {eventDTO.ticketBought && eventDTO.locationTypeModel.type != 'In Person' &&
                                                <Button size="lg" variant="primary" className="text-white mt-3 px-4">
                                                    Starts in {getRemainingTime(eventDTO.eventStartDate, eventDTO.eventStartTime)}
                                                </Button>
                                            }
                                            {!eventDTO.ticketBought && eventDTO.eventTicketDetailsModel &&
                                                <Button onClick={() => loadOrderDetails()} size="lg" variant="primary" className="text-white mt-3 px-4">
                                                    View Tickets
                                                    {eventDTO.eventTicketDetailsModel.price &&
                                                        <span> ${eventDTO.eventTicketDetailsModel.price} CAD</span>
                                                    }
                                                </Button>
                                            } */}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* banner section ends */}
        <Row>
          <Col xs="12">
            <div className="p-5">
              <h4 className="mb-3 fw-bold">About the Event</h4>
              <p dangerouslySetInnerHTML={{ __html: eventDTO.description }}>
                {/* {eventDTO.description} */}
                {/* Dark Vince is Vincent from an alternate timeline where
                                        ToyConvict won PRISON BREAK and took over the world. He
                                        is thought to have grown cynical and evil in his world,
                                        coming to the Light Realm for domination and acting as the
                                        main antagonist of Season 3.
                                        Dark Vince is Vincent from an alternate timeline where
                                        ToyConvict won PRISON BREAK and took over the world. He
                                        is thought to have grown cynical and evil in his world,
                                        coming to the Light Realm for domination and acting as the
                                        main antagonist of Season 3.
                                        Dark Vince is Vincent from an alternate timeline where
                                        ToyConvict won PRISON BREAK and took over the world. He
                                        is thought to have grown cynical and evil in his world,
                                        coming to the Light Realm for domination and acting as the
                                        main antagonist of Season 3. */}
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="px-4 d-flex align-items-center justify-content-center">
              {eventDTO.eventThumbnailsList &&
                eventDTO.eventThumbnailsList.map((dto) => {
                  return (
                    <div className="px-2">
                      <img
                        src={`${dto.thumbnailUrl}`}
                        style={{ maxHeight: '300px' }}
                        className="img-fluid border border-darkGray"
                        alt="artist 1"
                      />
                    </div>
                  );
                })}
            </div>
            <div className="justify-content-center d-flex d-md-none">
              <Nav className="flex-row pt-4 justify-content-center">
                <Nav.Link href="#" className="px-1">
                  <img src="/twitter_icon.svg" alt="twitter logo" />
                </Nav.Link>
                <Nav.Link href="#" className="px-1">
                  <img src="/facebook.svg" alt="facebook logo" />
                </Nav.Link>
                <Nav.Link href="#" className="px-1">
                  <img src="/youtube.svg" alt="youtube logo" />
                </Nav.Link>
                <Nav.Link href="#" className="px-1">
                  <img src="/instagram.svg" alt="instagram logo" />
                </Nav.Link>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row className="py-5">
          {eventDTO.externalVideoUrl && (
            <Col xs={12} className="mb-5">
              <div className="px-5">
                <h4 className="mb-3 fw-bold">Watch Trailer/Promo</h4>
                <iframe
                  width="100%"
                  height="500"
                  src={eventDTO.externalVideoUrl}
                ></iframe>
              </div>
            </Col>
          )}
          <Col lg={6}>
            <div className="px-5">
              <h4 className="fw-bold">FOLLOW THE ARTIST. </h4>
              <h6 className="fw-bold">BE PART OF SOMETHING MORE</h6>
              <p>
                See what they’re up to off stage and on spare time. It’s like
                the ultimate free back stage pass to your favourite bands!{' '}
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="px-5 text-center">
              <Nav className="flex-row justify-content-center">
                {eventDTO.socialMediaLinksModel.twitter && (
                  <Nav.Link
                    href={`https://www.twitter.com/${eventDTO.socialMediaLinksModel.twitter}`}
                    className="px-3"
                  >
                    <img
                      width="70"
                      height="70"
                      src="/twitter_icon.svg"
                      alt="twitter logo"
                    />
                  </Nav.Link>
                )}
                {eventDTO.socialMediaLinksModel.facebook && (
                  <Nav.Link
                    href={`https://www.facebook.com/${eventDTO.socialMediaLinksModel.facebook}`}
                    className="px-3"
                  >
                    <img
                      width="70"
                      height="70"
                      src="/facebook.svg"
                      alt="facebook logo"
                    />
                  </Nav.Link>
                )}

                {eventDTO.socialMediaLinksModel.instagram && (
                  <Nav.Link
                    href={`https://www.instagram.com/${eventDTO.socialMediaLinksModel.instagram}`}
                    className="px-3"
                  >
                    <img
                      width="70"
                      height="70"
                      src="/instagram.svg"
                      alt="instagram logo"
                    />
                  </Nav.Link>
                )}
              </Nav>
              <p className="mt-4">
                Support your artists and follow them on social media.
              </p>
            </div>
          </Col>
          <Col xs="12">
            <div className="px-5">
              <h4 className="mb-4 fw-bold">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="lg"
                  className="text-primary me-2"
                />
                Location
              </h4>
              <p className="fw-bold">National Event Venue & Banquet Hall</p>
              <p>1000 Finch Avenue West Toronto, ON M3J 2V5</p>

              <Button
                onClick={() => setShowMap(!showMap)}
                variant="link"
                className="text-default text-decoration-none"
              >
                <FontAwesomeIcon
                  icon={showMap ? faChevronDown : faChevronUp}
                  className="me-2"
                />
                {showMap ? 'Hide' : 'Show'} the map
              </Button>

              {showMap && (
                <div
                  style={{ height: '400px', width: '600px' }}
                  className="my-4"
                >
                  <MapContainer
                    center={markerPosition}
                    zoom={markerPosition ? 14 : 2}
                    style={{ height: '400px', width: '800px' }}
                  >
                    <Centerer center={markerPosition} />
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker
                      position={markerPosition}
                      icon={customIcon}
                      draggable={true}
                    >
                      <Popup>A marker indicating the location.</Popup>
                    </Marker>
                  </MapContainer>
                </div>
              )}
            </div>
          </Col>
        </Row>

        <div className="mt-5">
          <Footer></Footer>
        </div>
      </Container>

      <Modal
        size="xl"
        show={showModal}
        onExit={() => resetOrderState()}
        backdrop="static"
        keyboard={false}
        dialogClassName={'checkout-modal'}
      >
        <Modal.Body className="p-0">
          <Row className="g-0">
            {(() => {
              if (orderLoading) {
                return (
                  <div className="p-5 d-flex align-items-center justify-content-center">
                    <h6 className="me-2">Loading Details, Please wait...</h6>
                    <Spinner animation="border" variant="secondary" />
                  </div>
                );
              } else {
                const isStreamEvent =
                  eventDTO.locationTypeModel.type != 'In Person';

                if (showTerms) {
                  return (
                    <Col xs={12}>
                      <div className="p-5">
                        <div className="d-flex">
                          <Button
                            onClick={() => setShowTerms(false)}
                            variant="link"
                            className="me-2 ms-auto text-dark text-decoration-none"
                          >
                            <FontAwesomeIcon icon={faClose} size="2x" />
                          </Button>
                        </div>

                        <TermsAndConditions />
                      </div>
                    </Col>
                  );
                } else {
                  return (
                    <>
                      <Col lg={8} xs={12} className="border-end">
                        <>
                          <Col
                            xs={12}
                            className="d-lg-none pb-3 border-bottom border-light"
                          >
                            <div>
                              <div className="d-flex">
                                <Button
                                  onClick={() => {
                                    setShowModal(false);
                                    setOrderStep('');
                                  }}
                                  variant="link"
                                  className="ms-auto text-decoration-none text-white"
                                >
                                  <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    size="2x"
                                  />
                                </Button>
                              </div>

                              <div className="text-center">
                                <h2 className="fw-bold">
                                  {eventDTO.eventTitle}
                                </h2>
                                <h4 className="fw-bold">
                                  <Link to={'promoter-dashboard'}>By {eventDTO.promoter}</Link>
                                </h4>
                                <h6>
                                  {moment(
                                    eventDTO.eventStartDate +
                                      ' ' +
                                      eventDTO.eventStartTime
                                  ).format('ddd MMM DD, YYYY hh:mm A')}{' '}
                                  {eventDTO.timezone}
                                </h6>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} className="mt-4">
                            <ProgressStepper
                              steps={ORDER_STEP_NAMES}
                              activeStep={orderStep}
                            />
                          </Col>
                          {orderStep == ORDER_STEP.COUPON_DETAILS && (
                            <div className="py-3">
                              {!isStreamEvent && (
                                <>
                                  <div className="modal-scroll">
                                    {eventDTO.eventTicketDetailsModel.inPersonTicketSectionDetailsList.map(
                                      (sectionDTO, i) => {
                                        return (
                                          <Ticket
                                            ticketSectionDTO={sectionDTO}
                                            handleTicketSelection={
                                              handleTicketSelection
                                            }
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                  <hr className="my-5 d-none d-lg-block" />

                                  <div className="px-5 d-none d-lg-block">
                                    <h4 className="mb-4">Venue Layout</h4>
                                    <div className="text-center">
                                      <img
                                        className="img-fluid"
                                        src="/venue_layout.png"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              <>
                                {isStreamEvent && (
                                  <div className="d-flex mx-5">
                                    <div className="flex-grow-1">
                                      <h6 className="mb-2">
                                        General Admission
                                      </h6>
                                      <h6>
                                        CA $
                                        {eventDTO.eventTicketDetailsModel.price}
                                      </h6>
                                      <p>
                                        Sale ends on{' '}
                                        {moment(
                                          eventDTO.eventTicketDetailsModel
                                            .salesEndDate
                                        ).format('ddd MMM DD, YYYY hh:mm A')}
                                      </p>
                                    </div>
                                    <div className="ms-auto">
                                      <Form.Select disabled>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                      </Form.Select>
                                    </div>
                                  </div>
                                )}
                                <hr className="my-5 d-none d-lg-block" />
                              </>
                            </div>
                          )}

                          {orderStep == ORDER_STEP.CART && (
                            <div className="p-lg-5 mx-lg-5 p-3 mx-3">
                              <div className="d-flex">
                                <h5>Cart Summary</h5>
                                <h5 className="text-primary ms-auto">
                                  <img
                                    className="me-2"
                                    src="/clock-icon.svg"
                                    alt="clock icon"
                                  />
                                  Time Left{' '}
                                  <Counter
                                    startTime={
                                      eventDTO.eventTicketDetailsModel
                                        .salesStartTimeUTC
                                    }
                                    endTime={eventDTO.eventStartDateTimeUTC}
                                  />
                                </h5>
                              </div>
                              <div className="border p-4 bg-light text-dark">
                                <h4 className="mb-4">{eventDTO.eventTitle}</h4>
                                <div className="ms-4">
                                  <Row className="mb-2">
                                    <Col className="flex-grow-0 pe-0">
                                      <img
                                        src="/clock-icon.svg"
                                        alt="clock icon"
                                      />
                                    </Col>
                                    <Col className="flex-grow-1">
                                      {moment(
                                        eventDTO.eventStartDate +
                                          ' ' +
                                          eventDTO.eventStartTime
                                      ).format('ddd MMM DD, YYYY hh:mm A') +
                                        ' ' +
                                        eventDTO.timezone}
                                    </Col>
                                  </Row>

                                  {!isStreamEvent && (
                                    <Row>
                                      <Col className="flex-grow-0 pe-0">
                                        <img
                                          src="/map-marker-icon.svg"
                                          alt="map marker icon"
                                        />
                                      </Col>
                                      <Col className="flex-grow-1">
                                        {eventDTO.eventAddressDetailsDto
                                          .addressLine1 +
                                          ' ' +
                                          eventDTO.eventAddressDetailsDto
                                            .addressLine2 +
                                          ', ' +
                                          eventDTO.eventAddressDetailsDto.city +
                                          ' ' +
                                          eventDTO.eventAddressDetailsDto
                                            .province +
                                          ' ' +
                                          eventDTO.eventAddressDetailsDto
                                            .postalCode}
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              </div>
                              {!isStreamEvent && (
                                <>
                                  <div className="border p-4">
                                    {eventDTO.eventTicketDetailsModel.inPersonTicketSectionDetailsList.map(
                                      (sectionDTO, i) => {
                                        return sectionDTO.inPersonTicketSectionTypeDetailsDtoList.map(
                                          (ticketTypeDTO, j) => {
                                            let {
                                              price,
                                              serviceFees,
                                              selectedQuantity,
                                              ticketType,
                                            } = ticketTypeDTO;
                                            if (selectedQuantity) {
                                              return (
                                                <div
                                                  key={j}
                                                  className="my-3 bg-light text-dark rounded"
                                                >
                                                  <Row className="p-3 mb-3">
                                                    <Col className="flex-grow-0">
                                                      <img
                                                        src="/ticket-icon.svg"
                                                        alt="ticket icon"
                                                      />
                                                    </Col>
                                                    <Col>
                                                      <h6>
                                                        {ticketType} (
                                                        {sectionDTO.sectionName}
                                                        )
                                                      </h6>
                                                      <strong>
                                                        Quantity:{' '}
                                                      </strong>
                                                      <span>
                                                        {selectedQuantity}
                                                      </span>
                                                    </Col>
                                                    <Col className="mt-3">
                                                      CA $
                                                      {(price +
                                                        (serviceFees
                                                          ? serviceFees
                                                          : 0)) *
                                                        parseInt(
                                                          selectedQuantity
                                                        )}
                                                    </Col>
                                                  </Row>
                                                </div>
                                              );
                                            }
                                          }
                                        );
                                      }
                                    )}
                                  </div>
                                  <Button
                                    onClick={() =>
                                      setOrderStep(ORDER_STEP.COUPON_DETAILS)
                                    }
                                    className="text-white mt-3 rounded"
                                  >
                                    Buy More Ticket
                                  </Button>
                                </>
                              )}

                              {isStreamEvent && (
                                <div className="border p-4">
                                  <div className="my-3 bg-light text-dark rounded">
                                    <Row className="p-3 mb-3">
                                      <Col className="flex-grow-0">
                                        <img
                                          src="/ticket-icon.svg"
                                          alt="ticket icon"
                                        />
                                      </Col>
                                      <Col>
                                        <h6>Live Stream</h6>
                                        <strong>Quantity: </strong>
                                        <span>1</span>
                                      </Col>
                                      <Col className="mt-3">
                                        CA $
                                        {eventDTO.eventTicketDetailsModel.price}
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {orderStep == ORDER_STEP.BILLING_DETAILS && (
                            <div>
                              <div className="modal-scroll">
                                <div className="px-3 mx-3 px-lg-5 mx-lg-5  my-4 ">
                                  <Button
                                    onClick={() =>
                                      setOrderStep(ORDER_STEP.COUPON_DETAILS)
                                    }
                                    variant="link"
                                    className="text-decoration-none px-0 mb-2"
                                  >
                                    <FontAwesomeIcon
                                      icon={faChevronLeft}
                                      className="me-2"
                                    />
                                    Back
                                  </Button>
                                  <h4 className="mb-4">Billing Information</h4>
                                  <div className="d-flex mb-3">
                                    <Form.Group
                                      controlId="firstName"
                                      className="me-3"
                                    >
                                      <Form.Label className="required">
                                        First Name
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="first name"
                                        value={billingDetails.firstName}
                                        onChange={(e) =>
                                          setBillingDetails({
                                            ...billingDetails,
                                            firstName: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group controlId="lastName">
                                      <Form.Label className="required">
                                        Last Name
                                      </Form.Label>
                                      <Form.Control
                                        placeholder="last name"
                                        type="text"
                                        value={billingDetails.lastName}
                                        onChange={(e) =>
                                          setBillingDetails({
                                            ...billingDetails,
                                            lastName: e.target.value,
                                          })
                                        }
                                      />
                                    </Form.Group>
                                  </div>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="required">
                                      Email Address
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="email"
                                      type="text"
                                      value={billingDetails.email}
                                      onChange={(e) =>
                                        setBillingDetails({
                                          ...billingDetails,
                                          email: e.target.value,
                                        })
                                      }
                                    />
                                  </Form.Group>

                                  <Form.Group
                                    controlId="addressLine1"
                                    className="mb-3"
                                  >
                                    <Form.Label>Address 1</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="address1"
                                      value={
                                        billingDetails.orderBillingAddressDto
                                          .addressLine1
                                      }
                                      onChange={(e) =>
                                        updateBillingDetails(
                                          'addressLine1',
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Form.Group>

                                  <Form.Group
                                    controlId="addressLine2"
                                    className="mb-3"
                                  >
                                    <Form.Label>Address 2</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="address2"
                                      value={
                                        billingDetails.orderBillingAddressDto
                                          .addressLine2
                                      }
                                      onChange={(e) =>
                                        updateBillingDetails(
                                          'addressLine2',
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Form.Group>

                                  <div className="d-flex align-items-center mb-3">
                                    <Form.Group
                                      controlId="city"
                                      className="mb-3 me-4 flex-grow-1"
                                    >
                                      <Form.Label className="required">
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="city"
                                        value={
                                          billingDetails.orderBillingAddressDto
                                            .city
                                        }
                                        onChange={(e) =>
                                          updateBillingDetails(
                                            'city',
                                            e.target.value
                                          )
                                        }
                                      />
                                      {/* {errors && errors[ADDRESS_TYPES.PRINCIPAL] && errors[ADDRESS_TYPES.PRINCIPAL].city &&
                                                                                    <Form.Text className="text-danger">
                                                                                        <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                                                                        {errors[ADDRESS_TYPES.PRINCIPAL].city}
                                                                                    </Form.Text>
                                                                                } */}
                                    </Form.Group>
                                    <Form.Group
                                      controlId="country"
                                      className="mb-3 flex-grow-1"
                                    >
                                      <Form.Label>Country</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="country"
                                        value={
                                          billingDetails.orderBillingAddressDto
                                            .country
                                        }
                                        onChange={(e) =>
                                          updateBillingDetails(
                                            'country',
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </div>

                                  <div className="d-flex align-items-center">
                                    <Form.Group
                                      controlId="postalCode"
                                      className="me-4 flex-grow-1"
                                    >
                                      <Form.Label className="required">
                                        Zip/Postal Code
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="zip/postal code"
                                        value={
                                          billingDetails.orderBillingAddressDto
                                            .postalCode
                                        }
                                        onChange={(e) =>
                                          updateBillingDetails(
                                            'postalCode',
                                            e.target.value
                                          )
                                        }
                                      />
                                      {/* {errors && errors[ADDRESS_TYPES.PRINCIPAL] && errors[ADDRESS_TYPES.PRINCIPAL].postalCode &&
                                                                                        <Form.Text className="text-danger">
                                                                                            <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                                                                                            {errors[ADDRESS_TYPES.PRINCIPAL].postalCode}
                                                                                        </Form.Text>
                                                                                    } */}
                                    </Form.Group>

                                    <Form.Group
                                      controlId="province"
                                      className="flex-grow-1"
                                    >
                                      <Form.Label>Province:</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="province"
                                        value={
                                          billingDetails.orderBillingAddressDto
                                            .province
                                        }
                                        onChange={(e) =>
                                          updateBillingDetails(
                                            'province',
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  </div>

                                  <div className="d-flex">
                                    <Form.Check
                                      type="checkbox"
                                      name="setAsDefaultAddress"
                                      label="Set as default Address"
                                      className="mt-3 ms-auto"
                                      checked={
                                        billingDetails.setAsDefaultAddress
                                      }
                                      onChange={(e) =>
                                        setBillingDetails({
                                          ...billingDetails,
                                          setAsDefaultAddress: e.target.checked,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {orderStep == ORDER_STEP.CHECKOUT && (
                            <div>
                              <h3 className="fw-bold p-4 text-center border-bottom">
                                Payment
                              </h3>
                              <div className="modal-scroll">
                                <div className="px-3 mx-3 px-lg-5 mx-lg-5  my-4 ">
                                  <Button
                                    onClick={() =>
                                      setOrderStep(ORDER_STEP.BILLING_DETAILS)
                                    }
                                    variant="link"
                                    className="text-decoration-none px-0 mb-2"
                                  >
                                    <FontAwesomeIcon
                                      icon={faChevronLeft}
                                      className="me-2"
                                    />
                                    Back
                                  </Button>

                                  <h4 className="my-3">Payment Methods</h4>
                                  {paymentMethodList &&
                                    paymentMethodList.length > 0 && (
                                      <div>
                                        <h6 className="mb-3">
                                          Saved ({paymentMethodList.length}){' '}
                                        </h6>
                                        <div className="overflow-y-auto">
                                          {paymentMethodList.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  className="d-flex align-items-center border border-gray rounded p-2 mb-3"
                                                >
                                                  <img
                                                    src={`/${GET_CARD_LOGOS(
                                                      item.cardBrand
                                                    )}`}
                                                  />
                                                  <div className="ms-2">
                                                    <h6 className="mb-0">
                                                      **** **** ****{' '}
                                                      {item.lastFourDigit}
                                                    </h6>
                                                    <small>
                                                      expires{' '}
                                                      {item.expMonth < 10
                                                        ? '0'
                                                        : ''}
                                                      {item.expMonth}/
                                                      {item.expYear}
                                                    </small>
                                                  </div>
                                                  <Button
                                                    className="text-white ms-auto"
                                                    onClick={() =>
                                                      payWithExisting(
                                                        item.paymentMethodId
                                                      )
                                                    }
                                                  >
                                                    Place Order
                                                  </Button>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    )}

                                  <div>
                                    <Button
                                      onClick={() => setNewPayment(true)}
                                      className="text-white my-3"
                                    >
                                      Add New payment detail
                                    </Button>

                                    {(newPayment ||
                                      paymentMethodList.length == 0) && (
                                      <div className="border border-darkGray rounded p-3">
                                        <AddNewPayment
                                          data={{
                                            eventId: eventDTO.id,
                                            price:
                                              eventDTO.eventTicketDetailsModel
                                                .price || 0,
                                            couponId: appliedCoupon
                                              ? appliedCoupon.couponId
                                              : '63eabe926e3235568165b6cc', // hard coded
                                            receiptEmailId:
                                              billingDetails.email,
                                            orderId: orderId,
                                            duration: 0,
                                            inPersonTicketTypeOrderDetailsDtoList:
                                              getInPersonTicketTypeOrderDetailsDtoList(),
                                          }}
                                          subscription={subscription}
                                          handlePaymentCallback={
                                            handlePaymentCallback
                                          }
                                        />
                                      </div>
                                    )}

                                    {!newPayment && payWithExistingElement && (
                                      <PayThroughExisting
                                        data={{
                                          paymentMethodId:
                                            selectedPaymentMethodId,
                                          eventId: eventDTO.id,
                                          price:
                                            eventDTO.eventTicketDetailsModel
                                              .price || 0,
                                          couponId: appliedCoupon
                                            ? appliedCoupon.couponId
                                            : '63eabe926e3235568165b6cc',
                                          receiptEmailId: billingDetails.email,
                                          orderId: orderId,
                                          duration: 0,
                                          inPersonTicketTypeOrderDetailsDtoList:
                                            getInPersonTicketTypeOrderDetailsDtoList(),
                                        }}
                                        subscription={subscription}
                                        handlePaymentCallback={
                                          handlePaymentCallback
                                        }
                                      />
                                    )}
                                  </div>

                                  {/* Start from here - Stripe */}
                                  <p className="mt-3">
                                    <FontAwesomeIcon
                                      icon={faLock}
                                      className="me-2"
                                    />
                                    Your payment is secure
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}

                          {orderStep == ORDER_STEP.CONFIRMED && (
                            <PaymentStatus
                              success={true}
                              msg={'Your payment is successful'}
                              handleTryAgain={() => {
                                setPaymentStatus(initialOrderStatus);
                                setShowModal(true);
                              }}
                              handlePreviewEvent={() => {
                                setPaymentStatus(initialOrderStatus);
                              }}
                              handleShareWithFriends={() => {
                                setPaymentStatus(initialOrderStatus);
                                setShowShareModal(true);
                              }}
                              handleAddToCalendar={addEventToGoogleCalendar}
                              eventDTO={eventDTO}
                            />
                          )}
                          {orderStep == ORDER_STEP.CONFIRMED && (
                            <footer className="bg-dark mb-5 py-4">
                              <Row>
                                <Col xs="12" className="d-flex">
                                  <Navbar.Brand
                                    className="m-auto logo"
                                    to="home"
                                  >
                                    {' '}
                                  </Navbar.Brand>
                                </Col>
                              </Row>
                            </footer>
                          )}
                        </>
                      </Col>
                      {orderStep == ORDER_STEP.CONFIRMED && (
                        <Col
                          lg={4}
                          xs={12}
                          className={`p-0 d-none d-lg-flex align-items-start bg-green-grey text-dark`}
                        >
                          <Button
                            onClick={() => {
                              setShowModal(false);
                              setOrderStep('');
                            }}
                            variant="link"
                            className="me-2 ms-auto text-decoration-none"
                          >
                            <FontAwesomeIcon icon={faClose} size="2x" />
                          </Button>
                        </Col>
                      )}

                      {orderStep != ORDER_STEP.CONFIRMED && (
                        <Col
                          lg={4}
                          xs={12}
                          className={`p-lg-0 px-5 bg-green-grey text-dark `}
                        >
                          <div
                            className="homebanner d-none d-lg-flex"
                            style={{
                              backgroundImage: `url(${eventDTO.bannerThumbnailS3IdList[0].thumbnailUrl})`,
                              height: '200px',
                            }}
                          >
                            <Button
                              onClick={() => {
                                setShowModal(false);
                                setOrderStep('');
                              }}
                              variant="link"
                              className="me-2 ms-auto text-white text-decoration-none"
                            >
                              <FontAwesomeIcon icon={faClose} size="2x" />
                            </Button>
                            <h3 className="text-center mt-3 text-white">
                              {eventDTO.eventTitle}
                            </h3>
                          </div>
                          <div className="px-lg-4 py-4 px-2">
                            <h4 className="mb-4">Order Summary</h4>

                            {isStreamEvent && (
                              <>
                                <div className="d-flex">
                                  <p>1 x General Admission</p>
                                  <span className="ms-auto">
                                    CA ${eventDTO.eventTicketDetailsModel.price}
                                  </span>
                                </div>

                                <div className="d-flex">
                                  <p>Fee</p>
                                  <span className="ms-auto">
                                    CA $
                                    {eventDTO.eventTicketDetailsModel.price *
                                      SERVICE_FEE_PERCENT}
                                  </span>
                                </div>

                                {!eventDTO.eventTicketDetailsModel
                                  .chargeTax && (
                                  <div className="d-flex">
                                    <p>Tax</p>
                                    <span className="ms-auto">
                                      CA $
                                      {(
                                        eventDTO.eventTicketDetailsModel.price *
                                        TAX_PERCENTAGE
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                )}

                                <hr />

                                <div className="d-flex">
                                  <p>Subtotal</p>
                                  <span className="ms-auto">
                                    CA ${eventDTO.eventTicketDetailsModel.price}
                                  </span>
                                </div>

                                {appliedCoupon && (
                                  <div className="d-flex">
                                    <p>
                                      Discount <br />
                                      <small className="text-darkGray">
                                        (Coupon Applied)
                                      </small>
                                    </p>
                                    <span className="ms-auto">
                                      - CA $
                                      {appliedCoupon.couponType == 'FIXED'
                                        ? appliedCoupon.couponDiscount
                                        : eventDTO.eventTicketDetailsModel
                                            .price *
                                          (appliedCoupon.couponDiscount / 100)}
                                    </span>
                                  </div>
                                )}

                                <div className="d-flex">
                                  <p>Grand Total</p>
                                  <span className="ms-auto">
                                    CA $
                                    {getTotalPrice(
                                      eventDTO.eventTicketDetailsModel
                                    )}
                                  </span>
                                </div>
                              </>
                            )}

                            {!isStreamEvent && (
                              <>
                                {(() => {
                                  let subtotal = 0,
                                    coupon = 0;
                                  return (
                                    <>
                                      {eventDTO.eventTicketDetailsModel.inPersonTicketSectionDetailsList.map(
                                        (ticketSectionDTO) => {
                                          return (
                                            <div>
                                              {ticketSectionDTO.inPersonTicketSectionTypeDetailsDtoList.map(
                                                (ticketTypeDTO) => {
                                                  let {
                                                    selectedQuantity,
                                                    ticketType,
                                                    price,
                                                    serviceFees,
                                                  } = ticketTypeDTO;
                                                  if (selectedQuantity) {
                                                    subtotal +=
                                                      (price +
                                                        (serviceFees
                                                          ? serviceFees
                                                          : 0)) *
                                                      parseInt(
                                                        selectedQuantity
                                                      );
                                                    return (
                                                      <div className="d-flex">
                                                        <p>
                                                          {selectedQuantity} x{' '}
                                                          {ticketType} (
                                                          {
                                                            ticketSectionDTO.sectionName
                                                          }
                                                          )
                                                        </p>
                                                        <span className="ms-auto">
                                                          CA $
                                                          {(price +
                                                            (serviceFees
                                                              ? serviceFees
                                                              : 0)) *
                                                            parseInt(
                                                              selectedQuantity
                                                            )}
                                                        </span>
                                                      </div>
                                                    );
                                                  }
                                                }
                                              )}
                                            </div>
                                          );
                                        }
                                      )}

                                      <hr />

                                      <div className="d-flex">
                                        <p>Subtotal</p>
                                        <span className="ms-auto">
                                          CA ${subtotal}
                                        </span>
                                      </div>

                                      {(() => {
                                        if (appliedCoupon) {
                                          coupon =
                                            appliedCoupon.couponType == 'FIXED'
                                              ? appliedCoupon.couponDiscount
                                              : subtotal *
                                                (appliedCoupon.couponDiscount /
                                                  100);

                                          return (
                                            <div className="d-flex">
                                              <p>
                                                Discount <br />
                                                <small className="text-darkGray">
                                                  (Coupon Applied)
                                                </small>
                                              </p>
                                              <span className="ms-auto">
                                                - CA $ {coupon}
                                              </span>
                                            </div>
                                          );
                                        }
                                      })()}

                                      {/* {appliedCoupon &&
                                                                                coupon = appliedCoupon.couponType == 'FIXED' ? appliedCoupon.couponDiscount :
                                                                                            subtotal * (appliedCoupon.couponDiscount / 100)
                                                                                <div className='d-flex'>
                                                                                    <p>
                                                                                        Discount <br/>
                                                                                        <small className='text-darkGray'>(Coupon Applied)</small>
                                                                                    </p>
                                                                                    <span className='ms-auto'>
                                                                                        - CA $
                                                                                        {appliedCoupon.couponType == 'FIXED' ? appliedCoupon.couponDiscount :
                                                                                            subtotal * (appliedCoupon.couponDiscount / 100)}
                                                                                    </span>
                                                                                </div>
                                                                            } */}

                                      <div className="d-flex">
                                        <p>Grand Total</p>
                                        <span className="ms-auto">
                                          CA ${subtotal - coupon}
                                        </span>
                                      </div>
                                    </>
                                  );
                                })()}
                              </>
                            )}

                            <hr className="my-4" />

                            <div>
                              <Form.Group
                                controlId="promoCode"
                                className="d-flex align-items-center"
                              >
                                <Form.Label className="me-2 mb-0 d-flex fw-bold">
                                  <LabelIcon />
                                  Enter Promo code
                                </Form.Label>
                                <Form.Control
                                  value={couponCode}
                                  onChange={(e) =>
                                    setCouponCode(e.target.value)
                                  }
                                  className="w-50"
                                  type="text"
                                  placeholder="Promo Code"
                                  disabled={appliedCoupon}
                                />

                                {!appliedCoupon && (
                                  <Button
                                    onClick={() =>
                                      getCouponDetailsAndValidate(
                                        eventDTO.id,
                                        couponCode
                                      )
                                    }
                                    variant="link"
                                    className="text-decoration-none text-secondary"
                                  >
                                    Apply
                                  </Button>
                                )}

                                {appliedCoupon && (
                                  <Button
                                    onClick={() => {
                                      setAppliedCoupon(null);
                                      setCouponCode('');
                                    }}
                                    variant="link"
                                    className="text-decoration-none text-danger"
                                  >
                                    Remove
                                  </Button>
                                )}

                                {showCouponToast && (
                                  <Form.Text className="text-success">
                                    {appliedCoupon
                                      ? 'Coupon applied successfully!'
                                      : 'Coupon removed'}
                                  </Form.Text>
                                )}

                                {couponError && (
                                  <Form.Text className="text-danger">
                                    {couponError}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </div>

                            {orderStep == ORDER_STEP.COUPON_DETAILS && (
                              <div className="mt-4 text-center">
                                <Button
                                  disabled={disableGetTicketsButton()}
                                  onClick={() => setOrderStep(ORDER_STEP.CART)}
                                  className="text-white px-5 w-100 border-primary"
                                  variant="primary"
                                >
                                  Get Tickets
                                </Button>

                                <Button
                                  className="text-dark px-5 w-100 bg-white mt-3 border-dark"
                                  variant="outlined"
                                >
                                  Cancel
                                </Button>
                              </div>
                            )}
                            {orderStep == ORDER_STEP.CART && (
                              <div className="mt-4 text-center">
                                <Button
                                  disabled={disableGetTicketsButton()}
                                  onClick={() =>
                                    setOrderStep(ORDER_STEP.BILLING_DETAILS)
                                  }
                                  className="text-white px-5 w-100"
                                  variant="primary"
                                >
                                  Checkout
                                </Button>

                                <Button
                                  className="text-dark px-5 w-100 bg-white mt-3 border-dark"
                                  variant="outlined"
                                >
                                  Cancel
                                </Button>
                              </div>
                            )}
                            {orderStep == ORDER_STEP.BILLING_DETAILS && (
                              <div className="mt-4 text-center">
                                {/* <Button disabled={disableGetTicketsButton()} onClick={checkout}
                                                                        className='text-white px-5 w-100' variant='primary'>
                                                                    Place Order
                                                                </Button> */}
                                {/* TODO: // the above code is not working so it is bypassed for now */}
                                <Button
                                  className="text-white ms-auto w-100"
                                  onClick={() =>
                                    setOrderStep(ORDER_STEP.CONFIRMED)
                                  }
                                >
                                  Place Order
                                </Button>

                                <Button
                                  className="text-dark px-5 w-100 bg-white mt-3 border-dark"
                                  variant="outlined"
                                >
                                  Cancel
                                </Button>
                              </div>
                            )}

                            <Form.Group className="d-flex mt-4">
                              <Form.Check
                                className="me-2"
                                type="checkbox"
                                id="acceptTerms"
                                checked={termsAccepted}
                                onChange={(e) =>
                                  setTermsAccepted(e.target.checked)
                                }
                              />
                              <Form.Label htmlFor="acceptTerms">
                                <p className="mb-0 required">
                                  By clicking here, I state that I have read and
                                  understood the
                                  <Button
                                    onClick={() => setShowTerms(true)}
                                    variant="link"
                                    className="text-decoration-none p-0 text-secondary ms-1"
                                  >
                                    terms and conditions.
                                  </Button>
                                </p>
                              </Form.Label>
                            </Form.Group>
                          </div>
                        </Col>
                      )}
                    </>
                  );
                }
              }
            })()}
            {/* {orderLoading &&
                                <div className="p-5 d-flex align-items-center justify-content-center">
                                    <h6 className="me-2">Loading Details, Please wait...</h6>
                                    <Spinner animation="border" variant="secondary" />
                                </div>
                            } */}
            {/* {!orderLoading && showTerms &&
                                <Col xs={12}>
                                    <div className='p-5'>
                                        <div className='d-flex'>
                                            <Button
                                                onClick={() => setShowTerms(false)}
                                                variant='link'
                                                className='me-2 ms-auto text-dark text-decoration-none'>
                                                <FontAwesomeIcon icon={faClose} size="2x" />
                                            </Button>
                                        </div>

                                        <TermsAndConditions />
                                    </div>

                                </Col>

                            } */}
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={showShareModal}
        onHide={() => setShowShareModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareEvent name={eventDTO.eventTitle} url={window.location.href} />
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={paymentStatus.show}
        onHide={() => setPaymentStatus(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <PaymentStatus
            success={paymentStatus.success}
            msg={paymentStatus.msg}
            handleTryAgain={() => {
              setPaymentStatus(initialOrderStatus);
              setShowModal(true);
            }}
            handlePreviewEvent={() => {
              setPaymentStatus(initialOrderStatus);
            }}
            handleShareWithFriends={() => {
              setPaymentStatus(initialOrderStatus);
              setShowShareModal(true);
            }}
            handleAddToCalendar={addEventToGoogleCalendar}
            eventDTO={eventDTO}
          />
        </Modal.Body>
      </Modal>

      <ToastMessage
        {...toast}
        onClose={() => setToast({ ...toast, show: false })}
      />
    </div>
  );
};

export default PreviewEvent;
