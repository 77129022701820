import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { payWithExistingMethod } from '../utils/APIUtils';
import { STRIPE_PUBLISH_KEY } from '../constants';

import AddNewPaymentElement from './AddNewPaymentElement';
//import "./App.css";

/**
 * Load Stripe with the publishable API key.
 * Call it outside of your React component to avoid recreating the Stripe object on every render.
 *
 * @return {Promise<Stripe>} A promise that resolves to a Stripe object.
 */
const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);

/**
 * A React component for adding a new payment.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.subscription - Flag indicating if the payment is for a subscription.
 * @return {JSX.Element} The rendered component.
 */
export default function AddNewPayment(props) {
  /**
   * State hook to hold the client secret.
   * @type {React.State<string>}
   */
  const [clientSecret, setClientSecret] = useState('');
  /**
   * State hook to hold the subscription flag.
   * @type {React.State<boolean>}
   */
  const [subscription, setSubscription] = useState(false);

  /**
   * useEffect hook that runs after the component has been rendered.
   * It sets the subscription state to the value of the subscription prop.
   * And logs the value of the subscription prop and the STRIPE_PUBLISH_KEY constant.
   *
   * @return {void}
   */
  useEffect(() => {
    console.log('Flag : ', props.subscription);
    console.log('Publishable Key : ' + STRIPE_PUBLISH_KEY);
    setSubscription(props.subscription);
    // Create PaymentIntent as soon as the page loads
    //   var body = JSON.stringify( {"eventId":props.data.eventId,
    //   "price":"200",
    //   "receiptEmailId":"ayushsingh9200@gmail.com",
    //   "couponId":"63eabe926e3235568165b6cc"
    //   //"paymentMethodId":"pm_1Ma1uwB89kt0i5FqwOf9Uimm" //payment method when we have already saved card details
    //   //"duration":"3" //duration in months - upto what time subscription will go on
    // });

    let body = JSON.stringify(props.data);
    payWithExistingMethod(body)
      .then((res) => {
        setClientSecret(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // axios.post(BASE_URL + '/payment', body, { headers: AUTH_HEADERS })
    //   .then((res) =>  {
    //     setClientSecret(res.data);
    // })
  }, []);

  /**
   * The appearance object for the Stripe payment form.
   * @type {Object}
   * @property {string} theme - The theme of the payment form.
   */
  const appearance = {
    theme: 'stripe',
  };

  /**
   * The options object for the Stripe payment form.
   * @type {Object}
   * @property {string} clientSecret - The client secret for the payment intent.
   * @property {Object} appearance - The appearance object for the payment form theme.
   */
  const options = {
    clientSecret,
    appearance,
  };

  // "eventId":eventDTO.id,
  // "price": eventDTO.eventTicketDetailsModel.price,
  // "couponId": appliedCoupon ?  appliedCoupon.couponId : '63eabe926e3235568165b6cc',// hard coded
  // "receiptEmailId": billingDetails.email,
  // "orderId": orderId
  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <AddNewPaymentElement
            data={props.data}
            subscription={props.subscription}
            handlePaymentCallback={props.handlePaymentCallback}
          />
        </Elements>
      )}
    </div>
  );
}
