import React from 'react';
import { Form } from 'react-bootstrap';

/**
 * @function ContactOrganizer
 * @description Function represents the contact organizer component
 * @returns {JSX.Element} The JSX element representing the ContactOrganizer component.
 */
const ContactOrganizer = () => {
  return (
    <div>
      <div className="mb-3">
        <Form.Group className="mb-3">
          <Form.Label className="required" htmlFor="contactOrganizerTitle">
            Title
          </Form.Label>
          <Form.Control
            type="text"
            className="rounded-0 px-3 py-2"
            id="contactOrganizerTitle"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            id="contactOrganizerDescription"
            placeholder="Type here..."
            maxLength={500}
            rows={5}
          />
          <Form.Text>Maximum 500 characters</Form.Text>
        </Form.Group>
      </div>
    </div>
  );
};

export default ContactOrganizer;
