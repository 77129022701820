import React, { useState, useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Form,
  Nav,
  Navbar,
  Dropdown,
  Button,
  Modal,
} from 'react-bootstrap';
import { USER_TYPE, USER_ROLES } from '../constants';
import { useAuth } from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { faTimesCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import local storage utilities module.
// const storageUtils = require("../utils/LocalStorageUtils");
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearch } from '@fortawesome/free-solid-svg-icons'

/**
 * React functional component that renders the header module.
 *
 * @param {Object} props - The properties object containing the following properties:
 *   @property {Function} handleLogout - The function to handle user logout.
 * @return {JSX.Element} The rendered header component.
 */
export default function Header(props) {
  const auth = useAuth();
  let navigate = useNavigate();
  const { pathname } = useLocation();
  // const [{ route }] = matchRoutes(routes, location)
  const [show, setShow] = useState(false);
  const [islightTheme, setIsLightTheme] = useState(false);

  /**
   * Closes a modal or dialog.
   *
   * @return {void}
   */
  const handleClose = () => setShow(false);

  /**
   * Shows the component.
   *
   * @return {void}
   */
  const handleShow = () => setShow(true);
  // This function is used to check if the user is admin.
  const isAdmin = React.useMemo(() => {
    console.log('llll', auth.user);
    return auth.user
      ? auth.user.roles.indexOf(USER_ROLES.ROLE_SUPER_ADMIN) > -1
      : false;
  }, [auth.user]);

  /**
   * This function is used to handle the logout event.
   *
   * @return {void}
   */
  const handleLogout = () => {
    props.handleLogout();
    auth.logout();

    setTimeout(() => {
      navigate('/login');
    }, 100);
  };

  /**
   * This useLayoutEffect hook is used to handle the layout effect.
   *
   * @return {void}
   */
  useLayoutEffect(() => {
    if (document.body.classList.contains('theme-light')) {
      islightTheme(true);
    }
  }, []);

  /**
   * Switches the theme.
   *
   * @return {void}
   */

  const switchTheme = () => {
    if (islightTheme) {
      document.body.classList.remove('theme-light');
    } else {
      document.body.classList.add('theme-light');
    }
    setIsLightTheme(!islightTheme);
  };

  /**
   * This function is used to navigate the login page with optional state.
   *
   * @param {boolean} newUser - Indicates if the user is new.
   * @param {string} userType - The type of user.
   * @return {void}
   */

  const navigateToLogin = (newUser, userType) => {
    setShow(false);
    navigate('/login', {
      state: { newUser: newUser, userType: userType },
      replace: true,
    });
  };

  /**
   * Navigates the user to the promoter page.
   *
   * This function checks the current environment and opens the promoter page
   * in a new browser window or tab.
   *
   * @return {void} This function does not return anything.
   */
  const navigateToPromoter = () => {
    if (process.env.NODE_ENV == 'development') {
      window.open('http://localhost:5000', '_blank');
    } else {
      window.open(' https://eventlinx-promoter.pages.dev/', '_blank');
    }
  };

  return (
    <header className="main sticky-top">
      <Navbar variant="dark" collapseOnSelect expand="lg">
        <Container fluid className="justify-content-start px-2 px-lg-5 py-2">
          <Navbar.Brand as={Link} className="m-0 logo" to="home">
            {/* <img className="img-fluid" src="/logo-light.svg" alt="website logo"/> */}
            {/* <h2 className='mb-0'><span className='text-primary'>EVENT</span>LINX</h2>
                        <h4 className='mb-0'>Make A Connection</h4> */}
          </Navbar.Brand>

          <Navbar.Toggle
            className="ms-auto"
            aria-controls="responsive-navbar-nav"
          >
            <span className="navbar-toggler-icon"></span>
            <FontAwesomeIcon
              title="No items in Cart yet"
              icon={faTimesCircle}
              size="2x"
            />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto d-lg-flex align-items-center">
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to="home"
                  className={`fw-bold ${pathname == '/home' ? 'active' : ''}`}
                >
                  Home
                </Nav.Link>
              </Nav.Item>

              {!isAdmin && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to={'events'}
                      className={`fw-bold ${
                        pathname == '/events' ? 'active' : ''
                      }`}
                    >
                      Events
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                                            <Nav.Link className="text-primary fw-bold" eventKey="link-2">Support</Nav.Link>
                                        </Nav.Item> */}
                  {/* <Nav.Item>
                                            <Nav.Link as={Link} to="home" className="fw-bold">Pricing</Nav.Link>
                                        </Nav.Item> */}

                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="about"
                      className={`fw-bold ${
                        pathname == '/about' ? 'active' : ''
                      }`}
                    >
                      About Us
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="contact-us"
                      className={`fw-bold ${
                        pathname == '/contact-us' ? 'active' : ''
                      }`}
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      to="faq"
                      className={`fw-bold ${
                        pathname == '/faq' ? 'active' : ''
                      }`}
                    >
                      FAQ
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="d-lg-none">
                    <Nav.Link
                      as={Link}
                      to="liked-followed"
                      className={`fw-bold ${
                        pathname == '/liked-followed' ? 'active' : ''
                      }`}
                    >
                      Liked & Followed
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item className="d-lg-none ">
                    <div className="d-flex">
                      <Button
                        variant="link"
                        className="text-decoration-none text-primary me-2 fw-bold"
                      >
                        Logout
                      </Button>
                      <Button
                        variant="pill"
                        className="bg-primary text-white rounded-pill d-flex align-items-center"
                      >
                        <FontAwesomeIcon
                          title="No items in Cart yet"
                          icon={faUserCircle}
                          size="2x"
                          className="text-dark me-2"
                        />
                        My Dashboard
                      </Button>
                    </div>
                  </Nav.Item>

                  <Nav.Item className="d-lg-none fw-bold">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Dark Mode"
                      onChange={switchTheme}
                      checked={!islightTheme}
                      className="text-white"
                    />
                  </Nav.Item>
                </>
              )}

              {!auth.user && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => navigateToLogin(false)}
                      className="fw-bold text-primary btn btn-link"
                    >
                      Login
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={handleShow}
                      className="fw-bold border border-primary btn-primary text-white rounded-pill"
                    >
                      Sign Up
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Form.Check
                      className="ms-4"
                      type="switch"
                      id="custom-switch"
                      label="Dark Mode"
                      onChange={switchTheme}
                      checked={!islightTheme}
                    />
                  </Nav.Item>
                  {/*<Nav.Item>
                                                <FontAwesomeIcon title="No items in Cart yet" icon={faCartShopping} className="ms-4" />
                                            </Nav.Item>*/}
                </>
              )}
              {auth.user && (
                <Nav.Item className="d-lg-block d-none">
                  <Dropdown className="user-menu">
                    <Dropdown.Toggle
                      variant="link"
                      className="user-toggle text-decoration-none"
                      id="dropdown-button-dark-example1"
                    >
                      <div className="user">
                        <div className="avatar">
                          {!auth.user.profileImageURL && (
                            <div className="h-100 d-flex align-items-center justify-content-center bg-darkGray">
                              <h4 className="mb-0 text-white">
                                {Array.from(auth.user.firstName)[0]}
                                {Array.from(auth.user.lastName)[0]}
                              </h4>
                            </div>
                          )}
                          {auth.user.profileImageURL && (
                            <img
                              src={auth.user.profileImageURL}
                              alt="profile pic"
                            />
                          )}
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item eventKey="1">
                        <strong>
                          {auth.user.firstName} {auth.user.lastName}
                        </strong>
                        <p className="mb-0">{auth.user.email}</p>
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey="2">Profile</Dropdown.Item> */}
                      <Dropdown.Divider />
                      <Dropdown.Item as="button">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Dark Mode"
                          onChange={switchTheme}
                          checked={!islightTheme}
                        />
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      {/* <Dropdown.Item eventKey="4">Purchases</Dropdown.Item> */}
                      {!isAdmin && (
                        <Dropdown.Item as={Link} to={`/account-settings`}>
                          My Dashboard
                        </Dropdown.Item>
                      )}
                      {/* <Dropdown.Item eventKey="6">Support</Dropdown.Item> */}
                      <Dropdown.Item onClick={handleLogout}>
                        Log out
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to={`/liked-followed`}>
                        Liked & Followed
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        contentClassName="login-box"
      >
        <Modal.Body className="text-center py-5">
          <h2>SIGN UP</h2>
          <h6 className="mb-3"> Welcome to TicketWindow UX</h6>
          <div className="my-4 px-5">
            <Button
              onClick={() => navigateToLogin(true, USER_TYPE.PATRON)}
              size="lg"
              variant="primary"
              className="text-white rounded-0 mb-4 fw-bold w-100"
            >
              I'm a Viewer
            </Button>
            <br />
            <Button
              onClick={() => navigateToPromoter()}
              size="lg"
              variant="primary"
              className="text-white rounded-0 w-100 fw-bold"
            >
              I'm a Producer
            </Button>
          </div>
          <p>
            Already have an account?
            <button
              onClick={() => navigateToLogin(false)}
              type="button"
              className="text-primary fw-bold  text-decoration-none ms-2 btn btn-link p-0 fs-12 mb-1"
            >
              Log In
            </button>
          </p>
        </Modal.Body>
      </Modal>
    </header>
  );
}
