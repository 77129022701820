import { EMAIL_REGEX } from '../constants';

/**
 * Validates the login form input fields.
 *
 * @param {boolean} newUser - Indicates if the user is signing up.
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @param {string} captcha - The captcha response.
 * @param {string} confirmPassword - The confirmation password of the user (optional).
 * @param {string} firstName - The first name of the user (optional).
 * @param {string} lastName - The last name of the user (optional).
 * @return {Object} An object containing validation errors.
 */
const validate = (
  newUser,
  email,
  password,
  captcha,
  confirmPassword,
  firstName,
  lastName
) => {
  /**
   * An object containing validation errors.
   * @typedef {Object} ValidationErrors
   * @property {string} [email] - The validation error for the email field.
   * @property {string} [password] - The validation error for the password field.
   * @property {string} [captcha] - The validation error for the captcha field.
   * @property {string} [confirmPassword] - The validation error for the confirmPassword field.
   * @property {string} [firstName] - The validation error for the firstName field.
   * @property {string} [lastName] - The validation error for the lastName field.
   */

  /**
   * @type {ValidationErrors}
   */
  let errors = {};

  // const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // const PSWRD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;

  // if (!email.trim()) {
  //   errors.email = "Email required";
  // }

  // if (email && !EMAIL_REGEX.test(String(email).toLowerCase())) {
  //   errors.email = "Enter valid email address";
  // }

  let emailErr = validateEmail(email);

  if (emailErr) {
    errors.email = emailErr;
  }

  if (!password.trim()) {
    errors.password = 'Password is required';
  }

  if (!captcha.trim()) {
    errors.captcha = 'Captcha is required';
  }

  if (newUser) {
    // validations while signing up
    if (!firstName.trim()) {
      errors.firstName = 'Please enter first name';
    }

    if (!lastName.trim()) {
      errors.lastName = 'Please enter last name';
    }

    errors = {
      ...errors,
      ...validatePassword(password, confirmPassword),
    };

    // if(password && !PSWRD_REGEX.test(String(password))){
    //   errors.password = "A password must be 8 characters in length and contain a number, an uppercase and a special character";
    // }

    // if (!confirmPassword.trim()) {
    //   errors.confirmPassword = "Please re-enter password";
    // }

    // else {
    //   if (!(confirmPassword.trim() === password.trim())) {
    //     errors.confirmPassword = "Please make sure your passwords match";
    //   }
    // }
  }

  return errors;
};

/**
 * Validates the password fields.
 *
 * @param {string} password - The password to be validated.
 * @param {string} confirmPassword - The confirmation password to be validated.
 * @return {Object} An object containing validation errors for the password and confirmPassword fields.
 */
const validatePassword = (password, confirmPassword) => {
  const PSWRD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
  let errors = {};

  if (!password.trim()) {
    errors.password = 'Password is required';
  }

  if (password && !PSWRD_REGEX.test(String(password))) {
    errors.password =
      'A password must be 8 characters in length and contain a number, an uppercase and a special character';
  }

  if (confirmPassword !== undefined) {
    // this block will only run for sign up / reset password
    if (!confirmPassword.trim()) {
      errors.confirmPassword = 'Please re-enter password';
    }

    if (!(confirmPassword.trim() === password.trim())) {
      errors.confirmPassword = 'Please make sure your passwords match';
    }
  }

  return errors;
};

/**
 * Validates the login email.
 *
 * @param {string} email - The email to be validated.
 * @return {string} An error message if the email is invalid, otherwise an empty string.
 */
const validateEmail = (email) => {
  let error = '';
  // const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!email.trim()) {
    error = 'Email required';
  }

  if (email && !EMAIL_REGEX.test(String(email).toLowerCase())) {
    error = 'Enter valid email address';
  }

  return error;
};

export { validate, validateEmail, validatePassword };
