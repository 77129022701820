import { Form, Button } from 'react-bootstrap';

export default function ConfirmVerificationCode({
  handleSubmitVerificationCode,
  emailVerificationCode,
  setEmailVerificationCode,
  errors,
}) {
  return (
    <Form onSubmit={handleSubmitVerificationCode}>
      <h6 className="my-4">
        We have sent a 6 digit verification code on your email. Please enter the
        6 digit code to login.
      </h6>
      <Form.Group className="mb-3" controlId="verifyEmailCode">
        <Form.Label
          className="fw-bold mb-1"
          aria-label="Enter code to verify email"
        >
          Enter Code
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter code here"
          maxLength="6"
          value={emailVerificationCode}
          onChange={(e) => setEmailVerificationCode(e.target.value)}
        />
        {errors.emailVerificationCode && (
          <Form.Text className="text-danger">
            {errors.emailVerificationCode}
          </Form.Text>
        )}
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        className="w-100 fw-bold text-uppercase text-white"
        disabled={!emailVerificationCode || emailVerificationCode.length < 6}
      >
        Submit Verification Code
      </Button>
    </Form>
  );
}
