// Routes to different components.. High order component nothing is rendered in this but this routes to all the components
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import Login from '../Login/Login';
import Error from '../shared/Error';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import OAuth2RedirectHandler from '../oauth2/OAuth2RedirectHandler';
import ResetPassword from '../ResetPassword/ResetPassword';
import LiveStream from '../LiveStream/LiveStream';
import AccountSettings from '../AccountSettings/AccountSettings';
import EventDashboard from '../EventDashboard/EventDashboard';
import PrivacyPolicy from '../policies/PrivacyPolicy';
import TermsAndConditions from '../policies/TermsAndConditions';
import About from '../About/About';
import PreviewEvent from '../Events/PreviewEvent';
import { useAuth } from '../hooks/useAuth';
import ViewEventPerformance from '../Events/ViewEventPerformance';
import PreviewEventWithPerformances from '../Events/PreviewEventWithPerformances';
import PromoterDashboard from '../Promoter/PromoterDashboard';
import LikedFollowed from '../LikedFollowed/LikedFollowed';
import ContactUs from '../ContactUs/ContactUs';
import FAQ from '../FAQ/FAQ';
import ScheduleDemo from '../ScheduleDemo/ScheduleDemo';
import SignUp from '../SignUp/SignUp';
import ConfirmVerificationCode from '../Components/ConfirmVerificationCode';

/**
 * A High order component called 'Main' that routes to different components.
 *
 * @param {Object} props - The props object containing the following properties:
 *   @property {Object} props.children - The children components to be rendered.
 * @return {JSX.Element} The rendered component.
 */
export default function Main(props) {
  /**
   * A hook that provides the auth object.
   *
   * @return {Object} The auth object.
   */
  const auth = useAuth();

  /**
   * Updates the logged in user.
   *
   * @param {Object} user - The user object to be updated.
   * @return {void} This function does not return anything.
   */
  const updateLoggedInUser = (user) => {
    if (user) {
      auth.setUser(user);
    }
  };
  return (
    <div className="flex-grow-1">
      <Routes>
        <Route exact path="/" element={<Navigate to="home" replace />} />
        <Route path="home" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<SignUp />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="events" element={<EventDashboard />} />
        <Route
          path="events/:eventId/performances"
          element={<PreviewEventWithPerformances />}
        />
        <Route
          path="events/:eventId/performances/:performanceId"
          element={<ViewEventPerformance />}
        />
        <Route path="events/:eventId" element={<PreviewEvent />} />
        <Route path="account-settings" element={<AccountSettings />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route
          path="promoter-dashboard"
          element={<PromoterDashboard />}
        ></Route>
        <Route
          path="privacy-policy"
          element={
            <div className="p-4">
              <PrivacyPolicy />
            </div>
          }
        ></Route>
        <Route
          path="terms-of-service"
          element={
            <div className="p-4">
              <TermsAndConditions />
            </div>
          }
        ></Route>
        <Route path="account-settings" element={<AccountSettings />}></Route>
        <Route path="liked-followed" element={<LikedFollowed />}></Route>
        <Route path="contact-us" element={<ContactUs />}></Route>
        <Route path="faq" element={<FAQ />}></Route>
        <Route path="schedule-demo" element={<ScheduleDemo />}></Route>

        {/* component for live stream POC */}
        <Route path="stream/:eventId" element={<LiveStream />} />
        <Route
          path="oauth2/redirect"
          element={
            <OAuth2RedirectHandler updateLoggedInUser={updateLoggedInUser} />
          }
        ></Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
}
