import React, { useEffect, useState } from 'react';
import { getEventList } from '../utils/APIUtils';
import EventListCard from '../Events/EventListCard';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

/**
 * LikedFollowed is a React functional component that renders the page that displays the events that a user has liked or followed.
 *
 * @return {JSX.Element} The rendered LikedFollowed page.
 */
const LikedFollowed = () => {
  /**
   * State hook for storing all events.
   *
   * @type {Array}
   */
  const [allEvents, setAllEvents] = useState([]);

  /**
   * useEffect hook that fetches all events on component mount.
   *
   * @return {void}
   */
  useEffect(() => {
    getEventList(0, false)
      .then((res) => {
        setAllEvents(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Container className="bg-white" fluid>
      <Row className="py-5">
        <Col xs="12" md="9" className="mx-auto">
          <h2 className="mb-4 text-dark fw-bold">Liked:</h2>
          {allEvents.map((event, index) => {
            return <EventListCard event={event} index={index} key={index} />;
          })}

          <div className="d-flex justify-content-center">
            <Button
              as={Link}
              to="/events"
              variant="link"
              className="text-dark d-flex align-items-center  text-decoration-none"
            >
              <h4 className="mb-0 me-2 fw-bold">All Events</h4>
              <FontAwesomeIcon
                size="2x"
                className="text-dark"
                title="event Information"
                icon={faChevronDown}
              />
            </Button>
          </div>

          <h2 className=" mt-5 mb-4 text-dark fw-bold">Followed:</h2>

          {/* for spacing on mobile devices */}
          <div className='d-md-none my-5 py-5'> </div> 

          <div class="profile-subanner bg-dark text-white col-6 d-flex border-dark rounded-pill position-relative mt-5">
            <div class="d-flex w-100">
              <img
                class="profile-img position-absolute"
                src="/promoter-default-img.png"
                height={150}
                style={{ marginTop: '-1.25rem' }}
              />
              <div class="mx-auto ps-5">
                <h2 class="fw-bold mt-3">Paul Rivard</h2>
                <h5>TicketWindow Promoter</h5>
                <p class="text-primary">3 Events</p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <div class="profile-subanner bg-dark text-white col-6 d-flex border-dark rounded-pill position-relative mt-5">
              <div class="d-flex w-100">
                <img
                  class="profile-img position-absolute"
                  src="/promoter-default-img.png"
                  height={150}
                  style={{ marginTop: '-1.25rem' }}
                />
                <div class="mx-auto ps-5">
                  <h2 class="fw-bold mt-3">Paul Rivard</h2>
                  <h5>TicketWindow Promoter</h5>
                  <p class="text-primary">3 Events</p>
                </div>
              </div>
            </div>
          </div>

          <div class="profile-subanner bg-dark text-white col-6 d-flex border-dark rounded-pill position-relative mt-5">
            <div class="d-flex w-100">
              <img
                class="profile-img position-absolute"
                src="/promoter-default-img.png"
                height={150}
                style={{ marginTop: '-1.25rem' }}
              />
              <div class="mx-auto ps-5">
                <h2 class="fw-bold mt-3">Paul Rivard</h2>
                <h5>TicketWindow Promoter</h5>
                <p class="text-primary">3 Events</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="px-0">
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default LikedFollowed;
