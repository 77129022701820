import './App.scss';
import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Header from './Header/Header';
import Main from './Main/Main';
import { ACCESS_TOKEN, LOGGED_IN_USER } from './constants';
import Error from './shared/Error';
import ErrorHandler from './ErrorHandler/ErrorHandler';
import { ProvideAuth } from './hooks/useAuth';

/**
 * A constant 'storageUtils' store the local storage utilities.
 *
 * @module LocalStorageUtils
 */
const storageUtils = require('./utils/LocalStorageUtils');

/**
 * App is the root component of the application.
 * It represents the highest level component in the component hierarchy.
 *
 * @return {JSX.Element} The JSX Element representing the root component of the application.
 */
function App() {
  // // const [loading, setLoading] = useState(false);
  // const [authenticated, setauthenticated] = useState(false);
  // const [currentuser, setcurrentuser] = useState(null);

  /**
   * This is a React hook that runs after the component has been rendered to the screen.
   * Loads the currently logged in user from the local storage by calling the loadCurrentlyLoggedInUser function.
   *
   * @return {void} This hook does not return anything.
   */
  useEffect(() => {
    loadCurrentlyLoggedInUser();
  }, []);

  // Function to load the currenly logged in user from the local storage.

  /**
   * Loads the currently logged in user from the local storage.
   *
   * @return {void} This function does not return anything.
   */
  const loadCurrentlyLoggedInUser = () => {
    let user = localStorage.getItem(LOGGED_IN_USER);
    if (user) {
      user = JSON.parse(user);
    }

    // setLoading(true);
    // let token = JSON.parse(localStorage.getItem(ACCESS_TOKEN));
    // let user = JSON.parse(localStorage.getItem(LOGGED_IN_USER));
    // let token = storageUtils.getItem(ACCESS_TOKEN);
    // let user = storageUtils.getItem(LOGGED_IN_USER);

    // if(token){
    //   setcurrentuser(user);
    //   setauthenticated(true);
    // }
  };

  /**
   * This function handles the logout event. It removes the access token and
   * logged in user from the local storage.
   *
   * @return {void} This function does not return anything.
   */
  const handleLogout = () => {
    storageUtils.removeItem(ACCESS_TOKEN);
    storageUtils.removeItem(LOGGED_IN_USER);
    // setcurrentuser(null);
    // setauthenticated(false);
    console.log("You're safely logged out!");
  };

  return (
    <ProvideAuth>
      <div className="d-flex flex-column h-100 overflow-auto">
        <Router>
          <Routes>
            <Route
              path="/*"
              element={
                <>
                  <Header handleLogout={handleLogout} />
                  <ErrorHandler>
                    <Routes>
                      <Route path="/*" element={<Main />} />
                    </Routes>
                  </ErrorHandler>
                </>
              }
            />
          </Routes>
        </Router>
      </div>
    </ProvideAuth>
  );
}

export default App;
