import { ACCESS_TOKEN, HEADERS } from '../constants';
import { getItem } from './LocalStorageUtils';
import { getFormDataObj } from './CommonUtils';
import axios from 'axios';
import qs from 'qs';

const API_BASE_URL = process.env.REACT_APP_PATRON_API;
/**
 * Function to retrieve the authentication header including the bearer token.
 *
 * @return {Object} An object containing the authentication header.
 */
const getAuthHeaders = () => {
  let token = localStorage.getItem(ACCESS_TOKEN);
  if (token) token = JSON.parse(token);

  return {
    ...HEADERS,
    Authorization: `Bearer ${token}`,
  };
};

/**
 * Function performs the login with provided data and captcha response.
 *
 * @param {Object} data - An object containing user data.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const login = (data, captchaResponse) => {
  const LOGIN_HEADERS = {
    ...HEADERS,
    'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + '/authenticate', data, {
    headers: LOGIN_HEADERS,
  });
};

/**
 * Function performs the login with provided data and captcha response.
 *
 * @param {Object} data - An object containing user data.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
export const login2 = (data, captchaResponse) => {
  console.log('login2');

  const LOGIN_HEADERS = {
    ...HEADERS,
    // 'captcha-response': captchaResponse,
  };
  return axios.post(`${API_BASE_URL}/login`, data, {
    withCredentials: true,
    headers: LOGIN_HEADERS,
  });
};

/**
 * Function to register a new user with provided data and captcha response.
 *
 * @param {Object} data - An object containing user data.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const register = (data, captchaResponse) => {
  const LOGIN_HEADERS = {
    ...HEADERS,
    // 'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + '/register', data, {
    headers: LOGIN_HEADERS,
  });
};

/**
 * Function to verify the email with provided code and authentication token.
 *
 * @param {string} code - A string representing the verification code.
 * @param {string} pendingAuthenticationToken - A string representing the pending authentication token.
 * @return {Promise} A promise that resolves to the response from the server.
 */
// const verifyemail = (code, pendingAuthenticationToken) => {
//   return axios.get(
//     API_BASE_URL +
//       `/verifyemail?code=${code}&pendingAuthenticationToken=${pendingAuthenticationToken}`,
//     { headers: HEADERS }
//   );
// };

const verifyemail = (code, pendingAuthenticationToken) => {
  return axios.get(API_BASE_URL + `/verify-email?`, {
    params: {
      code: code,
      pendingAuthenticationToken: pendingAuthenticationToken,
    },
    headers: {
      ...HEADERS,
    },
    withCredentials: true,
  });
};

// Function to send the reset password email.
/**
 * Function to send the reset password email.
 *
 * @param {Object} data - An object containing email and captchaResponse.
 * @param {string} data.email - A string representing the user's email.
 * @param {string} data.captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const sendResetPasswordEmail = (data) => {
  const LOGIN_HEADERS = {
    ...HEADERS,
    'captcha-response': data.captchaResponse,
  };
  return axios.get(API_BASE_URL + `/forgot-password?email=${data.email}`, {
    headers: LOGIN_HEADERS,
  });
};

// DEPRECATED
// function to verify the token to reset the password.
// const verifyToken = (data) => {
//     return axios.post(API_BASE_URL + '/password/reset/token/verify', data, { headers: HEADERS })
// }

/**
 * Function to reset a new password.
 *
 * @param {Object} data - An object containing token and password.
 * @param {string} data.token - A string representing the reset token.
 * @param {string} data.password - A string representing the new password.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const resetNewPassword = (data, captchaResponse) => {
  const LOGIN_HEADERS = {
    ...HEADERS,
    'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + '/reset-password', data, {
    headers: LOGIN_HEADERS,
  });
};

/**
 * Function to retrieve the list of event types.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventTypes = () => {
  return axios.get(API_BASE_URL + `/eventType/list`, { headers: HEADERS });
};

/**
 * Function to retrieve the list of event genres.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventGenres = () => {
  return axios.get(API_BASE_URL + `/genre/list`, { headers: HEADERS });
};

/**
 * Function to retrieve the location type.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getLocationTypes = () => {
  return axios.get(API_BASE_URL + `/locationtype/list`, { headers: HEADERS });
};

/**
 * Function to get the event detail based on event ID.
 *
 * @param {string} eventId - The ID of the event.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventDetails = (eventId) => {
  return axios.get(API_BASE_URL + `/event/${eventId}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to get the category detail of an event based on event ID.
 *
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getCategoryDetails = (isloggedIn) => {
  return axios.get(API_BASE_URL + `/eventCategories/list`, {
    headers: isloggedIn ? getAuthHeaders() : HEADERS,
  });
};

/**
 * Function to filter the event list.
 *
 * @param {Object} data - The data for filtering the event list.
 * @param {number} [page=1] - The page number of the event list.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const eventListFilter = (data, page = 1) => {
  console.log('bbbbb', data);
  return axios.post(
    API_BASE_URL + `/event/list/filter?page=${page}&size=10`,
    data,
    { headers: HEADERS }
  );
};

/**
 * Function to retrieve event list.
 *
 * @param {number} [page] - The page number of the event list.
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventList = (page, isloggedIn) => {
  return axios.get(
    API_BASE_URL + `/get-event-list${page ? '?page=' + page : ''}`,
    { headers: isloggedIn ? getAuthHeaders() : HEADERS }
  );
};

/**
 * Function to get list of customized event.
 *
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventListCustomized = (isloggedIn) => {
  return axios.get(API_BASE_URL + `/event/list/customized`, {
    headers: isloggedIn ? getAuthHeaders() : HEADERS,
  });
};

/**
 * Function to retrieve the details of camera based on event ID.
 *
 * @param {string} eventId - The ID of the event.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getCameraDetails = (eventId) => {
  return axios.get(API_BASE_URL + `/promoter/event/cameras/${eventId}`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to retrieve user details.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getUserDetails = async () => {
  console.log('get user details');

  try {
    const userInfo = await axios.get(`${API_BASE_URL}/get-user-info`, {
      headers: { ...HEADERS },
      withCredentials: true,
    });
    return userInfo.data;
  } catch (error) {
    console.error('Failed to fetch user info', error);
    return null;
  }
};

/**
 * Function to reset the stripe link.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const resetStripeLink = () => {
  return axios.get(API_BASE_URL + `/reset-link`, { headers: getAuthHeaders() });
};

/**
 * Function to update the contact information of the user.
 *
 * @param {Object} data - The data containing the contact information.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const updateContactInfo = (data) => {
  console.log(getAuthHeaders());
  let formData = getFormDataObj(data);
  return axios.put(API_BASE_URL + `/user/details/update`, formData, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to retrieve the methods to make a payment.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const fetchPaymentMethods = () => {
  return axios.get(API_BASE_URL + `/getPaymentMethods`, {
    headers: getAuthHeaders(),
  });
};

/**
 * Function to pay with already added payment method.
 *
 * @param {Object} paymentBody - The payment body containing the payment details.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const payWithExistingMethod = (paymentBody) => {
  return axios.post(API_BASE_URL + `/payment`, paymentBody, {
    headers: getAuthHeaders(),
  });
};

/**
 * Saves the billing information for a specific event.
 *
 * @param {number} eventId - The ID of the event.
 * @param {Object} billingDetails - The billing details to be saved.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const saveBillingInfo = (eventId, billingDetails) => {
  return axios.post(
    API_BASE_URL + `/checkout/billingInfo/${eventId}`,
    billingDetails,
    { headers: getAuthHeaders() }
  );
};

/**
 * Validates a coupon for a specific event.
 *
 * @param {number} eventId - The ID of the event.
 * @param {string} couponCode - The coupon code to be validated.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const validateCoupon = (eventId, couponCode) => {
  return axios.post(
    API_BASE_URL + `/coupon/validate/${eventId}?coupon=${couponCode}`,
    {},
    { headers: getAuthHeaders() }
  );
};

/**
 * Retrieves the list of orders made by the user.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getOrderList = () => {
  return axios.get(API_BASE_URL + `/order/list`, { headers: getAuthHeaders() });
};

/**
 * Cancels an order made by the user.
 *
 * @param {Object} orderDetails - The details of the order to be cancelled.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const cancelOrder = (orderDetails) => {
  return axios.post(API_BASE_URL + `/refund-customer`, orderDetails, {
    headers: getAuthHeaders(),
  });
};

/**
 * Retrieves the details of the fee charged for an order.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getFeeDetails = () => {
  return axios.get(API_BASE_URL + `/order/fee/details`, {
    headers: getAuthHeaders(),
  });
};

// Export all API Functions.
export {
  login,
  register,
  sendResetPasswordEmail,
  verifyemail,
  resetNewPassword,
  getEventTypes,
  getEventGenres,
  getLocationTypes,
  getEventDetails,
  getEventList,
  getEventListCustomized,
  getCameraDetails,
  getUserDetails,
  resetStripeLink,
  updateContactInfo,
  fetchPaymentMethods,
  payWithExistingMethod,
  validateCoupon,
  saveBillingInfo,
  getOrderList,
  cancelOrder,
  getFeeDetails,
  getCategoryDetails, //Category Filtering
  eventListFilter, // Filtering events
};
