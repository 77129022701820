import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
export default function AuthenticationHead({ isLogin }) {
  const navigate = useNavigate();

  return (
    <>
      <Card.Title className="text-primary text-uppercase mb-0 fw-bold">
        {isLogin ? 'Log in' : 'Sign up'}
      </Card.Title>
      <Card.Text className="fs-12 mb-2">
        {isLogin ? 'Not registered with us yet?' : 'Already have an account?'}
        <button
          type="button"
          onClick={() => navigate(isLogin ? '/register' : '/login')}
          className="text-primary fw-bold  text-decoration-none btn btn-link fs-12 mb-1 ms-2 p-0"
        >
          {isLogin ? 'Sign up' : 'Log in'}
        </button>
      </Card.Text>
    </>
  );
}
