import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
export default function KeepMeSignAndForgot({ onChangeChecked, rememberUser }) {
  return (
    <Form.Group className="d-flex mb-3" controlId="formBasicCheckbox">
      <Form.Check
        onChange={(e) => onChangeChecked(e.target.checked)}
        checked={rememberUser}
        className="fs-12"
        type="checkbox"
        label="Keep me signed in"
        aria-label="checkbox for keep me signed in"
      />
      <Link
        to="/forgot-password"
        className="text-primary text-decoration-none fs-12 fw-bold ms-auto"
      >
        Forgot Password?
      </Link>
    </Form.Group>
  );
}
