import React from 'react';
import { Row, Col, Pagination as Pagination_ } from 'react-bootstrap';

/**
 * React functional component that renders an HTML elements to manage the pagination.
 *
 * @param {Object} props - The properties object containing the following properties:
 *   @property {number} currentPage - The current page number.
 *   @property {Function} handlePageChange - The function to handle page change.
 *   @property {number} totalPages - The total number of pages.
 * @return {JSX.Element} The rendered pagination component.
 */
const Pagination = (props) => {
  /**
   * Handles the page change event.
   *
   * @param {number} pageNo - The page number to be set as the current page.
   * @return {void} This function does not return anything.
   */
  const handlePageChange = (pageNo) => {
    if (pageNo !== props.currentPage) props.handlePageChange(pageNo);
  };

  /**
   * Checks if the total number of pages is falsy.
   *
   * @return {JSX.Element} Returns a empty JSX element if the total number of pages is falsy.
   */
  if (!props.totalPages) {
    return <></>;
  }

  return (
    <Row>
      <Col className="d-flex justify-content-between align-items-center">
        <span className="invisible ">
          Showing Page {props.currentPage + 1} of {props.totalPages}
        </span>
        <Pagination_ className="mb-0">
          <Pagination_.Prev
            onClick={() => handlePageChange(props.currentPage - 1)}
            disabled={props.currentPage == 0}
            className="page-item-prev"
          />
          {(() => {
            let items = [];
            for (let i = 0; i < props.totalPages; i++) {
              items.push(
                <Pagination_.Item
                  active={props.currentPage == i}
                  onClick={() => handlePageChange(i)}
                >
                  {i + 1}
                </Pagination_.Item>
              );
            }
            return items;
          })()}
          <Pagination_.Next
            onClick={() => handlePageChange(props.currentPage + 1)}
            disabled={props.currentPage == props.totalPages - 1}
            className="page-item-next"
          />
        </Pagination_>
        <div>
          <span className="d-none d-md-block">
            Showing Page {props.currentPage + 1} of {props.totalPages}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default Pagination;
